<div class="modal-container">
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title">{{heading}}</span>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('back')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span i18n>{{message}}</span>
  </div>
  <div class="modal-footer">
    <a class="btn btn-delete" (click)="activeModal.close('cancel')" i18n>Cancel</a>
    <a class="btn btn-delete" (click)="activeModal.close('continue')" i18n>{{masterButton}}</a>
  </div>
</div>
