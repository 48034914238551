@if (loading) {
  <div class="loading-box">
    <div class="img" alt="loading"></div>
  </div>
}
<div class="sub-page edit-user" [class.modal-page]="mode === 'as-modal'">
  <div class="viewport">
    <header class="sub-header sub-header-sm" [class.modal-header]="mode === 'as-modal'">
      @if (mode!=='as-modal') {
        <i class="icon icon-back" aria-hidden="true" (click)="backWithoutSave()"></i>
      }
      <h3>{{title}}</h3>
      @if (mode==='as-modal') {
        <button type="button" class="close" aria-label="Close"
          (click)="activeModal.dismiss('back')">
          <span>&times;</span>
        </button>
      }
    </header>

    <div class="scroll-container" style="overflow-y: scroll;">
      <div class="role-info-section container-fluid">
        @if (loading) {
          <div class="loading-box">
            <div class="img" alt="loading"></div>
          </div>
        }
        @if (!addNewUser) {
          <div class="create-user-form" style="min-height: 10px !important">
            <div class="emp-info">
              <div class="fig">
                <img [src]="member['userProfilePhoto']" [class.no-image]="!member['userProfilePhoto']"  alt="" accept="image/*">
                <a class="btn-upload-img">+
                  <input type='file' class="file" (change)="onSelectFile($event)"></a>
                </div>
              </div>
            </div>
          }
          @if (mode!=='as-modal') {
            <div class="group-header first-header" i18n i18n>Profile</div>
          }
          <div class="frow row">
            <div class="col-lg-6">
              <div class="fieldset">
                <div class="fs-lbl">
                  <span i18n><sup>*</sup>Name</span>

                </div>
                <div class="fs-val">
                  <input type="text" (change)="validateForm()" (keypress)="omit_special_char($event)" maxlength="25"
                    class="input-ctrl" [(ngModel)]="member['userName']" />
                </div>
                <span class="error-indicate" [ngClass]="{'invalid':submitted && !member['userName']}" i18n>Name is
                required</span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="fieldset">
                <div class="fs-lbl">
                  <span i18n><sup>*</sup>Surname</span>

                </div>
                <div class="fs-val">
                  <input type="text" (change)="validateForm()" (keypress)="omit_special_char($event)" maxlength="25"
                    class="input-ctrl" [(ngModel)]="member['surName']" />
                </div>
                <span class="error-indicate" [ngClass]="{'invalid':submitted && !member['surName']}" i18n>Surname is
                required</span>
              </div>
            </div>
          </div>

          <div class="frow row">
            <div class="col-lg-6">
              <div class="fieldset">
                <div class="fs-lbl">
                  <span i18n><sup>*</sup>Employer</span>

                </div>
                <div class="fs-val">
                  <input type="text" (change)="validateForm()" (keypress)="allow_special_char($event)" maxlength="50"
                    class="input-ctrl" [(ngModel)]="member['employer']" />
                </div>
                <span class="error-indicate" [ngClass]="{'invalid':submitted && !member['employer']}" i18n>Employer is
                required</span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="fieldset">
                <div class="fs-lbl">
                  <span i18n><sup>*</sup>Job position</span>

                </div>
                <div class="fs-val">
                  <input type="text" (change)="validateForm()" (keypress)="allow_special_char($event)" maxlength="50"
                    class="input-ctrl" [(ngModel)]="member['jobPosition']" />
                </div>
                <span class="error-indicate" [ngClass]="{'invalid':submitted && !member['jobPosition']}" i18n>Job position
                is required</span>
              </div>
            </div>
          </div>

          <div class="frow row">
            <div class="col-lg-6">
              <div class="fieldset">
                <div class="fs-lbl">
                  <span i18n><sup>*</sup>Email</span>

                </div>
                <div class="fs-val">
                  <input type="email" maxlength="50" [class.disabled]="title === 'Edit member'" (change)="validateForm()"
                    (keypress)="allow_emailCharacters($event)" class="input-ctrl" [(ngModel)]="member['emailId']" />
                </div>
                <span class="error-indicate" [ngClass]="{'invalid':submitted && !isEmail(member['emailId'])}" i18n>Should
                enter email</span>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="fieldset">
                <div class="fs-lbl">
                  <span i18n><sup>*</sup>Phone</span>

                </div>
                <div class="fs-val fs-val-fx">
                  <div class="col-md-2">

                    <select class="select-ctrl" [(ngModel)]="member.countryId" (change)="changeIsd($event.target.value )">
                      @for (a of IsdCode; track a) {
                        <option [value]="a.countryId">{{a.countryShortNameWithIsdCode}}</option>
                      }
                    </select>
                  </div>
                  <div class="col-md-9">
                    <input type="text" class="input-ctrl" (keypress)="only_numbers($event) " (change)="validateForm()"
                      [(ngModel)]="member['phone']" maxlength="20" />
                  </div>
                </div>
                <span class="error-indicate" [ngClass]="{'invalid':submitted && !(member['phone'])}" i18n>Should enter
                phone
                number</span>
                <span class="error-indicate" [ngClass]="{'invalid':submitted && isdcodeError}" i18n>Should enter isd code
              </span>
              </div>
            </div>
          </div>

          @if (!addNewUser) {
            <div class="group-header" i18n i18n>Role</div>
          }
          @if (!addNewUser) {
            <div class="items">
              @for (role of member['userRolesDtoList']; track role) {
                <div [class.error]="isNoRoleSelected()" class="item">
                  <label [class.error]="isNoRoleSelected()" (click)="deleteRole(role)" [class.disabled]="isUserSelectedProjectMember()" >
                    <i class="icon icon-remove" aria-hidden="true"></i>
                  </label>
                  <span>{{role.roleName}}</span>
                </div>
              }
              @if (!addNewUser) {
                <a class="item add-new" [class.disabled]="isUserSelectedPMC() || isUserSelectedProjectMember()"  (click)="assignNewRole()">
                  <label>
                    <i class="icon icon-add-new" aria-hidden="true"></i>
                  </label>
                  <span [class.error]="isNoRoleSelected()" i18n>Assign role</span>
                  @if (member['userRolesDtoList']?.length === 0) {
                    <span class="error-indicate "
                    [class.invalid]="submitted && !isRole(member['userRolesDtoList'])"
                    i18n>Should add role
            </span>
                  }
                </a>
              }
            </div>
          }
          @if (addNewUser) {
            <div class="group-header" i18n i18n>Assign role & privileges</div>
          }

          @if (addNewUser && systemRoles) {
            <div class="role-items">
              @for (role of allRoles; track role) {
                <a class="role-item" (click)="roleSelected($event,role)">
                  <i class="icon icon-checkbox" aria-hidden="true" [class.checked]="role.selected"></i>
                  <span>{{role.roleName}}</span>
                </a>
              }
              @if (isNoRoleSelected()) {
                <span [class.error]="isNoRoleSelected()" class="error-indicate "
                [class.invalid]="submitted && !isRole(member['userRolesDtoList'])"
                i18n>Should add role
          </span>
              }
            </div>
          }

          @if (addNewUser && !systemRoles) {
            <div [class.error]="isNoRoleSelected()">
              <div>
                <app-role-card (click)="checkMaintenanceInfoVisbility()" [allRoles]="allRoles">
                </app-role-card>
              </div>
              @if (isNoRoleSelected()) {
                <span [class.error]="isNoRoleSelected()" class="error-indicate "
                  [class.invalid]="submitted && !isRole(member['userRolesDtoList'])"
                  i18n>Should add role
          </span>
              }
            </div>
          }
          @if (showMaintenanceInfo && !addNewUser) {
            <div class="group-header" i18n i18n>Maintenance information</div>
          }
          @if (showMaintenanceInfo  && !addNewUser) {
            <div class="tabs tabs-maintenance">
              <ul ngbNav #nav="ngbNav" class="nav-tabs">
                <li [ngbNavItem]="1">
                  <a ngbNavLink>Execution Team</a>
                  <ng-template ngbNavContent>
                    <div class="tab tab-execution-team">
                      <div class="items">
                        <div class="item-row">
                          <div class="item header"></div>
                          <div class="label hourly-rate">
                            <span i18n>*Hourly rate</span>
                            <span class="error-indicate" [class.invalid]="hourlyRateMissing" i18n>Required</span>
                          </div>
                        </div>
                        @for (team of member['teamUsersDtoList']; track team) {
                          <div class="item-row">
                            <div class="item">
                              <label (click)="removeFromTeam(team)">
                                <i class="icon icon-remove" aria-hidden="true"></i>
                              </label>
                              <span>{{team.teamName}}</span>
                            </div>
                          </div>
                        }
                        <div class="item-row">
                          <a class="item add-new" (click)="assignNewTeam()" [class.disabled]="member['teamUsersDtoList'] && member['teamUsersDtoList'].length > 0">
                            <label>
                              <i class="icon icon-add-new" aria-hidden="true"></i>
                            </label>
                            <span i18n>Assign to execution team</span>
                          </a>
                          <div class="fs-val">
                            <div class="hourly-rate">
                              <input appPositiveNumberOnly maxlength="8" type="number" min="1" [(ngModel)]="member['hourlyRate']" required>
                            </div>
                            <span class="error-indicate" [ngClass]="{'invalid':submitted && !member['hourlyRate']}"
                            i18n>Hourly rate is Required</span>
                          </div>
                          <div class="currency-text">
                            <div class="currency-value" i18n> <span>{{currency}}</span></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
                <li [ngbNavItem]="2">
                  <a ngbNavLink>Skills & certificates</a>
                  <ng-template ngbNavContent>
                    <div class="tab tab-skills">
                      <div class="items">
                        @if (member['userSkillsDtoList'] && member['userSkillsDtoList'].length) {
                          <div class="item header">
                            <span class="label" i18n>Skill name</span>
                            <div class="detail label" i18n>File</div>
                            <div class="detail label" i18n>Validity</div>
                          </div>
                        }
                        @for (skill of member['userSkillsDtoList']; track skill) {
                          <div class="item">
                            @if (userRolesObject.SkillsEdit) {
                              <label (click)="toggleSkill(skill)">
                                <i class="icon icon-checkbox" aria-hidden="true" [class.checked]="skill.selected"></i>
                              </label>
                            }
                            <span>{{ skill.skillName }}</span>
                            @if (skill.skillCertificateName) {
                              <div class="detail">
                                <i class="icon icon-file" aria-hidden="true"></i>
                                <span class="filename"
                                (click)="downloadFile(skill.skillCertificateFile, skill.skillCertificateName)">{{ skill.skillCertificateName }}</span>
                              </div>
                            }
                            @if (skill.skillCertificateName) {
                              <div class="detail">
                                <span class="date-value">{{ skill.skillCertificateValidityPeriodMonth }}</span>
                                <span class="date-value">{{ skill.skillCertificateValidityPeriodYear }}</span>
                              </div>
                            }
                          </div>
                        }
                        <a class="item add-new" (click)="assignOrEditSkill()">
                          <label>
                            <i class="icon icon-add-new" aria-hidden="true"></i>
                          </label>
                          <span i18n>Assign skill</span>
                        </a>
                        <div class="skill-buttons">
                          <a class="btn btn-edit" [class.disabled]="!selectedSkills.length"
                          (click)="deleteSelectedSkills(member['userSkillsDtoList'])" i18n>Delete</a>
                          <a class="btn btn-edit" [class.disabled]="selectedSkills.length!==1"
                          (click)="selectedSkills.length > 0 && assignOrEditSkill(selectedSkills[0])" i18n>Edit</a>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </li>
              </ul>
              <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
          }

          @if (addNewUser) {
            <div class="bar-action fx-r">
              <a class="btn btn-save btn-save-form" (click)="save()">Save</a>
            </div>
          }
          @if (!addNewUser) {
            <div class="bar-action fx-r">
              <a class="btn btn-save btn-save-form" (click)="save2()">Save</a>
            </div>
          }
        </div>
      </div>
    </div>
  </div>
