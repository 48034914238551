import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationService } from '../../../services/application.service';

@Component({
  selector: 'app-user-confirmation',
  templateUrl: './user-confirmation.component.html',
  styleUrls: ['./user-confirmation.component.scss']
})
export class UserConfirmationComponent implements OnInit {
  userId;
  success = false;
  activationMessage;
  constructor(private appSvc: ApplicationService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.userId = this.route.snapshot.params['id'];
    this.appSvc.changePassword(this.userId).subscribe((result) => {
      if (result.statusCode === 200) {
        this.success = true;
        this.activationMessage = 'We have sent temporary password to your registered mail id. Please check.';
      }
    });
  }

}
