<div class="page-container">
  <div class="row login-page">
    <div class="col left-panel">
      <div class="login-img">
        <img class="onboard-img" src="/assets/i/Hero Image - Onboarding.jpg" alt="Avatar" />
      </div>
    </div>
    <div class="col right-panel">
      @if(!isOffline){
        <div class="centre-align no-internet-label">
          <span class="no-internet-span">
            <img class="internet-icons" src="/assets/i/No-internet-red.svg" alt="Avatar" />Internet is not available
          </span>
        </div>
      }
      @else{
        <div class="centre-align internet-label">
          <span class="internet-span">
            <img class="internet-icons" src="/assets/i/Internet_green.svg" alt="Avatar" />Internet is available
          </span>
        </div>
      }
      <div class="centre-align hitachi-logo">
        <img class="relcare-img" src="/assets/i/Hitachi_logo.svg" alt="Avatar" />
      </div>
      <div class="centre-align relcare-text">Welcome to RelCare™</div>
      <div class="centre-align offline-mode">
        <span>
          <img class="offline-icon" src="/assets/i/Offline_mode.svg" alt="Avatar" />
        </span>Offline mode
      </div>

      @if(isDBNotAvailable){
      <div class="no-data-container">
        <div class="no-data-inner-cont1">
          <img class="error-img" src="/assets/i/error-fill.svg" alt="Avatar" />
          Data is not available to perform offline execution
        </div>
        <div class="no-data-inner-cont2">
          To proceed, <b>sync tasks to this device</b> in online mode
        </div>
        <div class="tiny-or">or</div>
        <div class="no-data-inner-cont2">
          <img alt="import"src="/assets/i/upload.svg" (click)="filePicker.click()"/>
          <input #filePicker type="file" accept="application/json"
            (input)="importFromBackup(filePicker.files)" style="display: contents;">
          <span class="warning-no-data">Import backup file</span>
        </div>
      </div>
    } @else{
      <div class="login-form">
        <div class="centre-align form-input">
          <label for="username" class="form-label">Email ID</label>
          <input class="input-ctrl" [(ngModel)]="email">
          @if(errorMessage === 'username error'){
            <div class="error-message">
              <img src="/assets/i/Error_circle.svg" alt="Avatar" />Unauthorised user
            </div>
          }
        </div>
        <div class="centre-align form-input">
          <label for="password" class="form-label">Password</label>
          <input type="password" class="input-ctrl" [(ngModel)]="password">
          @if(errorMessage === 'password error'){
            <div class="error-message">
              <img src="/assets/i/Error_circle.svg" alt="Avatar" />Wrong password
            </div>
          }
        </div>
        <div class="centre-align form-input">
          <button class="btn btn-primary btn-width" (click)="login()">Log in</button>
        </div>
      </div>
    }

      <div class="warning">
        <div class="warning-text">Warning</div>
        <div class="warning-text-1">
          <p>This application is the property of Hitachi Energy and can be accessed only by authorized users. Unauthorized
            access or improper use of the application may result in civil or criminal charges. CLOSE THIS WINDOW IMMEDIATELY
            if you are not an authorized user.</p>
        </div>
      </div>
    </div>
  </div>
</div>
