import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class MyInterceptor implements HttpInterceptor {
    AccessToken: any;
    constructor() { }
    // function which will be called for all http calls
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // how to update the request Parameters
        // const updatedRequest = request.clone({
        //     headers: request.headers.set('Authorization', this.getToken())
        // });
        // logging the updated Parameters to browser's console
        return next.handle(request).pipe(
            tap({next:
                event => {
                    // logging the http response to browser's console in case of a success
                    // if (event instanceof HttpResponse) {
                    // }
                },
                error:() => {
                    // logging the http response to browser's console in case of a failuer
                    // if (event instanceof HttpResponse) {
                    // }
                }
            })
        );
    }
    getToken(): any {
        this.AccessToken = sessionStorage.getItem('AccessToken');
        return this.AccessToken;
    }
}
