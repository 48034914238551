import { Directive, ElementRef, Input, NgZone, SimpleChanges, ViewChild, EventEmitter } from '@angular/core';
import PerfectScrollbar from 'perfect-scrollbar';
@Directive({
  selector: '[perfectScrollbar]'
})
export class PerfectScrollbarDirective {
  private instance: PerfectScrollbar | null = null;
  @Input('perfectScrollbar') config?: any;
  @Input() disabled = false;
    psScrollY: EventEmitter<any>;
    psScrollX: EventEmitter<any>;
    psScrollUp: EventEmitter<any>;
    psScrollDown: EventEmitter<any>;
    psScrollLeft: EventEmitter<any>;
    psScrollRight: EventEmitter<any>;
    psYReachEnd: EventEmitter<any>;
    psYReachStart: EventEmitter<any>;
    psXReachEnd: EventEmitter<any>;
    psXReachStart: EventEmitter<any>;


  constructor(public readonly elementRef: ElementRef,private readonly zone: NgZone) {}

  ngOnInit(): void {
    const defaults = { wheelPropagation:true, ...this.config }
          this.zone.runOutsideAngular(() => {
      this.instance = new PerfectScrollbar(this.elementRef.nativeElement, defaults);
    });
  }

  ngAfterViewChecked(): void {
    this.instance?.update();
  }

  ngOnDestroy(): void {
    this.instance?.destroy();
  }
    scrollTo(x: number, y?: number, speed?: number): void{};
    scrollToX(x: number, speed?: number): void{};
    scrollToY(y: number, speed?: number): void{};
    scrollToTop(offset?: number, speed?: number): void{};
    scrollToLeft(offset?: number, speed?: number): void{};
    scrollToRight(offset?: number, speed?: number): void{};
    scrollToBottom(offset?: number, speed?: number): void{};
    scrollToElement(element: HTMLElement | string, offset?: number, speed?: number): void{};
}
