import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { fromEvent, map, mapTo, merge, of } from 'rxjs';
import { AppAuthGuard } from './app.guard';
@Injectable({
    providedIn: 'root',
  })
export class ConditionalGuard implements CanActivate {
    isOnline: any;
  networkStatus: boolean;
  networkStatus$: any;
  constructor(private authGuard: AppAuthGuard, private router: Router) {}
  canActivate(_route: ActivatedRouteSnapshot, _state:           
    RouterStateSnapshot): any {
    //bypass guard here
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        console.log('status', status);
        this.isOnline = status  
        // if(!this.isOffline){
        //   // this.modalService.open(this.pinLoginTemplate, { centered: true, size: 'lg' ,backdropClass: 'black-backgound'});
        // }
      });
    if (!this.networkStatus) {
      // return true;
      this.router.navigate(['offline'])
      return true
    }
    // call your guard here

    return this.authGuard.canActivate(_route, _state);
  }
}