@if (loading) {
  <div class="loading-box">
    <div class="img" alt="loading"></div>
  </div>
}

<div class="page-container">
  <header class="header-container">
    <div class="header-item header-mid">
      <h3 i18n> Projects </h3>
    </div>
  </header>
</div>

<div class="project-listing">
  <div class="main_div">
    <ng-container ng-diable="disable">
      <div class="header-content">
        <div class="search-wrapper">
          @if(isMyProjectVisbile()) {
          <label class="checkbox-wrap">
            <input type="checkbox" (click)="isMyProjectChecked()">
            <i class="icon icon-checkbox-black" aria-hidden="true" [ngClass]="{ checked: isMyProject }"></i>
            <span>My projects</span>
          </label>
          }
          <input type='text' class="input-ctrl search-ctrl" placeholder='Search' [(ngModel)]="filterStr"
            (keypress)="!isSpecialCharacter($event)" (keyup)='updateFilter($event.target.value,$event)' />
        </div>
      </div>
    </ng-container>
    <div class="filter-container" [ngClass]="{'filter-hide':!filterShow}">
      <div class="header">
        <i class="icon icon-filter" aria-hidden="true" (click)="filterShow=!filterShow"
        [ngClass]="{'checked':!filterShow}"></i>
        <span i18n>Column filter</span>
      </div>
      @for (item of filterColumns; track item; let i = $index) {
        <div class="filter-option"
          (click)="item.selected=!item.selected;">
          <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':item.selected}"></i>
          <span>{{item.name}}</span>
        </div>
      }
    </div>

    <ngx-datatable (activate)="onActivate($event)" #projectTable [rows]="filteredData | paginate: configPlan"
      [ngClass]="isSystemManagerSupportRole ? 'scrollbar-with-create-new': 'scrollbar-without-create'"
      [count]="configPlan.totalItems" [offset]="configPlan.currentPage" [limit]="configPlan.itemsPerPage"
      (page)="pageChange($event)" [columnMode]="'force'" [rowHeight]="56" (sort)="onSort($event)">
      @if (filterColumns[0].selected) {
        <ngx-datatable-column name="Project name" prop='projectname' [width]="230"
          [draggable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
            <div class="country-header">
              <span class="company" [ngbTooltip]="row.projName">{{row.projName}}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
      }
      @if (filterColumns[1].selected) {
        <ngx-datatable-column name="Customer" prop='customer' [width]="200"
          [draggable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
            <div class="country-header">
              <span class="company" [ngbTooltip]="row.custName">{{row.custName}}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
      }
      @if (filterColumns[2].selected) {
        <ngx-datatable-column name="Continent" prop='country' [width]="230"
          [draggable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
            <div class="country-header">
              <span class="company" [ngbTooltip]="row.areaName">{{row.areaName}}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
      }
      @if (filterColumns[3].selected) {
        <ngx-datatable-column name="Project manager" prop='projectmanager' [width]="260"
          [draggable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
            <div class="tcell-user">
              @if (row.usersList[0]?.photo) {
                <img [src]="row.usersList[0]?.photo"
                  class="icon icon-avatar icon-font-margin " alt="Avatar" />
              } @else {
                <i class="icon icon-avatar" aria-hidden="true"></i>
              }
            </div>
            <div class="tcell-user" [ngbTooltip]="row.usersList[0]?.firstName + ' ' + row.usersList[0]?.surName">
              <span class="uname"
              style="padding-right : 5px;">{{row.usersList[0]?.firstName}}</span>
              <span class="uname" >{{row.usersList[0]?.surName}}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
      }
      @if (filterColumns[4].selected) {
        <ngx-datatable-column name="Deployment" prop='deployment' [width]="230"
          [draggable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
            <div class="country-header">
              <span class="company"
              [ngbTooltip]="row.projectDeploymentTypeName">{{row.projectDeploymentTypeName}}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
      }
      @if (filterColumns[5].selected) {
        <ngx-datatable-column name="Status" prop='status' [width]="230"
          [draggable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
            <div class="country-header">
              <span class="company" [ngbTooltip]="row.projectStatusName">{{row.projectStatusName}}</span>
            </div>
          </ng-template>
        </ngx-datatable-column>
      }
      @if (filterColumns[6].selected) {
        <ngx-datatable-column name="Duration" prop='projectstartdate' [width]="350"
          style="margin-left:50px" [draggable]="false">
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
            <div class="duration-cell-container">
              <div class="year">
                <span [ngbTooltip]="row.projStartDate">{{row.projStartDate}}</span>
                <span [ngbTooltip]="row.projEndDate">{{row.projEndDate}}</span>
              </div>
              <div class="year-progress">
                <div class="progress" [ngStyle]="{'width': row.projectPercentageCompletion*1 + '%'}"
                [class.projectCompleted]="row.projectPercentageCompletion === 100"></div>
              </div>
            </div>
          </ng-template>
        </ngx-datatable-column>
      }
      <ngx-datatable-column [width]="50" [sortable]="false" [canAutoResize]="false" [draggable]="false"
        [resizeable]="false">
        <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
            <button cursorTooltip [tooltipOptions]="{
          arrow: false,
          placement: 'bottom',
          followCursor: true,
          content: 'Project information'
          }" class="last-icon" (click)="openProjectInfo(row)">
            <i class="icon square-menu-icon" aria-hidden="true"></i>
          </button>
        </ng-template>
      </ngx-datatable-column>
      @if (!filterColumns[0].selected && !filterColumns[1].selected &&
        !filterColumns[2].selected &&!filterColumns[3].selected &&
        !filterColumns[4].selected && !filterColumns[5].selected && !filterColumns[6].selected) {
        <ngx-datatable-column [sortable]="false">
        </ngx-datatable-column>
      }

      <ngx-datatable-footer>
        <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
          let-selectedCount="selectedCount" let-curPage="curPage" let-offset="offset" let-isVisible="isVisible">
          <div class="page-count"></div>
        </ng-template>
      </ngx-datatable-footer>
    </ngx-datatable>


    <div class="pagination">
      <pagination-controls id="pagination1" (pageChange)="pageChange($event)" previousLabel="Previous" nextLabel="Next">
      </pagination-controls>
    </div>



    @if (isSystemManagerSupportRole) {
      <a class="item add-new" routerLink="/project">
        <label>
          <i class="icon icon-add-new" aria-hidden="true"></i>
        </label>
        <span i18n>Create new project</span>
      </a>
    }
  </div>

</div>
