@if (loading) {
<div class="loading-box">
  <div class="img" alt="loading"></div>
</div>
}
@for (item of notification; track item) {
<div class="notification-item-container container">
  <div class="first-line row">
    <div class="title col-sm-9">
      <span class="type">Assess reliability is running for installation
        <b>{{item.substationName}}</b> ({{item.status}} : {{item.progressPercentage}} %)</span>
      <mat-progress-bar id="progress-bar" mode="determinate" [value]="item.progressPercentage"></mat-progress-bar>
    </div>
    <div class="col-sm-3"> <button id="action-button" class="btn-primary"
        (click)="dismissWithAction(item)">Cancel</button>
    </div>
  </div>
</div>
}