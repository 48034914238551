<div class="modal-container">
    <div class="modal-header">
        <span class="modal-title" id="modal-basic-title">Confirm</span>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('back')">
            <span>&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <span i18n>Are you sure want to cancel assess reliability for {{substationName}}?</span>
    </div>
    <div class="modal-footer">
        <a class="btn btn-cancel" (click)="submit('no')" i18n>No</a>
        <a class="btn btn-delete" (click)="submit('yes')" i18n>Yes</a>
    </div>
</div>