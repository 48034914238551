import { AlarmNotificationSettingsComponent } from './modal/alarm-notification-settings/alarm-notification-settings.component';
import { Location } from '@angular/common';
import { Component, DoCheck, ElementRef, EventEmitter, HostListener, inject, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { NgbActiveModal, NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import moment from 'moment';
import { fromEvent, interval, merge, of } from 'rxjs';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { finalize, map } from 'rxjs/operators';
import { ApplicationService } from '../../../services/application.service';
import { NotificationService } from '../../../services/notification.service';
import { AlarmService } from '../../../services/alarm.service';
import { MainHeaderService } from './main-header.service';
import { NotificationSettingsComponent } from './modal/notification-settings/notification-settings.component';
import { SystemNotificationsComponent } from './modal/system-notifications/system-notifications.component';
import { PopUpService } from 'src/services/popUp.service';
import { Subscription } from 'rxjs';
import * as RouteHelper from 'src/app/utility/routing.helper';
import { ConstService } from 'src/services/const.service';
import { RolesService } from 'src/services/roles.service';
import { IndexDBService } from 'src/services/indexdb.service';
import { SldUtilService } from 'src/app/containers/sld-editor/services/util.service';
import { HelperService } from 'src/app/containers/sld-editor/services/helper.service';
import { SldMainService } from 'src/app/containers/sld-editor/sld-main.service';
import { SettingsService } from 'src/services/settings.service';

interface MenuItem {
  name: string;
  url?: string;
  icon: any;
  roles?: string[];
  active?: boolean;
}

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss'],
  providers: [NgbActiveModal,SldUtilService, HelperService]
})
export class MainHeaderComponent implements OnInit, OnDestroy, DoCheck {
  @ViewChild('role', { static: false }) role: ElementRef;
  @ViewChild('version', { static: false }) version: ElementRef;
  @ViewChild('navbar', { static: false }) navbar: ElementRef;
  @ViewChild('hamburger', { static: false }) hamburger: ElementRef;
  @ViewChild('systemWarn', { static: false }) systemWarn: ElementRef;
  @ViewChild('systemDetail', { static: false }) systemDetail: ElementRef;

  public db: any = {};
  public RefershTokenCalled: any;
  public project: any;
  userName: string;
  buildVersionNumber: string;
  buildVersionNumberError: boolean;
  userImage;
  showDropdown = false;
  loading = false;
  public topMenu = [];
  public bottomMenu = [];
  public allMenu = {};
  public menuItems: any = [];
  public externalMenuItems: any = [];
  //combination of menuItems and externalMenuItems
  public allMenuItenms: any = [];
  login: boolean;
  public highlightNotification = null;
  public systemNotifications = { relCareConnectionError: false, scadaGlobalError: false, scadaAssetErrors: [] };
  versionList = [];
  isRefreshToken: boolean = false;
  userRole = '';
  roleType = '';
  custName = '';
  projName = '';
  @Input() showExtraInfo: boolean;
  display: string;
  rolePopup = false;
  versionPopup = false;
  public openSide = false;
  selectedRole: any = {};
  selectedVersion: any = {};
  filterRoles: any = [];
  hideClientProjectDetail = false;
  isSystemEnv: boolean;
  ishide = true;
  modalRef;
  InprogressCount = 0;
  public metadataInfo: { title: string, clientName: string, projectName: string, kernelVersion: string, systemDeploymentName: string };
  allMenuDetails: any = [
    {'name': 'Operations center', 'black': 'icon-op-center', 'white': 'icon-op-center-w'},
    {'name': 'Home', 'black': 'icon-home', 'white': 'icon-home-w'},
    {'name': 'Projects', 'black': 'icon-projects', 'white': 'icon-projects-w'},
    {'name': 'Installed base', 'black': 'icon-installedbase', 'white': 'icon-installedbase-w'},
    {'name': 'Maintenance', 'black': 'icon-maintenance', 'white': 'icon-maintenance-w'},
    {'name': 'Switching operations', 'black': 'icon-switchingoperations', 'white': 'icon-switchingoperations-w'},
    {'name': 'Documentation', 'black': 'icon-documentation', 'white': 'icon-documentation-w'},
    {'name': 'Dashboards', 'black': 'icon-dashboard', 'white': 'icon-dashboard-w'},
    {'name': 'Configuration', 'black': 'icon-configuration', 'white': 'icon-configuration-w'},
    {'name': 'Policies', 'black': 'icon-policies', 'white': 'icon-policies-w'},
    {'name': 'Audit Logs', 'black': 'icon-auditlog', 'white': 'icon-auditlog-w'},
    {'name': 'Plan_Viewer', 'black': 'icon-install-base', 'white': 'icon-install-base'}
  ];

  defaultPageSize = 7;
  page = 0;
  alarmPage = 0;

  isOpen = false;
  isAlarmOpen = false;
  routerSub = null;
  online$: Observable<boolean>;
  showServerOfflineError: boolean = false;
  notificationPageSize = 10;
  alarmPageSize = 7;
  errorrole: any;
  alarmList: any = [];
  private _subscriptions: Subscription[] = [];
  systemRole: boolean;
  userInitials: string;
  compactMenu = false;
  filteredMenuItems: MenuItem[];
  openRightPanel = false;
  showExternalLink: boolean;
  @Output() compactmenuValue = new EventEmitter();
  OfflineUserName: any;
  networkStatus$: Subscription = Subscription.EMPTY;
  networkStatus: boolean;
  isOffline: boolean;
  mode: string;
  public isOpenBackgroundProcess:boolean;
  public assessReliabilityData:any;
  public message: string;
  public showAssessReliabilityIcon: boolean;
  public substationName: any;
  userRoles: any;
  sldMainService = inject(SldMainService);
  isAPICallInprogress: boolean = false;
  showAI: string;
  AILink: any;
  isExternal: boolean = false;

  constructor(
    public appSvc: ApplicationService, public router: Router,
    private mainheader: MainHeaderService,
    private loc: Location, private mysettingsService: SettingsService,
    private notificationService: NotificationService,
    private alarmService: AlarmService,private sldService:SldUtilService,
    public activeModal: NgbActiveModal, private modalService: NgbModal, config: NgbModalConfig, private indexDbService:IndexDBService,
    private popupService: PopUpService, private constService: ConstService, public rolesService: RolesService
  ) {
    config.backdrop = 'static';
  }

  isMarkAllRead() {
    return !this.appSvc.notifications.some(x => !x.isRead);
  }

  isAllAcknowledged() {
    return !this.appSvc.allAlarms.some(x => !x.isAcknowledged);
  }

  changeVersion(item) {
    this.selectedVersion = item;
    sessionStorage.setItem('versionId', this.selectedVersion.id);
    sessionStorage.setItem('versionName', this.selectedVersion.name);
    this.openSide = false;
    this.versionPopup = false;
    if ((this.loc.path() === RouteHelper.addProjectPrefix(`/dashboard/kernel-parameters`)) || (this.loc.path().includes('edit-equipment'))) {
      this.redirectTo(RouteHelper.addProjectPrefix(`/dashboard/kernel-parameters`));
    }
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl(RouteHelper.addProjectPrefix(`/dashboard`), { skipLocationChange: true }).then(() =>
      this.router.navigate([uri]));
  }

  showAlarms(): boolean {
    return this.rolesService.hasAnyRoles([this.rolesService.OPERATION_SME, this.rolesService.REMOTE_OPERATOR]);
  }

  ngOnInit() {
    this.mode = window.location.href.indexOf('offline') === -1 ? 'online' : 'offline';
    this.isExternal = RouteHelper.isExternalProject();
    if(this.mode === 'online' && navigator.onLine){
      this.onRolesUpdated();
         // Assess reliability status refresh
         this.showAssessReliabilityIcon = (this.roleType.includes(this.rolesService.PROJECT_SUPPORT) || this.roleType.includes(this.rolesService.PROJECT_MANAGER))&& (!this.isSystemEnv);
         if ((this.roleType.includes(this.rolesService.PROJECT_SUPPORT) || this.roleType.includes(this.rolesService.PROJECT_MANAGER)) && !this.isSystemEnv) {
           this.assessReliabilityGetData();
         }
      this._subscriptions.push(this.mainheader.rolesUpdated$.subscribe(() => {
        this.onRolesUpdated();
      }));
      this._subscriptions.push(this.mainheader.assessReliabilityUpdated$.subscribe(() => {
        this.assessReliabilityGetData();
        if (!this.roleType?.includes(this.rolesService.TENANT_ADMIN)) {
          this.getNotifications();
        }
      }));
    }
    if(this.mode==='offline'){
      if(sessionStorage.getItem('UserName')){
        this.userName= sessionStorage.getItem('UserName')
        this.userInitials = this.userName.split(' ').map(name => name[0]).join('').toUpperCase();
      }
      else{
        this.router.navigateByUrl('/offline')
      }
    }
    this.checkNetworkStatus()
    if(this.router.url.indexOf('/offline') !== -1){
      this.indexDbService.getUsers().subscribe((res:any)=>{
        this.userRoles = res[0].userRoles.split(',');
        sessionStorage.setItem('UserRoles',this.userRoles);
      })
    }
  }

  menu() {
    if(this.mode === 'online'){
      this.menuItems = [
        {
          name: 'Operations center',
          url: `/operationscenter`,
          icon: 'icon-op-center-w',
          roles: [this.rolesService.SYSTEM_MANAGER, this.rolesService.ADMIN, this.rolesService.SYSTEM_SUPPORT, this.rolesService.POLICY_MANAGER, this.rolesService.PROJECT_MEMBER, this.addControlRoomForSystemEnv()]
        },
        {
          name: 'Home',
          url: RouteHelper.addProjectPrefix(`/homepage`),
          icon: 'icon-home-w',
          roles: [this.rolesService.PROJECT_MANAGER, this.rolesService.PROJECT_SUPPORT, this.rolesService.PROJECT_MANAGER_C, this.rolesService.MAINTENANCE_MANAGER, this.rolesService.EXECUTION_TEAM_LEAD, this.rolesService.EXECUTION_OWNER, this.rolesService.OPERATION_SME, this.rolesService.REMOTE_OPERATOR, this.rolesService.POLICY_SME, this.addControlRoomForProjectEnv()]
        },
        {
          name: 'Projects',
          url: `/projectdashboard`,
          icon: 'icon-projects-w',
          roles: [this.rolesService.SYSTEM_MANAGER, this.rolesService.SYSTEM_SUPPORT, this.rolesService.POLICY_MANAGER, this.rolesService.PROJECT_MEMBER, this.addControlRoomForSystemEnv()]
        },
        {
          name: 'Installed base',
          url: '/installedbase',
          icon: 'icon-installedbase-w',
          roles: !this.isExternal ? [this.rolesService.SYSTEM_MANAGER, this.rolesService.ADMIN, this.rolesService.SYSTEM_SUPPORT, this.rolesService.POLICY_MANAGER, this.rolesService.PROJECT_MEMBER, this.addControlRoomForSystemEnv()] : []
        },
        {
          name: 'Installed base',
          url: RouteHelper.addProjectPrefix(`/installedbase`),
          icon: 'icon-installedbase-w',
          roles: !this.isExternal ? [this.rolesService.PROJECT_MANAGER, this.rolesService.PROJECT_SUPPORT, this.rolesService.PROJECT_MANAGER_C, this.addControlRoomForProjectEnv()] : []
        },
        {
          name: 'Maintenance',
          url: RouteHelper.addProjectPrefix(`/maintenance-management`),
          icon: 'icon-maintenance-w',
          roles: [this.rolesService.PROJECT_MANAGER, this.rolesService.PROJECT_SUPPORT, this.rolesService.PROJECT_MANAGER_C, this.rolesService.MAINTENANCE_MANAGER, this.rolesService.EXECUTION_TEAM_LEAD, this.rolesService.EXECUTION_OWNER, this.addControlRoomForProjectEnv()]
        },
        {
          name: 'Switching operations',
          url: RouteHelper.addProjectPrefix(`/operations/create`),
          icon: 'icon-switchingoperations-w',
          roles: [this.rolesService.OPERATION_SME, this.rolesService.REMOTE_OPERATOR]
        },
        {
          name: 'Documentation',
          url: RouteHelper.addProjectPrefix(`/reliability-center/knowledge-bank`),
          icon: 'icon-documentation-w',
          roles: [this.rolesService.PROJECT_MANAGER, this.rolesService.PROJECT_SUPPORT, this.rolesService.PROJECT_MANAGER_C, this.rolesService.MAINTENANCE_MANAGER, this.rolesService.EXECUTION_TEAM_LEAD, this.rolesService.EXECUTION_OWNER, this.rolesService.OPERATION_SME, this.rolesService.REMOTE_OPERATOR, this.rolesService.POLICY_SME, this.addControlRoomForProjectEnv()]
        },
        {
          name: 'Dashboards',
          url: RouteHelper.addProjectPrefix(`/dashboard/performance-monitoring`),
          icon: 'icon-dashboard-w',
          roles: [this.rolesService.PROJECT_MANAGER, this.rolesService.PROJECT_SUPPORT, this.rolesService.PROJECT_MANAGER_C, this.rolesService.MAINTENANCE_MANAGER, this.rolesService.EXECUTION_TEAM_LEAD, this.rolesService.EXECUTION_OWNER, this.rolesService.OPERATION_SME, this.rolesService.REMOTE_OPERATOR, this.rolesService.POLICY_SME, this.addControlRoomForProjectEnv()]
        },
        {
          name: 'Policies',
          url: RouteHelper.addProjectPrefix(`/policy`),
          icon: 'icon-policies-w',
          roles: [this.rolesService.POLICY_MANAGER, this.rolesService.PROJECT_MANAGER, this.rolesService.PROJECT_SUPPORT, this.rolesService.PROJECT_MANAGER_C, this.rolesService.POLICY_SME, this.rolesService.MAINTENANCE_MANAGER, this.rolesService.EXECUTION_TEAM_LEAD, this.addControlRoomForProjectEnv()]
        },
        {
          name: 'Configuration',
          url: RouteHelper.addProjectPrefix(`/dashboard/project-settings`),
          icon: 'icon-configuration-w',
          roles: [this.rolesService.SYSTEM_MANAGER, this.rolesService.ADMIN, this.rolesService.SYSTEM_SUPPORT, this.rolesService.CONTROL_ROOM, this.rolesService.PROJECT_MANAGER, this.rolesService.PROJECT_SUPPORT, this.rolesService.PROJECT_MANAGER_C]
        },
        {
          name: 'Audit Logs',
          url: RouteHelper.addProjectPrefix('/audit-logs'),
          icon: 'icon-auditlog-w',
          roles: [this.rolesService.PROJECT_MANAGER]
        },
        {
          name: 'Plan_Viewer',
          url: RouteHelper.addProjectPrefix('/plan-viewer'),
          icon: 'icon-install-base',
          roles: []
        }
      ];
    }
    else{
      this.menuItems=[
        {
        name: 'Projects',
        url: `/projectdashboard`,
        icon: 'icon-projects-w'
      }
      ]
    }

    this.externalMenuItems = [
      {
        name: 'Projects',
        url: `/projectdashboard`,
        icon: 'icon-projects-w',
        roles: [this.rolesService.PROJECT_MANAGER, this.rolesService.PROJECT_SUPPORT, this.rolesService.PROJECT_MANAGER_C, this.addControlRoomForProjectEnv()]
      }
    ];
  }

  openSlideMenu() {
    this.openSide = !this.openSide;
    const token = JSON.parse(sessionStorage.getItem('previleges'));
    this.systemRole = token?.isSystemRole;
    let userRoles = token?.roles;
    this.menu();
    this.filteredMenuItems = this.menuItems.filter(item => {
      item['active'] = this.router.isActive(item.url, true);
      return !item.roles || item.roles.some(role => userRoles?.includes(role));
    });
    this.allMenuItenms = [...this.filteredMenuItems, ...this.externalMenuItems];
    this.getCompactMenuActiveIcons();
    if(this.compactMenu) {
      this.openSide = false;
      this.compactMenu = false;
      this.sendCompactMenuValue();
    }
  }
  getAIDataFromEnvironmentalVariable() {
    this.showAI = this.mysettingsService.settings.isAIEnabled;
    this.AILink = this.mysettingsService.settings.openAILink;
  }
  openNewTab() {
    window.open(this.AILink);
  }
  onRolesUpdated() {
    this.appSvc.setUserLogin(true);
    this.errorrole = localStorage.getItem('errorrole');
    this.login = true;
    this.systemRole = this.rolesService.isSystemRole();
    this.showExternalLink = this.rolesService.hasAnyRoles([this.rolesService.PROJECT_MANAGER, this.rolesService.PROJECT_SUPPORT,
       this.rolesService.PROJECT_MANAGER_C, this.addControlRoomForProjectEnv()]) && !this.systemRole && !this.isExternal;
    if (sessionStorage.length > 4) {
      this.roleType = sessionStorage.getItem('UserRoles');
      this.getAIDataFromEnvironmentalVariable();
      if (!this.roleType?.includes(this.rolesService.TENANT_ADMIN)) {
        this.appSvc.getCurrentBuildVersion().subscribe({
          next: (resp) => {
            this.buildVersionNumberError = false;
            this.buildVersionNumber = resp.returnObj;
          }, error: (err) => {
            console.log("currentBuildVersion : Failed" + err);
            if (err != null && err.error != null && err.error.text != null) {
              this.buildVersionNumberError = true;
              this.buildVersionNumber = err.error.text;
            }
          }
        });

        this.isSystemEnv = this.roleType?.includes(this.rolesService.SYSTEM_MANAGER) || this.roleType?.includes(this.rolesService.SYSTEM_SUPPORT) ||
         this.roleType?.includes(this.rolesService.POLICY_MANAGER) || this.roleType?.includes(this.rolesService.PROJECT_MEMBER) ||
          this.roleType?.includes(this.rolesService.CONTROL_ROOM) && this.systemRole ? true : false;
      }
    }
    if (sessionStorage.length > 4) {
      this.ishide = false;

      if (!this.roleType?.includes(this.rolesService.TENANT_ADMIN)) {
        this.isRefreshToken = (sessionStorage.getItem('RefershToken') != null) ? true : false;
        this.mainheader.navItem$.subscribe({
          next: (res) => {
            this.appSvc.GetAllKernelVersions().subscribe(version => {
              this.versionList = version;
              this.selectedVersion = version.slice(-1).pop();
              sessionStorage.setItem('versionId', this.selectedVersion.id);
              sessionStorage.setItem('versionName', this.selectedVersion.name);
              localStorage.setItem('versionName', this.selectedVersion.name);
            });
          }, error: () => { }
        });
      }

      if (!this.roleType?.includes(this.rolesService.TENANT_ADMIN) && RouteHelper.isInProjectMode()) {
        this.appSvc.GetDefaultCustomerDetails().subscribe({
          next: (data) => {
            if (data.returnObj) {
              this.custName = data.returnObj.custname;
              this.projName = data.returnObj.projectname;
            }
          }, error: () => { }
        });
      }
      if (!this.routerSub) {
        this.routerSub = this.router.events
          .pipe(filter(event => event instanceof NavigationEnd))
          .subscribe((value) => {
            if (!this.roleType?.includes(this.rolesService.TENANT_ADMIN)) {
              this.getNotifications();
            }
            this.getAlarms();
          });
      }

      if (!this.roleType?.includes(this.rolesService.TENANT_ADMIN)) {
        this.getNotifications();
      }
      this.getAlarms();

      this.online$ = merge(
        of(navigator.onLine),
        fromEvent(window, 'online').pipe(map(() => true)),
        fromEvent(window, 'offline').pipe(map(() => false))
      );
      const roleTypeArr = this.roleType?.split(',');

      this.roleType = this.roleType?.includes(this.rolesService.SYSTEM_MANAGER) ? this.rolesService.SYSTEM_MANAGER : this.roleType;
      if (!this.roleType?.includes(this.rolesService.TENANT_ADMIN)) {
        this.roleType = this.roleType?.includes(this.rolesService.ADMIN) ? this.rolesService.ADMIN : this.roleType;
      }
      this.hideClientProjectDetail = this.roleType === this.rolesService.ADMIN || this.roleType === this.rolesService.TENANT_ADMIN ? true: false

      this.appSvc.getApplicationMetadata().subscribe((resp) => this.metadataInfo = resp);
      this.userName = sessionStorage.getItem('UserName');
      this.userInitials = this.userName?.split(' ').map(name => name[0]).join('').toUpperCase();
      this.userImage = sessionStorage.getItem('UserImage');
    }
  }
  assessReliabilityGetData() {
    this.InprogressCount = 0;
    this.sldService.getAssessReliabiltyJobStatus().subscribe({
      next: res => {
        this.assessReliabilityData = res.returnObj;
        sessionStorage.setItem('assessReliability', JSON.stringify(res.returnObj));
        this.assessReliabilityData?.forEach(element => {
          if(element.status === "In progress"){
            this.InprogressCount = this.InprogressCount+1;
          }
        });
        if(this.assessReliabilityData?.length > 0 && this.isAPICallInprogress == false) {
          this.isAPICallInprogress = true;
          this.callEveryInstanceOfAssessReliability()
        }
      }, complete: () => { },
      error: () => { this.loading = false; }
    });
  }
  callEveryInstanceOfAssessReliability(){
    interval(this.constService.assessReliabilityRefreshTime).subscribe((func => {
      if(this.assessReliabilityData?.length > 0) {
         let TempInprogressCount = 0;
        this.sldService.getAssessReliabiltyJobStatus().subscribe({
          next: res => {
            this.assessReliabilityData = res.returnObj;
            sessionStorage.setItem('assessReliability', JSON.stringify(res.returnObj));
            let substationId = sessionStorage.getItem('substationId');
            var target = this.assessReliabilityData.filter(f => f.substationId.includes(substationId));
            if (target.length > 0) {
              this.sldMainService.onProgressUpdate.next(target[0].progressPercentage);
            } else {
              this.sldMainService.onButtonClick.next(substationId);
            }
            this.assessReliabilityData?.forEach(element => {
              if(element.status === "In progress"){
              TempInprogressCount = TempInprogressCount+1;
              }
            });
            if (this.assessReliabilityData.length == 0) {
              this.sldMainService.onButtonClick.next("substationId");
              this.isAPICallInprogress = false;
              this.getNotifications();
            }
            this.InprogressCount = TempInprogressCount;
          }, complete: () => { },
          error: () => { this.loading = false; }
        });      }
    }));
  }

  showCancelMessage(event) {
    this.message = this.constService.assessReliabilityToasterCancelled;
    this.substationName = event;
    setTimeout ( () => {
      this.message = "";
   }, 5000);
  }
  onAssessStatusClick() {
    this.message = "";
  }
  ngOnDestroy() {
    if (this.routerSub !== null) {
      this.routerSub.unsubscribe();
    }
    if (this.appSvc.subscription != undefined) {
      this.appSvc.subscription.unsubscribe();
    }

    this._subscriptions.forEach(sub => sub.unsubscribe());
  }

  getAlarms() {
    if (sessionStorage.length > 1 && this.rolesService.hasAnyRoles([this.rolesService.OPERATION_SME, this.rolesService.REMOTE_OPERATOR])) {
      sessionStorage.getItem('RefershToken')
      this.loading = true;
      this.alarmService.getAlarms().subscribe({
        next: res => {
          this.loading = false;
          let temp = _.cloneDeep(res.items);
          temp = _.orderBy(temp, 'createdAt', 'desc');
          const list = _.map(temp, item => {
            const data = _.cloneDeep(item);
            let createdAtLocal = moment.utc(data.createdAt).local();
            data.createdAt = createdAtLocal.fromNow();
            return data;
          });
          this.appSvc.allAlarms = list;
          this.appSvc.alarms = list.slice(0, this.alarmPageSize - 1);
          this.alarmList = list.slice(0, this.alarmPageSize - 1);
          this.loading = false;

        }
      });
    }
  }

  getNotifications() {
    if (sessionStorage.length > 4 && !this.roleType?.includes(this.rolesService.TENANT_ADMIN)) {
      sessionStorage.getItem('RefershToken')
      this.notificationService.getNotifications().subscribe(res => {
        let temp = _.cloneDeep(res);
        temp = _.orderBy(temp, 'createdAt', 'desc');
        const list = _.map(temp, item => {
          const data = _.cloneDeep(item);
          let createdAtLocal = moment.utc(data.createdAt).local();
          data.createdAt = createdAtLocal.fromNow();
          return data;
        });
        this.appSvc.allNotifications = list;
        this.appSvc.notifications = list.slice(0, this.defaultPageSize - 1);
        this.loading = false;

        if (this.appSvc.notificationId) {
          let notificationId = this.appSvc.notificationId;
          let notification = res.find(x => x.notificationIds.includes(notificationId));
          if (notification && notification.subType == 'Substation risks') {
            for (let task of notification.substationIds)
              task.isRead = false;
            this.appSvc.notificationId = null;
          }
          if (notification && notification.subType == 'Asset risks') {
            for (let task of notification.assetIds)
              task.isRead = false;
            this.appSvc.notificationId = null;
          }
        }
      });
    }
  }

  displayNotificationMessage() {
    /// commenting this call until the system goes live.
    this.notificationService.getSystemNotifications().subscribe({
      next: (value) => {
        this.showServerOfflineError = false;
      },
      error: (err) => {
        if (err.status === 0) {
          this.showServerOfflineError = true;
        }
      }
    }
    );
  }

  ngDoCheck() {
    if(this.mode==='online' && navigator.onLine){
    this.appSvc.notifications = this.appSvc.allNotifications.slice(0, this.page * this.notificationPageSize - 1 + this.defaultPageSize);
    this.appSvc.alarms = this.appSvc.allAlarms.slice(0, this.alarmPage * this.alarmPageSize - 1 + this.defaultPageSize);
  }
  }

  goTo(item) {
    this.popupService.popup.next('close');
    this.appSvc.setEquipment(this.constService.emptyGuid);
    this.appSvc.setSubstation(this.constService.emptyGuid);
    this.appSvc.setRegionId(this.constService.emptyGuid);
    this.appSvc.setBayId(this.constService.emptyGuid);
    this.appSvc.setPlanId(this.constService.emptyGuid);
    if (item.name === 'Projects' && this.showExternalLink && this.router.url.indexOf('offline') === -1) {
      const tab = window.matchMedia('(display-mode: standalone)').matches ? '_self' : '_blank';
      window.open(`/projectdashboard`, tab);
      return;
    } else if (item.name === 'Projects' && this.router.url.indexOf('offline') !== -1) {
      this.openSide = false;
      this.router.navigateByUrl('/offline/projectdashboard')
    } else if (item.name === 'Configuration' && (!this.rolesService.isControlRoomProject() && this.rolesService.hasAnyRoles([this.rolesService.CONTROL_ROOM, this.rolesService.SYSTEM_MANAGER]))) {
      this.openSide = false;
      this.router.navigateByUrl(`/dashboard/project-settings`);
    } else {
      if (item.url !== '') {
        this.openSide = false;
        this.router.navigate([item.url]);
      }
    }
    if(this.compactMenu) {
      this.openSide = true;
    }
    if(this.filteredMenuItems) {
      this.filteredMenuItems.forEach(element => {
        element.active = item.name === element.name ? true : false;
      });
    }
    this.externalMenuItems.forEach(element => {
      element.active = item.name === element.name ? true : false;
    });
    this.getCompactMenuActiveIcons();
  }

  addControlRoomForSystemEnv() {
    return this.rolesService.isControlRoomSystem() ? this.rolesService.CONTROL_ROOM : null;
  }

  addControlRoomForProjectEnv() {
    return this.rolesService.isControlRoomProject() ? this.rolesService.CONTROL_ROOM : null;
  }

  @HostListener('document:click', ['$event'])
  clickedOutside($event) {
    if (this.rolePopup) {
      this.highlightNotification = null;
    }
    this.rolePopup = false;
    this.versionPopup = false;
    // check if clicked is HTML element
    if ($event.target instanceof Element || $event.target instanceof HTMLDocument) {
      const targetClassName = $event.target.className;
      const offsetParent = $event.target.offsetParent;
      const offsetParentClassName = offsetParent && offsetParent.className;

      if (typeof targetClassName === 'string' &&
          !targetClassName.includes('bell') &&
          !(offsetParent && typeof offsetParentClassName === 'string' && offsetParentClassName.includes('notification'))) {
          this.isOpen = false;
          this.page = 0;
      }

      if (typeof targetClassName === 'string' &&
          !targetClassName.includes('update') &&
          !(offsetParent && typeof offsetParentClassName === 'string' && offsetParentClassName.includes('notification'))) {
          this.isOpenBackgroundProcess = false;
      }

      if (typeof targetClassName === 'string' &&
          !targetClassName.includes('exclamation') &&
          !(offsetParent && typeof offsetParentClassName === 'string' && offsetParentClassName.includes('alarm'))) {
          this.isAlarmOpen = false;
          this.alarmPage = 0;
      }

      if (this.openSide && !this.compactMenu) {
          if (!this.navbar.nativeElement.contains($event.target) && !this.hamburger.nativeElement.contains($event.target)) {
              this.openSlideMenu();
          }
      }
      // if d3 created element close menu
    } else {
      this.isOpen = false;
      this.page = 0;

      this.isAlarmOpen = false;
      this.alarmPage = 0;
    }
    if ($event.target.innerHTML === 'Settings') {
      this.isOpen = false;
      this.page = 0;
      this.isAlarmOpen = false;
      this.alarmPage = 0;
    }
    if(this.compactMenu && this.openSide) {
      this.openSide = true;
    }
  }

  clickedAlarm($event: any) {
    sessionStorage.setItem('substationId', $event.substationId);
    this.router.navigateByUrl(RouteHelper.addProjectPrefix(`/operations/create/${$event.substationName}?substationId=${$event.substationId}&assetId=${$event.assetId}`));
    this.isAlarmOpen = false;
  }

  acknowledgedAlarm($event: any) {
    if ($event.alarm.isActive) return;
    this.getAlarms();
  }

  clickNotification($event: any) {
    if ($event && $event.notification.type.toLowerCase() === 'account' && $event.isRead) {
      if ($event.notification.subtype.toLowerCase() === 'add user to a role') {
        this.role.nativeElement.className = 'role-switch highlight';
        this.highlightNotification = _.cloneDeep($event.notification);
      }
    }

    if ($event.notification.type.toLowerCase() === 'performance'
      || $event.notification.category && $event.notification.category.toLowerCase() === 'planned') {
      this.isOpen = false;
      this.page = 0;
    }

    if ($event.notification.type.toLowerCase() === 'substation') {
      sessionStorage.setItem('substationId', $event.notification.substationIds[0].id);
      this.router.navigateByUrl(RouteHelper.addProjectPrefix(`/operations/create/${$event.notification.detail.includes("ubstation")}?substationId=${$event.notification.substationIds[0].id}`));
    }
    else if ($event.notification.type.toLowerCase() === 'new installation' &&
      (this.selectedRole.name === this.rolesService.PROJECT_SUPPORT || this.selectedRole.name === this.rolesService.PROJECT_MANAGER)) {
      this.router.navigateByUrl(RouteHelper.addProjectPrefix(`/project-modelling/1/${$event.notification.substationIds[0].id}`));
      this.isOpen = false;
    }
  }

  checkHighlightRole(role) {
    if (this.highlightNotification) {
      return _.includes(this.highlightNotification.detail, role.name);
    } else {
      const result = _.filter(this.appSvc.allNotifications, notification => {
        return (notification.type === 'account' && _.includes(notification.detail, role.name));
      });
      return result && result.length > 0;
    }
  }

  clickMarkAllRead() {
    this.loading = true;
    this.notificationService.markAllNotificationsRead()
      .pipe(finalize(() => this.loading = false))
      .subscribe(() => {
        for (let not of this.appSvc.notifications) {
          not.isRead = true;
        }
      });
  }

  clickLoadMore(e) {
    e.stopPropagation();
    if (this.appSvc.notifications.length < this.appSvc.allNotifications.length) {
      this.page += 1;
    }
  }

  clickLoadMoreAlarms(e) {
    e.stopPropagation();
    if (this.appSvc.alarms.length < this.appSvc.allAlarms.length) {
      this.alarmPage += 1;
    }
  }

  clickBell() {
    this.isOpen = true;
    this.isOpenBackgroundProcess = false;
  }
  clickupdate() {
    this.isOpenBackgroundProcess = !this.isOpenBackgroundProcess;
    if(this.isOpenBackgroundProcess) {
      this.isOpen = false;
      this.isAlarmOpen = false;
    }
  }

  clickAlarmIcon() {
    this.isAlarmOpen = true;
    this.isOpenBackgroundProcess = false;
  }

  clickSettings() {
    const modalRef = this.modalService.open(NotificationSettingsComponent, { centered: true });
    modalRef.componentInstance.title = 'Notification Settings';
    modalRef.result.then(ret => {
    }, () => {
    });
  }

  clickAlarmSettings() {
    this.modalService.open(AlarmNotificationSettingsComponent, { centered: true, windowClass: "xl-modal" })
      .result.then((result) => {

      }, (reason) => {

      });
  }

  clickSystemNotifcaionts() {
    const modalRef = this.modalService.open(SystemNotificationsComponent, { centered: true });
    modalRef.componentInstance.title = 'Connection errors to SCADA';
    modalRef.result.then(ret => {
    }, () => {
    });
  }
  getClass() {
    if (this.alarmList.find(x => x.severity == 'High'))
      return 'high';

    if (this.alarmList.find(x => x.severity == 'Medium'))
      return 'medium';

    if (this.alarmList.find(x => x.severity == 'Low'))
      return 'low';
  }
  openUserPopup(event) {
    event.stopPropagation();
    this.openRightPanel = !this.openRightPanel;
    if (this.openSide && this.openRightPanel && !this.compactMenu) {
      this.openSide = false;
    }
    return this.openRightPanel;
  }
  onValueChanged(value) {
    this.openRightPanel = value;
  }
  compactMenuFunc() {
    this.compactMenu = !this.compactMenu;
    this.openSide = true;
    this.getCompactMenuActiveIcons();
  }

  getCompactMenuActiveIcons() {
    if(this.compactMenu) {
      this.allMenuItenms.forEach(element => {
        this.allMenuDetails.forEach(item => {
          if(element.name === item.name) {
            element.icon = element.active ? item.black : item.white;
          }
        });
      })
    } else {
      this.allMenuItenms.forEach(element => {
        this.allMenuDetails.forEach(item => {
          if(element.name === item.name) {
            element.icon = item.white;
          }
        })
      })
    }
  }
  mouseOver(item) {
    if(!item.active) {
      this.allMenuItenms.forEach(element => {
        if(item.icon === element.icon) {
          this.allMenuDetails.forEach(menu => {
            if(item.name === menu.name) {
              element.icon = menu.black;
            }
          });
        }
      });
    }
  }
  mouseOut(item) {
    if(!item.active) {
      this.allMenuItenms.forEach(element => {
        if(item.icon === element.icon) {
          this.allMenuDetails.forEach(menu => {
            if(item.name === menu.name) {
              element.icon = menu.white;
            }
          });
        }
      });
    }
  }
  isPM() {
    return this.rolesService.hasRole(this.rolesService.PROJECT_MANAGER);
  }

  sendCompactMenuValue() {
    this.compactmenuValue.emit(this.compactMenu);
  }
  navigateToProjects(){
    this.router.navigateByUrl('/offline/projectdashboard')
  }
  checkNetworkStatus(){
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.isOffline = status
      });
  }
}
