import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { SuccesspopupComponent } from 'src/app/containers/dashboard/modals/successpopup/successpopup.component';
import { ApplicationService } from 'src/services/application.service';
import { ConstService } from 'src/services/const.service';

@Component({
  selector: 'app-add-existing-installation-modal',
  templateUrl: './add-existing-installation-modal.component.html',
  styleUrls: ['./add-existing-installation-modal.component.scss']
})
export class AddExistingInstallationModalComponent {
  loading = false;
  configPlan;
  installationsData;
  masterData;
  filterShow = false;
  requestdata;
  filterColumnsInst = [
    { prop: 'Installation', name: 'Installation', selected: true },
    { prop: 'substationOwner', name: 'Owner', selected: true },
    { prop: 'substationOperator', name: 'Operator', selected: true },
    { prop: 'Country', name: 'Country', selected: true },
    { prop: 'location', name: 'Location', selected: true },
    { prop: 'YearOFInstallation', name: 'Year of installation', selected: true },
    { prop: 'YearOfComissioning', name: 'Year of commissioning', selected: true },
    { prop: 'Technology', name: 'Technology', selected: true },
    { prop: 'UnderSLA', name: 'Under SLA', selected: true },
    { prop: 'UId', name: 'UID', selected: true }
  ];
  installMainFilterList? = [];
  installCompFilterList? = [];
  installAllFilterListCount: number;
  filterData:any;
  selectEnabled:boolean;
  filterDataFetch: Subscription;
  filterAPICall: string;
  filterStyles: any = { button: 30, space: 50, search: 20 };
  projectId;
  filterStr = "";
  sortOrder = 'ASC';
  sortColumn = 'Installation';
  selected: any = [];
  constructor(private appSvc: ApplicationService, private constService :ConstService,
    public activeModal: NgbActiveModal, private modalService: NgbModal) {
      this.configPlan = {
        id: "pagination",
        currentPage: 1,
        itemsPerPage: 10,
        totalItems: 0
      };
}
ngOnInit() {
  this.projectId = sessionStorage.getItem('projectId');
  this.getInstallationMasterData();
  this.getAllInstallations();
}
getAllInstallations() {    
  this.loading = true;   
  let filterList  = this.filterData?.filterDataList == undefined ? [] : (this.mainFilterTotalCount(this.filterData?.filterDataList) == 0 ? [] : [this.filterData?.filterDataList]) ; 
  const data = {      
    "projectId": this.projectId, 
    "pageNumber": this.configPlan.currentPage,      
    "pageSize": this.configPlan.itemsPerPage,      
    "searchText": this.filterStr,      
    "sortOrder": this.sortOrder,      
    "sortColumn": this.sortColumn,      
    "filtersdata":filterList
   }    
   this.appSvc.getAllInstallationdforGridModellings(data).subscribe({next:res => {      
     this.filterAPICall = "InstallationsDetails";
     this.loading = false;      
     this.installationsData = res.returnObj.totalInstallations;      
     this.configPlan.totalItems = res.returnObj.totalNoOfSubstations;    
   }, error: () => {      
     this.loading = false;    
   }});    
 }
 onRowClick(event: any) {
 
  if (event.type === 'click' && event.row.underSLA !== "Yes") {
    this.requestdata = {
      "sourceId": event.row?.projectId,
      "destinationId": sessionStorage.getItem('projectId'),
      "substationId": event.row?.installationId,
      "requestedUserId":sessionStorage.getItem('UserId')
    };
    if(this.selected.includes(event.row)) {
      this.selected = this.selected.filter(row => row !== event.row);
      this.selectEnabled = false;
    }else {
      this.selected = [event.row];
      this.selectEnabled = true;
    }
  }
 }
 getRowClass = (row: any) => {
  return {
    'highlight-row': this.selected?.includes(row),
    'disabled': row.underSLA === 'Yes'
  }
}
 selectInstallation(event) {
  this.loading= true;  
  this.appSvc.importExistingInstallations(this.requestdata).subscribe({next:res=> {
    const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
    this.loading = false;  
    this.closeModal();  
    modalRef.componentInstance.title = 'Message';
    modalRef.componentInstance.message = res.errorMessage;
  }, error: () => {      
    this.loading = false;    
  }});
 }
 getInstallationMasterData(){
 // this.loading = true;
  this.appSvc. GetInstallationsMasterDataForGridModelling(this.projectId).subscribe({next:(res) => {
    this.masterData = res.returnObj;
    let flag = 0;
    Object.keys(this.masterData).forEach((key) => {
      if (this.projectId == this.constService.emptyGuid && this.constService.installationMasterDataForGrid.includes(key)) {
        flag = flag + this.masterData[key].length;
        this.installMainFilterList.push({
          key: key,
          value: key
        });
      }
      else if (this.projectId != this.constService.emptyGuid && this.constService.installationMasterDataForGrid.includes(key)) {
        flag = flag + this.masterData[key].length;
        this.installMainFilterList.push({
          key: key,
          value: key
        });
      }
    }
    );   
    Object.keys(this.masterData).forEach((key) => 
    {
      this.installCompFilterList.push({
      menuId: key,
      menuValues: Object.values(this.masterData[key])
    });
  });
     this.installAllFilterListCount = flag;
   // this.loading = false;
  }, error: () => {
    this.loading = false;      
  }});
}
 mainFilterTotalCount(req){
  let flag = 0;
  Object.keys(req).forEach((key) =>  
    {
    flag = flag + req[key].length;
  });
  return flag;
}
paginateData(event) {
  this.configPlan.currentPage = event;
  this.getAllInstallations();
}

sort(event) {
  this.updateSortDirection(event);
  this.sortColumn= this.updateSortColumn(event.sorts[0].prop);
  this.getAllInstallations();
  this.loading = false;

}
updateSortDirection(event) {
  if (this.sortOrder == "ASC" && this.updateSortColumn(event.sorts[0].prop) == this.sortColumn) {
    this.sortOrder = "DESC";
    document.getElementsByClassName("sort-btn")[0].classList.add("sort-desc");
    document.getElementsByClassName("sort-btn")[0].classList.add("datatable-icon-up");
  }
  else {
    this.sortOrder = "ASC";
    document.getElementsByClassName("sort-btn")[0].classList.add("sort-asc");
    document.getElementsByClassName("sort-btn")[0].classList.add("datatable-icon-up");
  }
}
updateSortColumn(sortColumn): string {
  sortColumn = sortColumn.slice(sortColumn.indexOf(".") + 1, sortColumn.length);
  return sortColumn.charAt(0).toUpperCase() + sortColumn.slice(1);
}


search(str : string) {
    this.filterStr = str;
    this.configPlan.currentPage = 1;
    this.getAllInstallations();
}

filterItemEmit(event) {
  this.filterData = event;
  this.getAllInstallations();
}

closeModal() {
  this.activeModal.close({ type: 'closeModal' });
}

}
