import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationService } from '../../../../../services/application.service';

@Component({
  selector: 'app-assign-member',
  templateUrl: './assign-member.component.html',
  styleUrls: ['./assign-member.component.scss']
})
export class AssignMemberComponent implements OnInit {
  @ViewChild('allUsers', { static: false }) allUsersComponent: TemplateRef<any>;
  @Input() UserRole;
  @Input() teamId;
  showAssign = true;

  constructor(public activeModal: NgbActiveModal, private appSvc: ApplicationService) {
  }

  ngOnInit() {
  }
  gettingUserlength(event) {
    this.showAssign = true;
    this.showAssign = event;
  }

  save() {
    this.activeModal.close(this.allUsersComponent['selected']);
  }
}
