import { v4 as uuid }  from 'uuid';
import { WidgetSystemTypes } from '../edit-widget-dashboard/systemWidgets';

export interface WidgetSourceConfig {
  isPowerGrid: boolean;
  region: string;
  regionId: uuid;
  substation: string;
  substationId: uuid;
  equipmentCategory: string;
  equipmentCategoryId: uuid;
  type: string;
  typeId: uuid;
  asset: string;
  assetId: uuid;
  equipmentId?: uuid;
}

export interface WidgetDataRangesConfig {
  color: string;
  min: number | string;
  max: number | string;
  value: any;
  open: boolean;
}

export interface AdditionaPropertyCat {
  propertyCategoryId?: string;
  propertyCategory: string;
  propertySubCategoryId?: string;
  propertySubCategory: string;
  id: string;
  label: string;
}

export interface AdditionalProperty {
  id: string;
  label: string;
}

export interface WidgetDataXAxisConfig {
  propertyCategoryId?: string;
  propertyCategory: string;
  propertySubCategoryId?: string;
  propertySubCategory: string;
  id?: string;
  label: string;
  rangeMin: number | string;
  rangeMax: number | string;
  relativeRangeValue: number | string;
  ranges?: Array<WidgetDataRangesConfig>;
}

export interface WidgetDataYAxisConfig {
  propertyCategory: string;
  propertyCategoryId: string;
  propertySubCategory: string;
  propertySubCategoryId: string;
  label: string;
  id: string;
  additionalProperties: AdditionalProperty[];
  additionalPropertiesCats?: AdditionaPropertyCat[];
  hasControlValues: boolean;
  minControlValue?: number;
  maxControlValue?: number;
}

export interface WidgetDataComboConfig {
  propertyCategory: string;
  propertyCategoryId?: string;
  propertySubCategory: string;
  propertySubCategoryId?: string;
  label: string;
  id: string;
  minControlValue: number;
  maxControlValue: number;
}

/**
 * Display data model
 */
export interface DisplayDataModel {
  label: string;
  unit?: string;
  color?: string;
  id?: uuid;
  propertyCategoryId?: uuid;
  propertyCategory?: string;
  propertySubCategoryId?: uuid;
  propertySubCategory?: string;
  value: any;
  open: boolean;
}

export interface WidgetDataConfig {
  xAxis?: WidgetDataXAxisConfig;
  yAxis?: WidgetDataYAxisConfig;
  // pie chart properties
  properties?: Array<any>;
  combo?: WidgetDataComboConfig;
  displayData?: Array<DisplayDataModel>;
  isTimeSeries?: boolean;
}

export interface VisualDataConfig {
  name: string;
  label?: string;
  color?: string;
  type?: 'line' | 'color';
  value?: any;
  open?: boolean;
  id?: string;
}

export interface WidgetVisualYAxisConfig {
  ticksNumber?: number;
  customName: string;
  visualConfigs: Array<VisualDataConfig>;
  unit: string;
  id?: string;
}

export interface WidgetVisualComboConfig {
  customName: string;
  ticksNumber: number;
  visualConfig: VisualDataConfig;
  unit: string;
}

export interface WidgetVisualXAxisConfig {
  visualConfig: VisualDataConfig;
  ticksNumber: number;
  customName: string;
  unit: string;
}

export interface WidgetVisualConfig {
  chartTitle: string;
  showHorizontalLines?: boolean;
  showVerticalLines?: boolean;
  xAxis?: WidgetVisualXAxisConfig;
  yAxis?: WidgetVisualYAxisConfig;
  combo?: WidgetVisualComboConfig;
  pie?: Array<VisualDataConfig>;
  segmentCustomLabel?: string;
  lineSegmentTitle?: string;
  segmentUpperLimit?: number;
  segmentLowerLimit?: number;
  controlValueColor?: string;
  displayData?: Array<DisplayDataModel>;
  controlValueName?: any;
}

export enum WidgetType {
  BAR_HORIZONTAL = 'Bar horizontal',
  COLUMN_REGULAR = 'Column regular',
  COLUMN_CLUSTER = 'Column cluster',
  COLUMN_STACK = 'Column stack',
  COMBO = 'Combo',
  GAUGE = 'Gauge',
  DISPLAY = 'Display',
  PIE = 'Pie',
  LINE = 'Line',
  DOUGHNUT = 'Doughnut',
  RISK_MATRIX = 'Risk matrix',
  COLUMN = "Column",
  CLUSTER_COLUMN = "Cluster column"
}

export enum WidgetTypeName {
  DISPLAY = 'Display',
  GAUGE = 'Gauge',
  PIE = 'Pie',
  LINE = 'Line',
  COLUMN_REGULAR = 'ColumnRegular',
  BAR_HORIZONTAL = 'Bar',
  COLUMN_STACK = 'ColumnStacked',
  COLUMN_CLUSTER = 'ColumnCluster',
  COMBO = 'Combo',
  DOUGHNUT = 'Doughnut',
  RISK_MATRIX = 'RiskMatrix'
}

export enum SystemWidgetTypeNames {
  ASSET_BY_MANUFACTURER = 'Asset by manufacturer',
  ASSET_STATUS =  'Asset status',
  ASSET_AGE_DISTRIBUTION = 'Asset age distribution',
  TASK_STATUS = 'Task status',
  ASSET_AGE_EVOLUTION = 'Asset age evolution',
  YEARLY_EXPENDITURE = 'Yearly expenditure (inspection, preventive, predictive, and non-outage related)',
  YEARLY_HOURS_OF_MAINT = 'Yearly hours of maintenance (inspection, preventive, predictive, and non-outage related)',
  RISK_DISTRIBUTION = 'Risk distribution',
  LOAD_FOR_ASSETS = 'Load - for assets'
}

/**
 * Widget templates shown in Create New tab
 */
export interface WidgetTemplate {
  id?: string;
  type?: WidgetType;
  imageURI?: string;
  hoverImageURI?: string;
  grid: {
    cols: number;
    rows: number;
    x?: number;
    y?: number;
    isPlaceholder?: boolean;
    widgetId?: string;
    dragEnabled?: boolean,
    resizeEnabled?: boolean
  };
  // default config for template
  config?: {
    sourceConfig?: WidgetSourceConfig;
    dataConfig?: WidgetDataConfig;
    visualConfig?: WidgetVisualConfig;
  };
}

export interface WidgetCategory {
  name: string;
  presets: WidgetTemplate[];
}

export interface WidgetConfig {
  id: string;
  template: WidgetTemplate;
  name?: string;
  config: {
    sourceConfig?: WidgetSourceConfig;
    dataConfig?: WidgetDataConfig;
    visualConfig?: WidgetVisualConfig;
  },
  isRemovableOnlyWidget: boolean,
  isNewWidgetForUserFromRole: boolean,
  isPresetWidget: boolean,
  widgetSystemType?: WidgetSystemTypes,
  comments?: any
}

export interface WidgetPresetCategory {
  id: string;
  name: string;
  widgets: Array<WidgetConfig>;
}

export interface WidgetDashboardConfig {
  id: string;
  // if editing for equipment
  equipmentId?: string;
  // if editing for role
  roleName?: string;
  widgets: Array<WidgetConfig>;
}
