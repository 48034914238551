import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { SettingsService } from './settings.service';
import { DerivativeAlarmFormula } from 'src/app/containers/derivative-alarm/models/derivative-alarm-formula';
const MOCK_API_DELAY = 1000;

@Injectable({
  providedIn: 'root'
})
export class AlarmService {
  msurl: any;
  constructor(private helper: HelperService,
    mysettingsService: SettingsService) {
    this.msurl = mysettingsService.settings.managesystemApiURL;
  }

  /**
   * Get all alarm & events
   */
  getAlarms() {
    //return this.helper.getdd('/assets/data/GetAlarmsAndEvents.json').pipe(delay(MOCK_API_DELAY));
    return this.helper.get1(this.msurl + '/AlarmsAndEvents/Bar/GetItems');
  }

  acknowledgeAlarm(id) {
    return this.helper.patch('/AlarmsAndEvents/Bar/AcknowledgeAlarm/' + id, undefined);
  }

  getAlarmUserConfiguration() {
    return this.helper.get1(this.msurl + '/AlarmsAndEvents/Bar/GetAlarmUserConfiguration');
  }

  saveAlarmUserConfiguration(userConfiguration) {
    return this.helper.post('/AlarmsAndEvents/Bar/saveAlarmUserConfiguration', userConfiguration);
  }

  loadCouplingTags(scadaServerId: string) {
    return this.helper.get1(this.msurl + '/AlarmsAndEvents/Bar/loadCouplingTags?scadaServerId=' + scadaServerId);
  }

  loadCouplingNames(scadaServerId: string) {
    return this.helper.get1(this.msurl + '/AlarmsAndEvents/Bar/loadCouplingNames?scadaServerId=' + scadaServerId);
  }

  getCouplingsAeForEditor(serverId: string) {
    return this.helper.get1(this.msurl + '/AlarmsAndEvents/Formula/GetCouplingsAeForEditor/' + serverId);
  }

  saveDerivativeAlarmFormula(formula: DerivativeAlarmFormula) {
    return this.helper.post('/AlarmsAndEvents/Formula/SaveDerivativeAlarmFormula', formula)
  }

  getAeSeverityDictionary() {
    return this.helper.get1(this.msurl + '/AlarmsAndEvents/Formula/GetAeSeverityDictionary');
  }

  getFormula(derivativeId: string) {
    return this.helper.get1(this.msurl + `/AlarmsAndEvents/Formula/GetFormula/${derivativeId}`)
  }

  generateDerivativeAlarmName() {
    return this.helper.get1(this.msurl + '/AlarmsAndEvents/Formula/GenerateAlarmName');
  }

  deleteDerivativeAlarm(id) {
    return this.helper.post('/AlarmsAndEvents/Formula/DeleteDerivativeAlarmFormula', id);
  }
}
