import { Injectable } from '@angular/core';
import { Settings } from 'src/app/settings';

@Injectable({ providedIn: 'root' })
export class SettingsService {
    public settings: any;

    constructor() {
        this.settings = new Settings();
    }
}