import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { UserTypeResolverService } from '../services/user-type-resolver.service';
import { CreateUserProfileComponent } from './components/create-user-profile/create-user-profile.component';
import { LoginComponent } from './containers/login/login.component';
import { UserConfirmationComponent } from './containers/user-confirmation/user-confirmation.component';
import { ExportSubstationDataComponent } from './containers/export-substation-data/export-substation-data.component';
import { AppAuthGuard } from './utility/app.guard';
import { TenantAdminComponent } from './containers/tenant-admin/tenant-admin.component';
import { AuditLogsComponent } from './containers/audit-logs/audit-logs.component';
import { AddEditProjectComponent } from './containers/add-edit-project/add-edit-project.component';
import { ProjectDashboardComponent } from './containers/project-dashboard/project-dashboard.component';
import { MobileQrCodeComponent } from './containers/mobile-qr-code/mobile-qr-code.component';
import { OperationsCenterComponent } from './containers/operations-center/operations-center.component';
import { InstalledBaseComponent } from './containers/installed-base/installed-base.component';
import { UrlNotFoundComponent } from './containers/url-not-found/url-not-found.component';
import { HomePageComponent } from './containers/home-page/home-page.component';
import { OfflineLandingComponent } from './containers/offline-landing/offline-landing.component';
import { ConditionalGuard } from './utility/conditionaApp.guard';
import { OfflineListPageComponent } from './containers/maintenance-management/offline-list-page/offline-list-page.component';


let routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { title: 'Login' },
    canActivate: [AppAuthGuard]
  },
  {
    path: 'operationscenter',
    component: OperationsCenterComponent,
    canActivate: [ConditionalGuard]
  },
  {
    path: ':projectName/homepage',
    component: HomePageComponent,
    canActivate: [ConditionalGuard]
  },
  {
    path: ':projectName/importorexportComponentData',
    component: ExportSubstationDataComponent,
    canActivate: [AppAuthGuard],
  },
  {
    path: 'project',
    component: AddEditProjectComponent,
    canActivate: [AppAuthGuard],
  },
  {
    path: 'viewProject/:id',
    component: AddEditProjectComponent,
    canActivate: [AppAuthGuard],
  },
  {
    path: 'projectdashboard',
    component: ProjectDashboardComponent,
    canActivate: [AppAuthGuard],
  },
  {
    path: ':projectName/installedbase',
    component: InstalledBaseComponent,
    canActivate: [AppAuthGuard],
  },
  {
    path: 'project/:id',
    component: AddEditProjectComponent,
    canActivate: [AppAuthGuard],
  },
  {
    path: ':projectName/importorexportComponentData/:substationId/:isExport/:type',
    component: ExportSubstationDataComponent,
    pathMatch: 'full',
    resolve: {
      userType: UserTypeResolverService,
    },
    canActivate: [AppAuthGuard],
  },
  {
    path: 'tenantAdmin',
    component: TenantAdminComponent,
    canActivate: [AppAuthGuard]
  },
  {
    path: ':projectName/userConfirmation/:id',
    component: UserConfirmationComponent,
  },
  {
    path: ':projectName/audit-logs',
    component: AuditLogsComponent,
    canActivate: [AppAuthGuard],
  },
  {
    path: ':projectName/dashboard',
    loadChildren: () => import('./containers/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AppAuthGuard]
  },
  {
    path: ':projectName/operations',
    loadChildren: () => import('./containers/operations/operations.module').then(m => m.OperationsModule),
    canActivate: [AppAuthGuard],
    data: { title: 'Operations' },
  },

  {
    path: ':projectName/project-modelling/:id',
    loadChildren: () => import('./containers/project-modelling/project-modelling.module').then(m => m.ProjectModellingModule),
    canActivate: [AppAuthGuard],
    data: { title: 'Login' },
  },
  {
    path: ':projectName/project-modelling/:id/:substationId',
    loadChildren: () => import('./containers/project-modelling/project-modelling.module').then(m => m.ProjectModellingModule),
    canActivate: [AppAuthGuard],
    data: { title: 'Login' },
  },
  {
    path: ':projectName/sld-editor',
    loadChildren: () => import('./containers/sld-editor/sld-editor.module').then(m => m.SldEditorModule),
    canActivate: [AppAuthGuard]
  },
  {
    path: ':projectName/sld-editor/:view',
    loadChildren: () => import('./containers/sld-editor/sld-editor.module').then(m => m.SldEditorModule),
    canActivate: [AppAuthGuard]
  },
  {
    path: ':projectName/sld-editor/:view/:substationId',
    loadChildren: () => import('./containers/sld-editor/sld-editor.module').then(m => m.SldEditorModule),
    canActivate: [AppAuthGuard]
  },
  {
    path: ':projectName/policy',
    loadChildren: () => import('./containers/policy/policy.module').then(m => m.PolicyModule),
    canActivate: [AppAuthGuard]
  },
  {
    path: ':projectName/policy/:id/:equip/:menu',
    loadChildren: () => import('./containers/policy/policy.module').then(m => m.PolicyModule),
    canActivate: [AppAuthGuard]
  },
  {
    path: ':projectName/assets/:id',
    loadChildren: () => import('./containers/assets/assets.module').then(m => m.AssetsModule),
    canActivate: [AppAuthGuard]
  },
  {
    path: ':projectName/assets/:id/:view',
    loadChildren: () => import('./containers/assets/assets.module').then(m => m.AssetsModule),
    canActivate: [AppAuthGuard]
  },
  {
    path: ':projectName/substationinfo',
    loadChildren: () => import('./containers/sub-station-info/assets.module').then(m => m.SubStationInfoModule),
    canActivate: [AppAuthGuard]

  },
  {
    path: ':projectName/planning',
    loadChildren: () => import('./containers/planning/planning.module').then(m => m.PlanningModule),
    canActivate: [AppAuthGuard],
  },
  {
    path: ':projectName/execution',
    loadChildren: () => import('./containers/execution/execution.module').then(m => m.ExecutionModule),
    canActivate: [AppAuthGuard],
  },
  {
    path: ':projectName/reliability-center',
    loadChildren: () => import('./containers/reliability-center/reliability-center.module').then(m => m.ReliabilityCenterModule),
    canActivate: [AppAuthGuard],
  },
  {
    path: ':projectName/create-swms',
    loadChildren: () => import('./containers/create-swms/create-swms.module').then(m => m.CreateSwmsModule),
    canActivate: [AppAuthGuard],
    data: { title: 'SWMS' },
  },
  {
    path: ':projectName/widgets',
    loadChildren: () => import('./containers/widgets/widgets.module').then(m => m.WidgetsModule),
    data: { title: 'widgets' },
  },
  {
    path: ':projectName/swms/:param1/:param2',
    loadChildren: () => import('./containers/create-swms/create-swms.module').then(m => m.CreateSwmsModule),
    canActivate: [AppAuthGuard],
    data: { title: 'SWMS' },
  },
  {
    path: ':projectName/derivative-alarm',
    loadChildren: () => import('./containers/derivative-alarm/derivative-alarm.module').then(m => m.DerivativeAlarmModule),
    canActivate: [AppAuthGuard],
    data: { title: 'derivative-alarm' },
  },
  {
    path: ':projectName/qr-code',
    component: MobileQrCodeComponent,
    canActivate: [AppAuthGuard],
  },
  {
    path: ':projectName/plan-viewer',
    loadChildren: () => import('./containers/plan-viewer/plan-viewer.module').then(m => m.PlanViewerModule),
    canActivate: [AppAuthGuard]
  },
  {
    path: ':projectName/maintenance-management',
    loadChildren: () => import('./containers/maintenance-management/maintenance-management.module').then(m => m.MaintenanceManagementModule),
    canActivate: [ConditionalGuard]
  },
  {
    path: 'offline',
    pathMatch: 'full',
    component: OfflineLandingComponent
  },
  {
    path: 'offline/projectdashboard',
    pathMatch: 'full',
    component: OfflineListPageComponent
  },
  {
    path: 'offline/:projectName/maintenance-management',
    loadChildren: () => import('./containers/maintenance-management/maintenance-management.module').then(m => m.MaintenanceManagementModule),
  },
  {
    path: ':projectName',
    pathMatch: 'full',
    canActivate: [AppAuthGuard],
  },
  {
    path: '',
    component: LoginComponent,
    pathMatch: 'full',
    canActivate: [ConditionalGuard]
  },
  {
    path: '**',
    component: UrlNotFoundComponent,
    pathMatch: 'full',
    canActivate: [AppAuthGuard],
  },

];

const externalProjectRoutes: Routes = [];
routes.forEach(route => {
  if (route.path.startsWith(':projectName/')) {
    const extProjectRoute = Object.assign({}, route);
    extProjectRoute.path = extProjectRoute.path.replace(/^:projectName\//, '');
    externalProjectRoutes.push(extProjectRoute);
  }
});

routes = externalProjectRoutes.concat(routes);

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
  declarations: [CreateUserProfileComponent]
})
export class AppRoutingModule { }
