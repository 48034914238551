@if (loading) {
  <div class="loading-box">
    <div class="img" alt="loading"></div>
  </div>
}
<div class="modal-container" style="width: 155%;
  background-color: white; margin-left: -91px;">
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title" i18n>{{title}}</span>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('back')">
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">
      <div class="back col-sm-4">
        @if (!isNext) {
          <i class="icon icon-back" (click)="previous()" aria-hidden="true"></i>
        }
        <h5>Tenant details</h5>
      </div>
      @if (!isNext) {
        <div class="row">
          <div class="col-sm-6">
            <div class="form-wrapper">
              <label class="label-element" i18n>Secret key</label>
              <div class="select-wrapper" style="position: relative; cursor: default;">
                <span>Secret key</span>
                <input #input [class.invalid]="validation('secretKey')" type="password" formControlName="secretKey"
                  class="form-input" maxlength="50" />
              </div>
              <div [class.show]="validation('secretKey')" class="error-message" i18n>Secret key is required.</div>
              <div [class.show]="validation('secretKey', 'pattern')" class="error-message" i18n>Secret key must contain at
              least 10 characters, 1 numeric, 1 upper case letter, and 1 special character($&#64;$!%*?&).</div>
            </div>
          </div>
        </div>
      }

      @if (isNext) {
        <div class="row">
          <div class="col-sm-6">
            <div class="form-wrapper">
              <label class="label-element" i18n>Tenant name *</label>
              <div class="select-wrapper" style="position: relative; cursor: default;">
                <span>Tenant name</span>
                <input [class.disabled]="title === 'Edit tenant'" #input [class.invalid]="validation('tenantName')"
                  type="text" formControlName="tenantName" (keypress)="alphaNumericOnly($event)" class="form-input"
                  maxlength="50"/>
              </div>
              <div [class.show]="validation('tenantName')" class="error-message" i18n>Tenant name is required.</div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-wrapper">
              <label class="label-element" i18n>RelCare report client secret *</label>
              <div class="select-wrapper" style="position: relative; cursor: default;">
                <span>RelCare report client secret</span>
                <input #input (focus)="isInputFieldTouched($event,'clientIdandSecret')"
                  (blur)="isInputFieldTouched($event,'clientIdandSecret')"
                  (change)="changeInput($event,'clientIdandSecret')"
                  [placeholder]="(title === 'Edit tenant' && !isClientIdandSecret) ? '●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●' : ''"
                  [class.edit]="title === 'Edit tenant'" [class.invalid]="validation('clientIdandSecret')" type="text"
                  formControlName="clientIdandSecret" class="form-input" maxlength="50"/>
              </div>
              <div [class.show]="validation('clientIdandSecret')" class="error-message" i18n>RelCare report client secret
              is required.</div>
            </div>
          </div>
        </div>
      }
      @if (isNext) {
        <div class="row">
          <div class="col-sm-6">
            <div class="form-wrapper">
              <label class="label-element" i18n>DB connection string</label>
              <div class="select-wrapper" style="position: relative; cursor: default;">
                <span>DB Connection String</span>
                <textarea (focus)="isInputFieldTouched($event, 'dBconnectionString')"
                  (blur)="isInputFieldTouched($event, 'dBconnectionString')"
                  (change)="changeInput($event, 'dBconnectionString')"
                  [placeholder]="(title === 'Edit tenant' && !isDBconnectionString) ? '●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●' : ''"
                  [class.edit]="title === 'Edit tenant'" [class.invalid]="validation('dBconnectionString')"
                  formControlName="dBconnectionString" class="areaHeight input-ctrl form-input"
                maxlength="2000"></textarea>
              </div>
              <div [class.show]="validation('dBconnectionString')" class="error-message" i18n>DB connection string is
              required.</div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-wrapper">
              <label class="label-element" i18n>Azure storage connection string </label>
              <div class="select-wrapper" style="position: relative; cursor: default;">
                <span>Azure Storage Connection String </span>
                <textarea (focus)="isInputFieldTouched($event, 'azureStorageConnectionString')"
                  (blur)="isInputFieldTouched($event, 'azureStorageConnectionString')"
                  (change)="changeInput($event, 'azureStorageConnectionString')"
                  [placeholder]="(title === 'Edit tenant' && !isAzureStorageConnectionString ) ? '●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●●' : ''"
                  [class.edit]="title === 'Edit tenant'" [class.invalid]="validation('azureStorageConnectionString')"
                  formControlName="azureStorageConnectionString" class="areaHeight input-ctrl form-input"
                maxlength="2000"></textarea>
              </div>
              <div [class.show]="validation('azureStorageConnectionString')" class="error-message" i18n>Azure storage
              connection string is required.</div>
            </div>
          </div>
        </div>
      }
      @if (isNext && title !== 'Edit tenant') {
        <div class="row">
          <div class="col-sm-6">
            <div class="form-wrapper">
              <div class="radio-group">
                <div class="radio-buttons-container">
                  <label>Is System environment?</label>
                  <label>
                    <input type="radio" name="system" value="Yes" (change)="onRadioChange(true)"> Yes
                  </label>
                  <label>
                    <input type="radio" name="system" value="No" (change)="onRadioChange(false)"> No
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
      @if (isNext) {
        <div>
          @if (showRMC) {
            <h5 class="h2-title" i18n>Remote monitoring and control</h5>
          }
          @if (showRMC) {
            <div class="row">
              <div class="radio-group">
                @for (item of recurringJobTypes; track item) {
                  <div class="radio-buttons-container">
                    <label for="{{ item.recurringJobTypeId }}">{{ item.recurringJobTypeName }}</label>
                    <label>
                      <input type="radio" name="radioGroup{{ item.recurringJobTypeName }}"
                        id="radioGroup{{ item.recurringJobTypeId }}" value="included"
                        (change)="onRemoteMonitoringSelection(item, 'included')"> Included
                      </label>
                      <label>
                        <input type="radio" name="radioGroup{{ item.recurringJobTypeName }}"
                          id="radioGroup{{ item.recurringJobTypeId }}" value="excluded"
                          (change)="onRemoteMonitoringSelection(item, 'excluded')"> Excluded
                        </label>
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          }
        </form>
      </div>

      <div class="modal-footer">
        @if (isNext) {
          <button (click)="next()" type="submit" class="btn btn-primary" i18n>Next</button>
        }
        @if (!isNext) {
          <button (click)="onSubmit()" type="submit" class="btn btn-primary" i18n>Save</button>
        }
      </div>
    </div>