@if (!(online$ | async) && isRefreshToken) {
<div class="system-notifications">
  <span class="warn">You are Offline. Please check your internet.</span>
</div>
}

@if (loading) {
<div class="loading-box">
  <div class="img" alt="loading"></div>
</div>
}
@if (!ishide || (router.url !== '/offline' || this.router.url.indexOf('/offline') !== -1)) {
<header class="main-header" style="z-index: 100001;"
  [ngStyle]="{'display':display}">
  <div class="left-content">
    <div class="logo-container">
      <a #hamburger class="hamburger" [class.active]="openSide"
        (click)="openSlideMenu()" href="javascript:;">@if (roleType !== 'Admin'
        && roleType !== 'Tenant Admin') {
        <i
          class="icon icon-hamburger-icon" aria-hidden="true"></i>
        }</a>
    </div>
    <a class="hitachi-logo">
      <i class="icon icon-hitachi-logo" aria-hidden="true"></i>
    </a>
    @if(this.router.url.indexOf('/offline') === -1){
    <div class="left-item">
      <a class="logo" style="opacity:unset;display: inline-grid;">
        <span i18n>{{metadataInfo?.title}}</span>
      </a>
    </div>
    @if (this.isSystemEnv) {
    <div class="left-item">
      <span i18n>{{metadataInfo?.systemDeploymentName}}</span>
    </div>
    }
    @if (this.isSystemEnv) {
    <div class="left-item version-item">
      <span i18n>Version {{buildVersionNumber}}</span>
    </div>
    }
    @if (!hideClientProjectDetail && !this.isSystemEnv) {
    <div class="left-item">
      <span i18n>{{custName}}</span>
    </div>
    }
    @if (!hideClientProjectDetail && !this.isSystemEnv) {
    <div class="left-item">
      <span i18n>{{projName}}</span>
    </div>
    }
    @if (isPM() && project && !this.isSystemEnv) {
    <div i18n>{{project.company}}</div>
    }
    @if (isPM() && project && !this.isSystemEnv) {
    <div i18n>{{project.location}}</div>
    }
    @if (!hideClientProjectDetail && !this.isSystemEnv) {
    <div class="left-item version-item">
      @if (buildVersionNumberError) {
      <span i18n>{{buildVersionNumber}}</span>
      }
      @if (!buildVersionNumberError) {
      <span i18n>Version {{buildVersionNumber}}</span>
      }
    </div>
    }
  }
  </div>
  <div class="header-opts">
     @if(isOffline && (this.router.url.indexOf('/offline') !== -1)){
                <div class="right-item">
                    <img  src="assets/i/internet-un.svg"
                    alt="internet-icon" />
                  <span style="vertical-align: middle;">Internet available</span>
                </div>
                }
    @if(this.router.url.indexOf('/offline') === -1){
      @if(showAI === "true") {
      <div (click)="openNewTab()" class="containerAI" ngbTooltip="Opens in new tab" placement="bottom">
        <i class="icon icon-AI"></i>
        <span class="titleAI">{{constService.AIname}}</span>
        <i class="icon icon-AI-logo"></i>
      </div>
      <div class="seperator"></div>
      }
    @if (showAlarms()) {
    <div class="alarms" ngbTooltip="Alarms & Events" placement="bottom" (click)="clickAlarmIcon()">
      <span class="status" [class.unread]="!isAllAcknowledged()"
        [ngClass]=getClass()></span>
      <i header-icon-severity aria-hidden="true"
        [alarms]="this.appSvc.allAlarms"></i>
    </div>
    }
    @if (showAlarms()) {
    <div class="alarm-container" [class.active]="isAlarmOpen"
      [class.show-more]="alarmPage > 0">
      <div class="header">
        <div class="title">Alarms & Events</div>
        <div class="actions">
          <span (click)="clickAlarmSettings()">Settings</span>
        </div>
      </div>
      <div class="list-container" [class.show-more]="alarmPage > 0">
        @for (item of appSvc.alarms; track item) {
        <app-alarm-item [alarm]="item" (clickedAlarm)="clickedAlarm($event)"
          (acknowledgedAlarm)="acknowledgedAlarm($event)">
        </app-alarm-item>
        }
      </div>
      <div class="more"
        [class.active]="this.appSvc.allAlarms?.length > 6 && this.appSvc.alarms?.length < this.appSvc.allAlarms?.length"
        (click)="clickLoadMoreAlarms($event)">Load more</div>
    </div>
    }
<!-- Header icon added -->
@if(showAssessReliabilityIcon && assessReliabilityData?.length > 0) {
<div class="notifications" (click)="clickupdate()">
  <i class="icon icon-update new" aria-hidden="true"></i>
</div>

<!--Assess Reliability  -->
<div class="notification-container assess-container" [class.active]="isOpenBackgroundProcess">
  <div class="header">
    <div class="title">Background processes<span class="circle" #circle></span> <span class="progressCount">
        {{InprogressCount}} Progress</span>
    </div>
  </div>
  <div class="list-container" [class.show-more]="page > 0">
    <app-notification-assessReliability (cancelMessage)="showCancelMessage($event)"
      [notification]="assessReliabilityData"></app-notification-assessReliability>
  </div>
</div>
}
<!-- End of assess reliability -->
    @if (roleType !== 'Admin' && roleType !== 'Tenant Admin') {
    <div class="notifications" ngbTooltip="Notifications" placement="bottom" (click)="clickBell()">
      <span class="status" [class.unread]="!isMarkAllRead()"></span>
      <i class="icon icon-bell new" aria-hidden="true"></i>
    </div>
    }
    <div class="notification-container" [class.active]="isOpen"
      [class.show-more]="page > 0">
      <div class="header">
        <div class="title">Notifications</div>
        <div class="actions">
          <span (click)="clickMarkAllRead()">Mark all as read</span>
          <span (click)="clickSettings()">Settings</span>
        </div>
      </div>
      <div class="list-container" [class.show-more]="page > 0">
        @for (item of appSvc.notifications; track item) {
        <app-notification-item [notification]="item"
          (clickNotification)="clickNotification($event)"
          [isMarkAllRead]="isMarkAllRead()"></app-notification-item>
        }
      </div>
      <div class="more"
        [class.active]="this.appSvc.allNotifications?.length > 7 && this.appSvc.notifications?.length < this.appSvc.allNotifications?.length"
        (click)="clickLoadMore($event)">Load more</div>
    </div>
  }
    @if(openRightPanel) {
      <app-log-out-details [openRightPanel]="openRightPanel" (rightPanelClosed)="onValueChanged($event)"></app-log-out-details>
    }
    <div class="right_part">
        <span class="logger-thumb" (click)="openUserPopup($event)">
          @if (userImage) {
          <img [src]="userImage" alt="Avatar" class="icon icon-avatar" />
          } @else {
            <div class="userInitials">{{ userInitials }}</div>
          }
        </span>
    </div>
  </div>
</header>
}
<aside #navbar [class.hide]="!openSide"
 [class.compact-aside]="compactMenu">
  <nav class="top">
    <ul [class.compact-menu-ul]="compactMenu">
      @for (item of filteredMenuItems; track item) {
      <li class="list_item"
       [class.active]="item.active || this.router.isActive(item.url, false)"
       [class.compact-menu-li]="compactMenu"
       [class.compact-menu-li-active]="compactMenu && (item.active || this.router.isActive(item.url, false))">
        <a href="javascript:;" (click)="goTo(item)" [class.compact-menu-a]="compactMenu"
          [class.compact-menu-active]="compactMenu && (item.active || this.router.isActive(item.url, false))">
          <i class="icon {{item.icon}}" aria-hidden="true" id="myIcon"
          (mouseover)="compactMenu ? mouseOver(item) : ''"
          (mouseout)="compactMenu ? mouseOut(item) : ''"
          [ngbTooltip] = "compactMenu ? item.name : ''" container="body" tooltipClass="compact-menu-tooltip"></i>
          @if(!this.compactMenu) {
            {{item.name}}
          }
        </a>
      </li>
      }
    </ul>
    @if(!compactMenu && showExternalLink) {
      <div class="mid-header">External link</div>
     } @if(compactMenu && showExternalLink) {
       <div class="mid-header-compact-menu">
         <i class="icon icon-external-link"></i>
         <div class="border_bottom"></div>
       </div>
     }
     @if(showExternalLink) {
     <ul [class.compact-menu-ul]="compactMenu">
      @for(item of externalMenuItems; track item) {
      <li class="list_item list_item_mid"
        [class.active]="item.active || this.router.isActive(item.url, false)"
        [class.compact-menu-li]="compactMenu"
        [class.compact-menu-li-active]="compactMenu && (item.active || this.router.isActive(item.url, false))">
        <a href="javascript:;" (click)="goTo(item)" [class.compact-menu-a]="compactMenu"
          [class.compact-menu-active]="compactMenu && (item.active || this.router.isActive(item.url, false))">
          <i class="icon {{item.icon}}" aria-hidden="true" id="myIcon"
            (mouseover)="compactMenu ? mouseOver(item) : ''"
            (mouseout)="compactMenu ? mouseOut(item) : ''"
            ngbTooltip = "Projects (Opens in a new tab)" container="body" tooltipClass="compact-menu-tooltip"></i>
            @if(!this.compactMenu) {
              {{item.name}}
            }
        </a>
      </li>
      }
     </ul>
     }
  </nav>
  <nav class="bottom">
    <ul>
      <li>
        <a href="javascript:;" (click)="compactMenuFunc(); sendCompactMenuValue()" [class.compact-menu-a]="compactMenu">
          <i aria-hidden="true" class="icon icon-checkbox" [class.compactMenu]="this.compactMenu"
            [ngbTooltip] = "compactMenu ? 'Click to expand the menu' : ''" container="body" tooltipClass="compact-menu-tooltip"></i>
           @if(!this.compactMenu) {
            <span class="pd_left"> Compact menu
            </span>
          }
        </a>
      </li>
    </ul>
  </nav>
</aside>

@if(message) {
<div id="toast-container" class="toast-top-center toast-container">
  <div class="ngx-toastr">
    <button type="button" (click)="onAssessStatusClick()" class="toast-close-button">
      <span>×</span></button>
    <div class="toast-message">
      {{message}}<b>{{substationName}}</b></div>
  </div>
</div>
}
