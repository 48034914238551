import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToggleIconsComponent } from './toggle-icons.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [ToggleIconsComponent],
  imports: [
    CommonModule,
    NgbModule
  ],
  exports:[
    ToggleIconsComponent,
    NgbModule
  ]
})
export class ToggleIconsModule { }
