@if (loading) {
  <div class="loading-box">
    <div class="img" alt="loading"></div>
  </div>
}
<div class="modal-container">
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title" i18n>Assign to execution team</span>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('back')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="title">
      <span i18n>All teams</span>
      <input type="search" class="input-ctrl search-ctrl" placeholder="Search" [(ngModel)]="filterStr"
        (keyup)='onKey($event)' />
      <div class="filter-container" [ngClass]="{'filter-hide':!filterShow}">
        <div class="header">
          <i class="icon icon-filter" aria-hidden="true" (click)="filterShow=!filterShow" [ngClass]="{'checked':!filterShow}"></i>
          <span i18n>Column filter</span>
        </div>
        @for (item of filterColumns; track item; let i = $index) {
          <div class="filter-option"
            (click)="item.selected=!item.selected;">
            <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':item.selected}"></i>
            <span>{{item.name}}</span>
          </div>
        }
      </div>
    </div>
    @if (loading) {
      <div class="loading-box">
        <div class="img" alt="loading"></div>
      </div>
    }
    <div class="execution-teams-table">
      <ngx-datatable #executionTeamTable [rows]="teams" [columnMode]="'force'" rowHeight="40" footerHeight="56"
        [selected]="selected" [selectionType]="'checkbox'" (select)="onSelect($event)">
        <ngx-datatable-column prop="teamId" [width]="41" [sortable]="false" [canAutoResize]="true" [draggable]="false"
          [resizeable]="false">
          <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
            let-selectFn="selectFn">
            <label [for]="'team-header' + (asModal?'-modal':'')" class="checkbox-wrap">
              <input [id]="'team-header' + (asModal?'-modal':'')" type="checkbox" [checked]="allRowsSelected"
                (change)="selectFn(!allRowsSelected)" />
              <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':allRowsSelected}"></i>
            </label>
          </ng-template>
          <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-isSelected="isSelected"
            let-i="index" let-onCheckboxChangeFn="onCheckboxChangeFn">
            <label [for]="'team-'+row.teamId + (asModal?'-modal':'')" class="checkbox-wrap">
              <input [id]="'team-'+row.teamId + (asModal?'-modal':'')" type="checkbox" [checked]="isSelected"
                (change)="onCheckboxChangeFn($event)">
              <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':isSelected}"></i>
            </label>
          </ng-template>
        </ngx-datatable-column>
        @if (filterColumns[0].selected) {
          <ngx-datatable-column name="Name" prop='teamName' [draggable]="false"></ngx-datatable-column>
        }

        @if (filterColumns[1].selected) {
          <ngx-datatable-column name="Type" prop='teamType' [draggable]="false"></ngx-datatable-column>
        }

      </ngx-datatable>
    </div>
  </div>
  <div class="modal-footer">
    <a class="btn btn-save" [class.disabled]="selected.length<=0 || selected.length>1" (click)="assign()" i18n>Assign</a>
  </div>
</div>
