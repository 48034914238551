<div class="content-wrap all-users">
  <div class="title">
    <span i18n>{{asModal?'Members':'Manage members'}}</span>
    <input type="search" #search class="input-ctrl search-ctrl" placeholder="Search" [(ngModel)]="filterStr"
      (input)='onKey(search.value)' />
    </div>
    <!--end .title-->
    <div class="scroll-panel">
      <perfect-scrollbar fxFlex="auto" class="scrollbar">
        <div class="body-panel" [class.modal-body-panel]="asModal">
          <div style="padding: 20px 22px 0 22px;">
            @if (loading) {
              <div class="loading-box">
                <div class="img" alt="loading"></div>
              </div>
            }
            <div class="all-user-table">
              <div class="filter-container" [ngClass]="{'filter-hide':!filterShow}">
                <div class="header">
                  <i class="icon icon-filter" aria-hidden="true" (click)="filterShow=!filterShow" [ngClass]="{'checked':!filterShow}"></i>
                  <span i18n>Column filter</span>
                </div>
                @for (item of filterColumns; track item; let i = $index) {
                  <div class="filter-option"
                    (click)="item.selected=!item.selected;updateFilter()" id="{{ item.prop }}">
                  <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':item.selected}"></i>
                  <span>{{item.name}}</span>
                </div>
              }
            </div>
            @if (tableVisible) {
              <ngx-datatable #usertable [rows]="allUsers" [columnMode]="'force'" rowHeight="40"
                [ngClass]="'scrollbar-tab'"
                footerHeight="56" [selected]="selected" [selectionType]="'checkbox'" (select)="onSelect($event)"
                [externalPaging]="true" [count]="page.totalElements" [offset]="page.pageNumber" [limit]="page.size"
                (page)='setPage($event)' [class.hide_footer]="search===true">
                <ngx-datatable-column prop="id" [width]="41" [sortable]="false" [canAutoResize]="false" [draggable]="false"
                  [resizeable]="false">
                  <ng-template ngx-datatable-header-template let-value="value" let-allRowsSelected="allRowsSelected"
                    let-selectFn="selectFn">
                    <label [class.disable_add]="userSystemroleRoles.ManageusersEdit === false || !hasPermissionToEdit()"
                      [for]="'all-user-header' + (asModal?'-modal':'')" class="checkbox-wrap">
                      <input [id]="'all-user-header' + (asModal?'-modal':'')" type="checkbox" [checked]="allRowsSelected"
                        (change)="selectFn(!allRowsSelected)" />
                      <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':allRowsSelected}"></i>
                    </label>
                  </ng-template>
                  <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-isSelected="isSelected"
                    let-i="index" let-onCheckboxChangeFn="onCheckboxChangeFn">
                    @if (!row.key) {
                      <label [class.disable_add]="userSystemroleRoles.ManageusersEdit === false || row.isPS || !hasPermissionToEdit()"
                        [for]="'all-user-'+row.userId + (asModal?'-modal':'')" class="checkbox-wrap">
                        @if (row.userId !== userId) {
                          <input [id]="'all-user-'+row.userId + (asModal?'-modal':'')"
                            type="checkbox" [checked]="isSelected" (change)="onCheckboxChangeFn($event)">
                        }
                        @if (row.userId !== userId) {
                          <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':isSelected && !(userSystemroleRoles.ManageusersEdit === false || row.isPS || !hasPermissionToEdit())}"></i>
                        }
                      </label>
                    }
                  </ng-template>
                </ngx-datatable-column>
                @if (filterColumns[0].selected) {
                  <ngx-datatable-column name="Member" prop='firstName' [draggable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
                      <div class="tcell-user">
                        @if (row.userProfilePhoto) {
                          <img [src]="row.userProfilePhoto"
                            class="icon icon-avatar" alt="Avatar" />
                        } @else {
                          <i class="icon icon-avatar" aria-hidden="true"></i>
                        }
                        <span class="uname" [class.no-margin]="userType==='PM'">{{value}}</span>
                      </div>
                    </ng-template>
                  </ngx-datatable-column>
                }
                @if (filterColumns[1].selected) {
                  <ngx-datatable-column name="Role" prop='role' [draggable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row" let-i="index">
                      @for (team of row['userRolesDtoList']; track team; let ti = $index) {
                        <span class="team-span">
                          <i class="icon icon-role" aria-hidden="true" title="{{ team.roleName }}"></i>
                        </span>
                      }
                    </ng-template>
                  </ngx-datatable-column>
                }
                @if (filterColumns[2].selected) {
                  <ngx-datatable-column name="Employer" prop='employer' [draggable]="false">
                  </ngx-datatable-column>
                }
                @if (filterColumns[3].selected && isExecutionView) {
                  <ngx-datatable-column name="Execution team" prop='executionTeams' [draggable]="false">
                    <ng-template ngx-datatable-cell-template let-row="row">
                      @for (team of row['teamUsersDtoList']; track team; let ti = $index) {
                        <span class="team-span">{{ team.teamName }}
                        </span>
                      }
                    </ng-template>
                  </ngx-datatable-column>
                }
                @if (filterColumns[4].selected) {
                  <ngx-datatable-column name="Email" prop='emailId' [draggable]="false">
                  </ngx-datatable-column>
                }
                @if (filterColumns[5].selected) {
                  <ngx-datatable-column name="Phone" prop='phone'>
                    <ng-template ngx-datatable-cell-template let-row="row">
                      <span>+{{row['isdCode']+' - '+row['phone']}}</span>
                    </ng-template>
                  </ngx-datatable-column>
                }
              </ngx-datatable>
            }

            <!--end ngx-datatable-->

            @if (userSystemroleRoles.ManageusersEdit && showButton) {
              <a [class.disable_add]="selected.length > 0 || !hasPermissionToEdit()"
                class="add-new" (click)="addOrEditMember(null)">
                <i class="icon icon-add-new" aria-hidden="true" style="border-right: 1px solid #ababab;"></i>
                <span style="color:black" i18n>Add new member</span>
              </a>
            }
          </div>
        </div>
      </div>
      <!-- end .view-all-user-table-section -->
      @if (userSystemroleRoles.ManageusersEdit && showButton) {
        <div class="footer-panel">
          <div class="footer-buttons">
            <a class="btn btn-secondary" [class.disabled]="!selected.length || !hasPermissionToEdit()" (click)="delete()" i18n>Delete</a>
            <a class="btn btn-secondary" [class.disabled]="selected.length > 1 || selected.length == 0 || !hasPermissionToEdit()"
            (click)="selected.length > 0 && addOrEditMember(selected[0])" i18n>Edit</a>
          </div>
        </div>
      }
    </perfect-scrollbar>
  </div>
  <!-- end .footer -->
</div>