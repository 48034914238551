import { Injectable } from '@angular/core';
import { ApplicationService } from './application.service';

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor(private appService: ApplicationService) { }


    loadCurrencyValue() {
        this.appService.GetCurrencyDetails().subscribe({
            next: (data) => {
                if (data && data.returnObj) {
                    return data.returnObj;
                }
            }, error: () => { }
        });
    }

    projectDelimiter() {
        this.appService.getProjectCurrencyDelimiter().subscribe({
            next: (data) => {
                if (data && data.decimalDelimiter) {
                    return data.decimalDelimiter;
                }
            }, error: () => { }
        });
    }

    only_numbers(event: any) {
        let k;
        k = event.charCode;  //k = event.keyCode;  (Both can be used)
        return ((k > 47 && k < 58) || k === 46 || k === 45);
    }
}
