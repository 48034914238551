import { WidgetType, SystemWidgetTypeNames } from './../models/widget';

export enum WidgetSystemTypes {
  assetByManufacturer = 1,
  assetAgeDistribution = 2,
  assetStatus = 3,
  taskStatus = 4,
  yearlyExpenditure = 5,
  yearlyHoursOfMaintenance = 6,
  riskDistribution = 7,
  assetAgeEvolution = 8,
  loadForAssets = 9
}

const startupGrid = {
  cols: 1,
  rows: 1
}

export const assetLineChartSystemCategories = {
  hoverImageURI: '/assets/widget-images/line-hover.png',
  imageURI: '/assets/widget-images/line.png',
  type: WidgetType.LINE,
  name: 'Line chart',
  grid: startupGrid
};

export const assetSystemCategories = [
  assetLineChartSystemCategories
];

export const projectSubstationPieChartSystemCategory = {
  hoverImageURI: '/assets/widget-images/doughnut-hover.png',
  imageURI: '/assets/widget-images/doughnut.png',
  type: WidgetType.PIE,
  name: 'Pie chart',
  grid: startupGrid
};

export const projectSubstationColumnRegularChartSystemCategory = {
  hoverImageURI: '/assets/widget-images/column-regular-hover.png',
  imageURI: '/assets/widget-images/column-regular.png',
  type: WidgetType.COLUMN_REGULAR,
  name: 'Column regular chart',
  grid: startupGrid
};

export const projectSubstationColumnStackChartSystemCategory = {
  hoverImageURI: '/assets/widget-images/column-stack-hover.png',
  imageURI: '/assets/widget-images/column-stack.png',
  type: WidgetType.COLUMN_STACK,
  name: 'Column stack chart',
  grid: startupGrid
};

export const projectSubstationBarHorizontalChartSystemCategory = {
  hoverImageURI: '/assets/widget-images/bar-horizontal-hover.png',
  imageURI: '/assets/widget-images/bar-horizontal.png',
  type: WidgetType.BAR_HORIZONTAL,
  name: 'Bar horizontal chart',
  grid: startupGrid
};

export const projectSubstationSystemCategories = [
  projectSubstationPieChartSystemCategory,
  projectSubstationColumnRegularChartSystemCategory,
  projectSubstationColumnStackChartSystemCategory,
  projectSubstationBarHorizontalChartSystemCategory
];

export const regionSubsystemWidgets = [
  { name: SystemWidgetTypeNames.ASSET_BY_MANUFACTURER, conf: projectSubstationPieChartSystemCategory, widgetSystemType: WidgetSystemTypes.assetByManufacturer },
  { name: SystemWidgetTypeNames.ASSET_AGE_DISTRIBUTION, conf: projectSubstationColumnRegularChartSystemCategory, widgetSystemType: WidgetSystemTypes.assetAgeDistribution },
  { name: SystemWidgetTypeNames.ASSET_STATUS, conf: projectSubstationPieChartSystemCategory, widgetSystemType: WidgetSystemTypes.assetStatus },
  { name: SystemWidgetTypeNames.TASK_STATUS, conf: projectSubstationColumnStackChartSystemCategory, widgetSystemType: WidgetSystemTypes.taskStatus },
  { name: SystemWidgetTypeNames.ASSET_AGE_EVOLUTION, conf: projectSubstationColumnRegularChartSystemCategory, widgetSystemType: WidgetSystemTypes.assetAgeEvolution },
  { name: SystemWidgetTypeNames.YEARLY_EXPENDITURE, conf: projectSubstationColumnStackChartSystemCategory, widgetSystemType: WidgetSystemTypes.yearlyExpenditure },
  { name: SystemWidgetTypeNames.YEARLY_HOURS_OF_MAINT, conf: projectSubstationColumnStackChartSystemCategory, widgetSystemType: WidgetSystemTypes.yearlyHoursOfMaintenance },
  { name: SystemWidgetTypeNames.RISK_DISTRIBUTION, conf: projectSubstationBarHorizontalChartSystemCategory, widgetSystemType: WidgetSystemTypes.riskDistribution }
];

export const assetWidgets = [
  { name: SystemWidgetTypeNames.LOAD_FOR_ASSETS, conf: assetLineChartSystemCategories, widgetSystemType: WidgetSystemTypes.loadForAssets }
];
