<div class="notification-item-container" #notificationItem (click)="handleClick(notification.notificationIds)">
  <div class="first-line">
    <div class="title">
      <span class="type">{{notification.type}}</span>
      <span class="circle" [class.unread]="!notification.isRead" #circle></span>
    </div>
    <div class="datetime">{{notification.createdAt}}</div>
  </div>
  <div class="second-line">{{notification.detail}}</div>
  <div class="hover-tooltip">Mark as {{notification.isRead ? 'UnRead' : 'Read'}}</div>
</div>

