import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class UserTypeResolverService {

  constructor(private authService: AuthService) { }
  resolve(): string {
    return this.authService.getUser()['role'];
  }
}
