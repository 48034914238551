import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NavigationStart, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

const INNER_BOX_VERT_PADDING = 16;
const INNER_BOX_HOR_PADDING = 0;
const OUTER_BOX_VERT_PADDING = 32;
const OUTER_BOX_HOR_PADDING = 16;

@Component({
  selector: 'app-rise-to-relcare-unmapped-assets-modal',
  templateUrl: './rise-to-relcare-unmapped-assets-modal.component.html',
  styleUrls: ['./rise-to-relcare-unmapped-assets-modal.component.scss']
})
export class RiseToRelcareUnmappedAssetsModalComponent implements OnInit {

  @Input() unmappedAssets: {
    equipmentDetails: any,
    goJSNodeData: any,
    overlayLeft?: number,
    overlayTop?: number,
    overlayWidth?: number,
    overlayHeight?: number
  }[];
  @Input() sldImage: string;
  @Input() serializedSld: any;

  @ViewChild('sldImageRef') sldImageRef: ElementRef;
  @ViewChild('bitmapCheckerCanvas') bitmapCheckerCanvas: ElementRef;
  @ViewChild('imageContainerRef') imageContainerRef: ElementRef;

  public selectedAsset: any;

  private _scale: number = 1;
  private _imageInspectionMode: 'GREY_BOX_INSPECTION' | 'WHITE_PADDING_INSPECTION';

  constructor(public activeModal: NgbActiveModal, router: Router) {
    router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .pipe(take(1))
      .subscribe(() => this.activeModal.dismiss('back'));
  }

  ngOnInit() {
    // we set the image inspection mode based on whether labels are drawn or not
    // white padding inspection is more accurate and should be prefered, but it cannot be used when labels are shown in diagram image
    // labels are basically hard to calculate (cause RISE has draggable labels and the offset alignment parameter behaves strangely)
    this._imageInspectionMode = this.serializedSld.areLabelOn ? 'GREY_BOX_INSPECTION' : 'WHITE_PADDING_INSPECTION';
  }

  /**
   * inspect SLD image bitmap
   * @param - image html element (should already be loaded)
   * this method exists to inspect the SLD image at a pixel level and figure out the white-space padding
   * recent changes in RISE image capture introduces a seemingly aribitrary amount of padding to SLD images
   * if it's not properly accounted for, then alignemnt of overlays could be severely messed up
   */
  private _inspectSLDImageBitMap(image: HTMLImageElement) {

    // we use an invisible canvas to load the image in.
    // canvas gives us a method to inspect the image on a pixel level
    const canvas = this.bitmapCheckerCanvas.nativeElement;
    const ctx = canvas.getContext('2d');
    canvas.height = image.height;
    canvas.width = image.width;
    ctx.drawImage(image, 0, 0);

    // the bounds of the actual drawn diagram (this object basically tells us about the padding in the image)
    const drawBounds = {
      left: image.width,
      top: image.height,
      right: 0,
      bottom: 0
    };

    // from the image data, we restructure the data to inspect each pixel in the diagram
    // and figure out what the white space padding is
    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    const greyScale = [];
    for (let i=0; i < canvas.height; i++) {
      greyScale[i] = [];
      for (let j=0; j < canvas.width; j++) {
        const pixel = {
          r: imageData.data[i * canvas.width * 4 + (j * 4)],
          g: imageData.data[i * canvas.width * 4 + (j * 4) + 1],
          b: imageData.data[i * canvas.width * 4 + (j * 4) + 2],
          a: imageData.data[i * canvas.width * 4 + (j * 4) + 3]
        }
        // from rgb values, just figure out the grey scale to get a single value
        greyScale[i][j] = Math.min(255, Math.round(((0.299 * pixel.r) + (0.587 * pixel.g) + (0.114 * pixel.b))));
        if (pixel.a === 0) { greyScale[i][j] = 255; }
      }
    }

    const isGreyBoxPixel = (i: number, j: number): boolean => {
      if (i > canvas.height || j > canvas.width) return false;
      if (i < 0 || j < 0) return false;
      return greyScale[i][j] < 222 && greyScale[i][j] > 215;
    };

    const areNeighborsAlsoGreyBoxPixel = (i: number, j: number): boolean => {
      if (!isGreyBoxPixel(i, j)) return false;
      if (!(isGreyBoxPixel(i - 1, j) || isGreyBoxPixel(i + 1, j))) return false;
      if (!(isGreyBoxPixel(i - 2, j) || isGreyBoxPixel(i + 2, j))) return false;
      if (!(isGreyBoxPixel(i, j - 1) || isGreyBoxPixel(i, j + 1))) return false;
      if (!(isGreyBoxPixel(i, j - 2) || isGreyBoxPixel(i, j + 2))) return false;
      return true;
    }

    for (let i=0; i < canvas.height; i++) {
      for (let j=0; j < canvas.width; j++) {
        if (this._imageInspectionMode === 'WHITE_PADDING_INSPECTION') {
          // if the single value is 255 (fully white), then we realize that this is a white space cell
          if (greyScale[i][j] === 255) { continue; }
        } else {
          // we check if this pixel and surrounding pixels are exactly the grey that is the iconic color of equipment boxes
          if (!areNeighborsAlsoGreyBoxPixel(i, j)) { continue; }
        }
        // we calculate the padding by figuring out the first non-white pixel in each direction
        if (j < drawBounds.left) { drawBounds.left = j; }
        if (j > drawBounds.right) { drawBounds.right = j; }
        if (i < drawBounds.top) { drawBounds.top = i; }
        if (i > drawBounds.bottom) { drawBounds.bottom = i; }
      }
    }

    // in case of grey box inspection, we do need add a little padding (to account for node borders)
    if (this._imageInspectionMode === 'GREY_BOX_INSPECTION') {
      drawBounds.left -= 2;
      drawBounds.right += 2;
      drawBounds.top -= 2;
      drawBounds.bottom += 2;
    }

    console.log('Performed', this._imageInspectionMode);

    return drawBounds;
  }

  /**
   * on image lodaed
   * this method is the crux of this component.
   * Once the image is loaded, we can then calculate from GoJS properties the expected diagram bounds
   * we then try to align those bounds onto the image bounds.
   * To calculate expected diagram bounds, we essentially have to look at all nodes and links (and possibly labels)
   */
  public onImageLoaded() {

    // first step is the figure out the white space padding in the image
    const bitmapDrawnBounds = this._inspectSLDImageBitMap(this.sldImageRef.nativeElement);

    // helper fn to calculate the bounds of the "inner grey box" of equipment nodes
    // this will be used to draw the red overlays over equipments that are invalid
    // fn expects an axis 'x' or 'y' to return a single dimension in that axis
    // it automatically returns the calculated height or width based on the angle of the equipment
    const getGreyBoxDimension = (node: any, axis: 'x' | 'y') => {
      let nWidth = node.width + 2 + INNER_BOX_HOR_PADDING;
      let nHeight = node.height + 2 + INNER_BOX_VERT_PADDING;

      if (node.name === 'Protection Relay') {
        nHeight -= INNER_BOX_VERT_PADDING;
      }

      if (!node.angle || node.angle % 180 === 0) { return axis === 'x' ? nWidth : nHeight; }
      else { return axis === 'x' ? nHeight : nWidth; }
    }

    // helper fn to calculate the bounds of equipment nodes with a bunch of optional parameters
    // fn expects an axis 'x' or 'y' to return a single dimension in that axis
    // it automatically returns the calculated height or width based on the angle of the equipment
    // additionally it expects a direction in the given axis 'positive' or 'negative' or 'both'
    const getNodeDimension = (node: any, axis: 'x' | 'y', direction: 'positive' | 'negative' | 'both') => {
      let nWidth = node.width;
      let nHeight = node.height;

      const extraHeights = { negative: 0, positive: 0 };
      const extraWidths = { negative: 0, positive: 0 };

      const calculateExtraDimensions = this._imageInspectionMode === 'WHITE_PADDING_INSPECTION' && node.isSelected;

      if (node.class === 'text') {
        // the RISE text box has additional buttons for font-size
        nHeight += 24;

      } else if (!node.category) {

        // we only add the grey box (visible part) if input param is false and the node is not marked as selected
        nWidth += 1 + INNER_BOX_HOR_PADDING + (calculateExtraDimensions ? 1 + OUTER_BOX_HOR_PADDING : 0);
        nHeight += 1 + INNER_BOX_VERT_PADDING + (calculateExtraDimensions ? 1 + OUTER_BOX_VERT_PADDING: 0);

        if (node.name === 'Protection Relay') {
          // protection relays have a weird template in RISE
          nHeight -= INNER_BOX_VERT_PADDING;
        }

        // the extra dimensions due to ports (invisible or visible)
        if (calculateExtraDimensions) {
          if (!node.isConnector && node.name !== 'Protection Relay' && node.ports) {
            const topPort = node.ports.find(p => p.spot.split(' ')[1] === '0');
            const bottomPort = node.ports.find(p => p.spot.split(' ')[1] === '1');

            extraHeights.negative = (!node.angle || node.angle === 270) ? (topPort ? 8 : 0) : (bottomPort ? 8 : 0);
            extraHeights.positive = (node.angle === 90 || node.angle === 180) ? (topPort ? 8 : 0) : (bottomPort ? 8 : 0);
          }
        }
      }

      switch (direction) {
        case 'both':
          nHeight += extraHeights.negative + extraHeights.positive;
          nWidth += extraWidths.negative + extraWidths.positive;
          break;
        case 'positive':
          nHeight = (nHeight / 2) + extraHeights.positive;
          nWidth = nWidth / 2 + extraWidths.positive;
          break;
        case 'negative':
          nHeight = (nHeight / 2) + extraHeights.negative;
          nWidth = nWidth / 2 + extraWidths.negative;
      }

      if (!node.angle || node.angle % 180 === 0) { return axis === 'x' ? nWidth : nHeight; }
      else { return axis === 'x' ? nHeight : nWidth; }
    }

    // helper fn to get all the link points in the diagram
    const getLinkPointLocations = (link: any, axis: 'x' | 'y') => {
      let pointsArray;
      if (!link.n) {
        for (let prop in link.points) {
          if (!Object.prototype.hasOwnProperty.call(link.points, prop)) { continue; }
          if (!Array.isArray(link.points[prop])) { continue; }
          pointsArray = link.points[prop];
        }
      }
      if (!pointsArray) { return []; }
      return pointsArray.map(point => point[axis]);
    }

    // RISE ensures that bays are not drawn while capturing the SLD image.
    // this consistency helps us in aligning the expected bounds of diagram as we know never to include bay position
    let nodesDisplayedOnImage = this.serializedSld.nodes.filter(node => node.category !== 'bay');
    if (this._imageInspectionMode === 'GREY_BOX_INSPECTION') {
      nodesDisplayedOnImage = nodesDisplayedOnImage.filter(node => node.class !== 'text');
    }

    let xLocations = nodesDisplayedOnImage.map(node => Number(node.loc.split(' ')[0]) - getNodeDimension(node, 'x', 'negative'));
    let yLocations = nodesDisplayedOnImage.map(node => Number(node.loc.split(' ')[1]) - getNodeDimension(node, 'y', 'negative'));
    xLocations = xLocations.concat(nodesDisplayedOnImage.map(node => Number(node.loc.split(' ')[0]) + getNodeDimension(node, 'x', 'positive')));
    yLocations = yLocations.concat(nodesDisplayedOnImage.map(node => Number(node.loc.split(' ')[1]) + getNodeDimension(node, 'y', 'positive')));
    if (this._imageInspectionMode === 'WHITE_PADDING_INSPECTION') {
      xLocations = xLocations.concat(this.serializedSld.links.reduce((acc, link) => acc.concat(getLinkPointLocations(link, 'x')), []));
      yLocations = yLocations.concat(this.serializedSld.links.reduce((acc, link) => acc.concat(getLinkPointLocations(link, 'y')), []));
    }

    xLocations.sort((a, b) => a - b);
    yLocations.sort((a, b) => a - b);

    // the total bounds calculated for the diagram
    const bounds = {
      left: xLocations[0],
      top: yLocations[0],
      right: xLocations[xLocations.length -1],
      bottom: yLocations[yLocations.length -1]
    };

    // the all important step of scaling down our calculated diagram bounds to the image
    // we choose height as the means to scale here because there's usually less error in the y axis
    // (as labels read from left to right and labels are hard to calculate properly)
    this._scale = (bitmapDrawnBounds.bottom - bitmapDrawnBounds.top) / (bounds.bottom - bounds.top);

    // for each of the unmapped asset, draw the overlay
    // the position and size is calculated using the expected position * scale and also adding the white space padding that we inspected in the image
    this.unmappedAssets.forEach(asset => {
      asset.overlayLeft = this._scale * (Number(asset.goJSNodeData.loc.split(' ')[0]) - bounds.left - getGreyBoxDimension(asset.goJSNodeData, 'x') / 2) + bitmapDrawnBounds.left;
      asset.overlayTop = this._scale * (Number(asset.goJSNodeData.loc.split(' ')[1]) - bounds.top - getGreyBoxDimension(asset.goJSNodeData, 'y') / 2) + bitmapDrawnBounds.top;

      asset.overlayWidth = this._scale * getGreyBoxDimension(asset.goJSNodeData, 'x');
      asset.overlayHeight = this._scale * getGreyBoxDimension(asset.goJSNodeData, 'y');
    });

    // auto scroll to the first unmapped asset
    this.onAssetSelected(this.unmappedAssets[0]);
  }

  public onAssetSelected(asset) {
    this.selectedAsset = asset;
    this._scrollToAssetOverlay(asset);
  }

  private _scrollToAssetOverlay(asset) {
    const containerWidth = this.imageContainerRef.nativeElement.offsetWidth;
    const containerHeight = this.imageContainerRef.nativeElement.offsetHeight;

    const desiredXScroll = Math.max(asset.overlayLeft - containerWidth / 2, 0);
    const desiredYScroll = Math.max(asset.overlayTop - containerHeight / 2, 0);

    this.imageContainerRef.nativeElement.scrollTo(desiredXScroll, desiredYScroll);
  }

}
