@if (loading) {
  <div class="loading-box" >
    <div class="img"  alt="loading"></div>
  </div>
}
@if (tab == 'Installations' || tab == 'Projects') {
  <div class="main-wrapperOpc" [class.expandproject]="expand">
    @if (tab == 'Installations' || tab == 'Projects') {
      <a (click)="expandModal()" class="expand"><i class="icon"
      aria-hidden="true" [class.icon-prev]="expand" [class.icon-next]="!expand"></i></a>
    }
    <!-- operationMode -->
    @if (tab == 'Installations' || tab == 'Projects') {
      <div class="tab">
        <a (click)="tabChangefn('Installations');tabChange.emit('Installations')"
        [class.active]="tab === 'Installations'">Installations</a>
        <a (click)="tabChangefn('Projects');tabChange.emit('Projects')" [class.active]="tab === 'Projects'">Projects</a>
      </div>
    }
    @if (tab === 'Installations') {
      <div class="content">
        <div class="search-bar">
          <input class="input-ctrl search-ctrl margin-top-0" [(ngModel)]="installationSearchText"
            (input)="updateInstallationFilter($event)" placeholder="Type to search"
            type="text" title={{installationSearchText}} />
        </div>
        <div class="margin-top-0" style="padding-right: 3%" style="flex: 1;">
          <csp-common-filter class="filter1" style="width: 200px;" [filterOptions]="installMainFilterList"
            [multipleFilterOptions]="installCompFilterList" (filterDataEmitter)="applyFilterDataSpareGroup($event)"
            (expandFilterView)="expandFilterView($event)" [tabName]="tabName"  [filterAPICallType]="'Installations'"
            [totalOptionLength]="installAllFilterListCount" [isFullScreen]="expand"
            (filterItemEmit)="filterItemEmit($event)" [filterStyles]="filterStyles">
          </csp-common-filter>
        </div>
        <div class="table-wrapper">
          <div class="freeze-table">
            <perfect-scrollbar fxFlex="auto">
              <div class="wrapper">
                <ng-container [ngTemplateOutlet]="table0"></ng-container>
              </div>
            </perfect-scrollbar>
            <div class="fixed-wrapper">
              <ng-container [ngTemplateOutlet]="table0"></ng-container>
            </div>
          </div>
          <ng-template #table0>
            @if (Installations && Installations.length) {
              <table class="base-table"
                aria-hidden="true">
                <tr>
                  @for (column of InstallationfilterColumns; track column; let idx = $index) {
                    @if (InstallationfilterColumns[idx].selected) {
                      <th class="textStyle" id="idinstallationSortBy" [class.expand-table]="expand"
                        (click)="sortInstallation(column.prop)">
                        {{column.name}}
                        <i class="default-sort-btn" aria-hidden="true"
                          [class.sort-asc]="this.installationSortDirection && (this.installationSortBy === column.prop)"
                        [class.sort-desc]="!this.installationSortDirection && (this.installationSortBy === column.prop)"></i>
                      </th>
                    }
                  }
                </tr>
                @for (item of Installations | paginate: configInstallation; track item; let idx = $index) {
                  <ng-container>
                    <tr (mouseover)="hoverOnInstallationRow(item.installationId)" (mouseleave)="hoverOnInstallationRow(null)"
                      [class.active]="isRowActive(item.installationId)">
                      @for (column of InstallationfilterColumns; track column; let idx = $index) {
                        @if (InstallationfilterColumns[idx].selected) {
                          <td (click)="clearSubstationNotificationSignal(item.id);installationInformation(item)"
                            [class.expand-table]="expand"
                            title="{{item[column.prop]}}">
                            <ng-container>{{item[column.prop]}}</ng-container>
                          </td>
                        }
                      }
                      <td>
                        <button class="btnn" type="button" [ngbTooltip]="tipbudget1" [autoClose]="true" triggers="manual"
                          #t="ngbTooltip" (click)="t.open()"><i class="icon square-menu-icon" aria-hidden="true"></i>
                        </button>
                      </td>
                      <ng-template #tipbudget1>
                        <div class="pop-container">
                          <div [ngClass]="{disabledNoOfCasesDiv: item.isExternal === 'true'}" class="menu-option" (click)="installationInformation(item)">
                            <div>Installation information</div>
                          </div>
                          <div class="menu-option" (click)="projectInformation(item.projectId)">
                            <div>Project information</div>
                          </div>
                          <div class="menu-option" [ngClass]="{disabledNoOfCasesDiv: !isRole() || !item.isMyProject}" (click)="gotoProject(item)">
                            <div>Go to project</div>
                          </div>
                        </div>
                      </ng-template>
                    </tr>
                  </ng-container>
                }
              </table>
            } @else {
              <div class="not-found-info">
                Cannot find any installation
              </div>
            }
          </ng-template>
        </div>
        @if (Installations.length > 0) {
          <div class="show-more-wrapper">
            <div style="display: inline-flex; height:30px;">
              <pagination-controls (pageChange)="pageChange($event)" nextLabel="" previousLabel="" class="my-pagination">
              </pagination-controls>
              @if (expand) {
                <div style="padding-top: 7px;"> Rows: </div>
              }
              @if (expand) {
                <div style="padding-top: 4px;">
                  <select (change)="pageSizeChangedInst($event)" [(ngModel)]="configInstallation.itemsPerPage">
                    @for (currentPageSize of pageSizeOption; track currentPageSize; let i = $index) {
                      <option [value]="currentPageSize"
                      [selected]="i == 0">{{currentPageSize}}</option>
                    }
                  </select>
                </div>
              }
            </div>
          </div>
        }
      </div>
    }
    @if (tab === 'Projects') {
      <div class="content">
        <div style="display: flex;">
          <div class="search-bar">
            <input class="input-ctrl search-ctrl margin-top-0" (input)="updateProjectFilter($event)"
              [(ngModel)]="projectSearchText" placeholder="Type to search"
              type="text" style="width: 160px;"/>
          </div>
          <div class="margin-top-0" style="padding-right: 3%" style="flex: 1;">
            <csp-common-filter class="filter1" style="width: 200px;" [filterOptions]="projMainFilterList"
              [multipleFilterOptions]="projCompFilterList" (filterDataEmitter)="applyFilterDataSpareGroup($event)"
              (expandFilterView)="expandFilterView($event)" [tabName]="tabName" [totalOptionLength]="projAllFilterListCount"
              [isFullScreen]="expand" (filterItemEmit)="filterItemEmit($event)"
              [filterStyles]="filterStyles"  [filterAPICallType]="'Projects'">
            </csp-common-filter>
          </div>
        </div>
        <div class="table-wrapper">
          <div class="freeze-table">
            <perfect-scrollbar fxFlex="auto">
              <div class="wrapper" style="min-height: 219px">
                <ng-container [ngTemplateOutlet]="table1"></ng-container>
              </div>
            </perfect-scrollbar>
            <div class="fixed-wrapper">
              <ng-container [ngTemplateOutlet]="table1"></ng-container>
            </div>
          </div>
          <ng-template #table1>
            @if (Projects && Projects.length) {
              <table style="margin-bottom: 11px;"
                class="base-table" aria-hidden="true">
                <tr>
                  @for (column of projectFilterColumns; track column; let idx = $index) {
                    @if (projectFilterColumns[idx].selected) {
                      <th class="textStyle" id="idSubstationSortBy2" (click)="sortProjects(column.prop)"
                        style="width: 150px; text-overflow: ellipsis;">
                        {{column.name}}
                        <i class="default-sort-btn" aria-hidden="true"
                          [class.sort-asc]="this.projectSortDirection && (this.projectSortBy === column.prop)"
                        [class.sort-desc]="!this.projectSortDirection && (this.projectSortBy === column.prop)"></i>
                      </th>
                    }
                  }
                </tr>
                @for (item of Projects | paginate: configProject; track item; let idx = $index) {
                  <ng-container>
                    <tr (mouseover)="projectHighlight(item.projectId)" (mouseleave)="projectHighlight(null)"
                      [class.active]="hoverOverMarker !== null ? hoverOverMarker.projectId === item.projectId : false">
                      @for (column of projectFilterColumns; track column; let idx = $index) {
                        @if (projectFilterColumns[idx].selected) {
                        <td style="white-space: nowrap ; overflow: hidden;
                            max-width: 100px; text-overflow: ellipsis;" title="{{item[column.prop]}}">
                            <ng-container>{{item[column.prop]}}</ng-container>
                          </td>
                        }
                      }
                      <td (click)="openTool()">
                        <button class="btnn" type="button" [ngbTooltip]="tipbudget2" [autoClose]="true" triggers="manual"
                          #t="ngbTooltip" (click)="t.open()"><i class="icon square-menu-icon" aria-hidden="true"></i>
                        </button>
                      </td>
                      <ng-template #tipbudget2>
                        <div class="pop-container">
                          <div class="menu-option" (click)="projectInformation(item.projectId)">
                            <div>Project information</div>
                          </div>
                          <div class="menu-option" (click)="gotoProject(item)" [ngClass]="{disabledNoOfCasesDiv: !isRole() || !item.isMyProject}">
                            <div>Go to project</div>
                          </div>
                        </div>
                      </ng-template>
                    </tr>
                  </ng-container>
                }
              </table>
            } @else {
              <div class="not-found-info">
                Cannot find any projects
              </div>
            }
          </ng-template>
        </div>
        @if (Projects.length > 0) {
          <div class="show-more-wrapper">
            <div style="display: inline-flex; height:30px;">
              <pagination-controls (pageChange)="pageChange($event)" nextLabel="" previousLabel="" class="my-pagination">
              </pagination-controls>
              @if (expand) {
                <div style="padding-top: 7px;"> Rows: </div>
              }
              @if (expand) {
                <div style="padding-top: 4px;">
                  <select (change)="pageSizeChangedProj($event)" [(ngModel)]="configProject.itemsPerPage">
                    @for (currentPageSize of pageSizeOption; track currentPageSize; let i = $index) {
                      <option [value]="currentPageSize"
                      [selected]="i == 0">{{currentPageSize}}</option>
                    }
                  </select>
                </div>
              }
            </div>
          </div>
        }
      </div>
    }
  </div>
}
<!-- End of operationMode -->

@if (tab !== 'Installations' && tab !== 'Projects' && isTableVisible) {
  <div class="main-wrapper" [class.expand]="expand" [ngClass]="
  { 'toggled-wrapper':
    isTableVisible &&  toggleTable}">
    @if (tab !== 'Installations' && tab !== 'Projects') {
      <a (click)="expandModal()" class="expand"><i class="icon"
      aria-hidden="true" [class.icon-prev]="expand" [class.icon-next]="!expand"></i></a>
    }
    <div class="tab">
      @if (!onlyAssets && !isHomePage) {
        <a (click)="tab = 'Substations'; tabChange.emit('Substations')"
        [class.active]="!onlyAssets && tab === 'Substations'">Installations</a>
      }
      @if (!onlyAssets && isHomePage) {
        <a (click)="tab = 'Substation-L'; tabChange.emit('Substation-L')"
        [class.active]="tab === 'Substation-L'">Installations</a>
      }
      @if (isFromHomePage) {
        <a (click)="tab = 'Assets'; tabChange.emit('Assets')"
        [class.active]="!onlyAssets && tab === 'Assets'">Assets</a>
      }
    </div>
    @if (tab === 'Substations' || isHomePage) {
      <div class="content">
        @if (!isFromHomePage) {
          <div class="row">
          <div class="search-bar common-search">
            <input class="input-ctrl search-ctrl margin-top-0" (input)="update($event)" placeholder="Type to search" type="text" />
          </div>
          @if(isHomePage){
          <div class="margin-top-0" style="padding-right: 3%" style="flex: 1;">
            <csp-common-filter class="filter1" style="width: 200px;" [filterOptions]="installMainFilterList"
            [multipleFilterOptions]="installCompFilterList" (filterDataEmitter)="applyFilterDataSpareGroup($event)"
            (expandFilterView)="expandFilterView($event)" [tabName]="tabName"  [filterAPICallType]="'Installations'"
            [totalOptionLength]="installAllFilterListCount" [isFullScreen]="expand"
            (filterItemEmit)="filterItemEmit($event)" [filterStyles]="filterStyles">
          </csp-common-filter>
          </div>
        }
        </div>
        }
        <perfect-scrollbar fxFlex="auto">
          <div class="table-wrapper">
            <div class="freeze-table">
              <div class="wrapper">
                <ng-container [ngTemplateOutlet]="table2"></ng-container>
              </div>
              <div class="fixed-wrapper">
                <ng-container [ngTemplateOutlet]="table2"></ng-container>
              </div>
            </div>
            <ng-template #table2>
              @if ((substations && substations.length) || (isHomePage) ) {
                <table class="base-table"
                  aria-hidden="true">
                  <tr>
                    @for (column of filterColumns; track column; let idx = $index) {
                      @if (filterColumns[idx].selected) {
                        <th id="idSubstationSortBy" [class.expand-table]="expand" (click)="sort(column.prop)">
                          {{column.name}}
                          @if (column.name) {
                          <i class="default-sort-btn" aria-hidden="true"
                            [class.sort-asc]="this.substationSortDirection && (this.substationSortBy === column.prop)"
                          [class.sort-desc]="!this.substationSortDirection && (this.substationSortBy === column.prop)"></i>
                          }
                        </th>
                      }
                    }
                  </tr>
                  @if(!isHomePage){
                  @for (item of filteredSubstations; track item; let idx = $index) {
                    @if (idx < substationIndex) {
                <tr (mouseover)="hoverOnSubstation(item.id)" (mouseleave)="hoverOnSubstation(null)" [class.active]="(hoverOverMarker !== null ? hoverOverMarker.getId() === item.id : false) ||
                    (selectedRegion && selectedRegion.get('region') === item.region)">
                        @for (column of filterColumns; track column; let idx = $index) {
                          @if (filterColumns[idx].selected) {
                            <td (click)="clearSubstationNotificationSignal(item.id)"
                              [class]="column.prop === 'riskValue' ? item.risk: ''" [class.expand-table]="expand"
                              title="{{item[column.prop]}}">
                              @if (idx === 0) {
                                <i class="notification" aria-hidden="true"
                                [style.visibility]="shouldDisplaySubstationNotificationSignal(item.id) ? 'visible': 'hidden'"></i>
                              }
                              <ng-container>{{ column.useLength?item[column.prop].length:item[column.prop]}}</ng-container>
          
                            </td>
                          }
                        }
                      </tr>
                    }
                  }
                }
                @else{
                  @for (item of Installations | paginate: configInstallation ; track item; let idx = $index) {
                    <ng-container>
                      <tr (mouseover)="hoverOnInstallationRow(item.installationId)" (mouseleave)="hoverOnInstallationRow(null)"
                       [class.active]="isRowActive(item.installationId)">
                        @for (column of filterColumns; track column; let idx = $index) {
                          @if (filterColumns[idx].selected) {
                            <td (click)="clearSubstationNotificationSignal(item.id);installationInformation(item)"
                              [class.expand-table]="expand"
                              title="{{item[column.prop]}}">
                              @if(filterColumns[idx].showRiskIndicator){
                                <span [class]="'risk-circle '+item.riskColor"></span>
                                }
                              <ng-container>{{item[column.prop]}}</ng-container>
                            </td>
                          }
                        }
                      </tr>
                    </ng-container>
                      }
                    }
                </table>
              }
            
              @else {
                <div class="not-found-info">
                  @if(!isHomePage){
                  Cannot find any substation: {{substationSearch}}
                  }
                  @else{
                    Cannot find any installations
                  }
                </div>
              }
            </ng-template>
          </div>
          @if (!isFromHomePage && !isHomePage) {
            <div class="show-more-wrapper">
              @if (substationIndex < substations?.length) {
                <a class="show-more" (click)="substationIndex = substationIndex + 10"
                >See more</a>
              }
            </div>
          }
        </perfect-scrollbar>
        @if (isHomePage && !isFromHomePage && (Installations?.length>0)) {
          <div class="show-more-wrapper">
            <div style="display: inline-flex; height:30px;">
              <pagination-controls (pageChange)="pageChange($event)" nextLabel="" previousLabel="" class="my-pagination">
              </pagination-controls>
              @if (expand) {
                <div style="padding-top: 7px;"> Rows: </div>
              }
              @if (expand) {
                <div style="padding-top: 4px;">
                  <select (change)="pageSizeChangedInst($event)" [(ngModel)]="configInstallation.itemsPerPage">
                    @for (currentPageSize of pageSizeOption; track currentPageSize; let i = $index) {
                      <option [value]="currentPageSize"
                      [selected]="i == 0">{{currentPageSize}}</option>
                    }
                  </select>
                </div>
              }
            </div>
          </div>
        }
      </div>
    }
    @if (tab === 'Assets' && (router.url.indexOf('information-explorer') > -1) || isFromHomePage) {
      <div class="content">
        <div class="search-bar">
          <input (input)="updateAssetFilter($event)" placeholder="Type to search" type="text" />
        </div>
        <perfect-scrollbar fxFlex="auto">
          <div class="table-wrapper">
            <div class="freeze-table">
              @if (expand && assets.length>0) {
                <div class="filter-section">
                  <div class="filter-container" style="right: -9px;height: 200px"
                    [ngClass]="{'filter-hide':!assetFilterShow}">
                    <div class="header">
                      <i class="icon icon-filter" aria-hidden="true" (click)="assetFilterShow=!assetFilterShow"
                      [ngClass]="{'checked':!assetFilterShow}"></i>
                      <span i18n>Column filter</span>
                    </div>
                    @for (item of assetFilterColumns; track item; let i = $index) {
                      <div class="filter-option"
                        (click)="item.selected=!item.selected;">
                        <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':item.selected}"></i>
                        <span>{{item.name}}</span>
                      </div>
                    }
                  </div>
                </div>
              }
              <div class="wrapper" style="min-height: 219px">
                <ng-container [ngTemplateOutlet]="table3"></ng-container>
              </div>
              <div class="fixed-wrapper">
                <ng-container [ngTemplateOutlet]="table3"></ng-container>
              </div>
            </div>
            <ng-template #table3>
              @if (assets && assets.length) {
                <table style="margin-bottom: 11px;" class="base-table"
                  aria-hidden="true">
                  <tr>
                    @for (column of assetFilterColumns; track column; let idx = $index) {
                      @if (assetFilterColumns[idx].selected) {
                        <th id="idSubstationSortBy2" (click)="sortAssert(column.prop)"
                          style="width: 150px; text-overflow: ellipsis;">
                          {{column.name}}
                          <i class="default-sort-btn" aria-hidden="true"
                            [class.sort-asc]="this.assetSortDirection && (this.assetSortBy === column.prop)"
                          [class.sort-desc]="!this.assetSortDirection && (this.assetSortBy === column.prop)"></i>
                        </th>
                      }
                    }
                  </tr>
                  @for (item of assets | paginate: config; track item; let idx = $index) {
                    @if (idx < assetIndex) {
                      <tr (mouseover)="hoverOnSubstation(item.substation, item)" (dblclick)="goToAssetInfo(item.id)"
                  (mouseleave)="hoverOnSubstation(null)" [class.active]="(hoverOverMarker !== null && !hover && assetHover === null ? hoverOverMarker.getId() === item.substation : false) ||
            (!hover && assetHover !== null ? item.id=== (hoverOverAsset!=null && hoverOverAsset.includes(item.id)?item.id:false): false) ||
            (selectedRegion && !hover && selectedRegion.get('region') === item.region)">
                        @for (column of assetFilterColumns; track column; let idx = $index) {
                          @if (assetFilterColumns[idx].selected) {
<td style="white-space: nowrap ; overflow: hidden;
                max-width: 100px; text-overflow: ellipsis;" title="{{item[column.prop]}}"
                              (click)="clearAssetNotificationSignal(item.id)"
                             >
                              @if (idx === 0) {
                                <span [class]="'risk-circle '+item.riskColor"></span>
                                <!-- <i class="notification" aria-hidden="true"
                                [style.visibility]="shouldDisplayAssetNotificationSignal(item.id) ? 'visible': 'visible'"></i> -->
                              }
                              <ng-container>{{item[column.prop]}}</ng-container>
                            </td>
                          }
                        }
                      </tr>
                    }
                  }
                </table>
              } @else {
                <div class="not-found-info">
                  Cannot find any asset: {{assetAssets}}
                </div>
              }
            </ng-template>
          </div>
        </perfect-scrollbar>
        @if ((router.url.indexOf('information-explorer') > -1) || isFromHomePage) {
          <div class="show-more-wrapper"
            >
            @if (assetIndex < assets.length) {
              <a class="show-more" (click)="assetIndex = assetIndex + 10;">See more</a>
            }
            @if (assetIndex!==10) {
              <pagination-controls (pageChange)="pageChange($event)" class="my-pagination">
              </pagination-controls>
            }
          </div>
        }
      </div>
    }
  </div>
}
@if (!isFromHomePage) {
  <div>
  </div>
}
