import { Injectable } from '@angular/core';
import CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})

export class EncrDecrService {

  constructor() {
  }

  encrypt(value, secretKey) {
    let ciphertext = CryptoJS.AES.encrypt( value, CryptoJS.enc.Utf8.parse(secretKey),
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7        
      }
    ).toString();
    // Compute the HMAC using encrypted value and secret key
    const hmac = CryptoJS.HmacSHA256(ciphertext, secretKey);
    const hexHmac = hmac.toString(CryptoJS.enc.Hex);
    return ciphertext + ',' + hexHmac;
  }
}