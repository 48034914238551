<div class="generic-table-container">
  @if (showLoadingSpinner) {
    <div class="loading-box">
      <div class="img" alt="loading"></div>
    </div>
  }
  <table class="table no-select" aria-hidden="true">
    <thead>
      <tr>
        @for (column of getVisibleColumns(); track column) {
<th id="idGenericTableCol1" cursorTooltip [tooltipOptions]="{
          arrow: false,
          placement: 'bottom',
          followCursor: true,
          content: column.text || column.name
          }">
            <span>{{column.text || column.name}}</span>
            @if (!column.disableSorting) {
              <i class="sort-btn" aria-hidden="true" (click)="sort(column)"
                [class.sort-asc]="configTableModel.sortAscending || configTableModel.sortColumn != column.name"
              [class.sort-desc]="!configTableModel.sortAscending && configTableModel.sortColumn === column.name"></i>
            }
          </th>
        }
        @if (!configTableModel.hideColumnFilter) {
          <th id="idGenericTableCol2" class="filter-header">
            <app-column-filter [columns]="configTableModel.columns"></app-column-filter>
          </th>
        }
      </tr>
    </thead>
    <tbody>
      @for (item of items | paginate: getPaginateConfig(); track item) {
        <ng-template [ngTemplateOutlet]="calculateBeforeCustomRow(item)"></ng-template>
        <tr class="generic-table-row">
          @for (column of getVisibleColumns(); track column) {
            <td (mouseenter)="onRowMouseEnter(item)"
              (mouseleave)="onRowMouseLeave()">
              <ng-container [ngTemplateOutlet]="column.cellTemplate ? column.cellTemplate : defaultCellTemplate"
              [ngTemplateOutletContext]="{ item: item, column: column }"></ng-container>
            </td>
          }
          @if (!configTableModel.hideColumnFilter) {
            <td>
              <ng-container
                [ngTemplateOutlet]="configTableModel.filterColumn?.cellTemplate ? configTableModel.filterColumn?.cellTemplate : null"
              [ngTemplateOutletContext]="{ item: item, column: configTableModel.filterColumn }"></ng-container>
            </td>
          }
        </tr>
        <ng-template [ngTemplateOutlet]="calculateAfterCustomRow(item)"></ng-template>
      }
    </tbody>
  </table>
  <div class="pagination">
    <pagination-controls (pageChange)="pageChanged($event)" [autoHide]="autoHidePager" class="my-pagination">
    </pagination-controls>
  </div>
</div>

<ng-template #defaultCellTemplate let-column="column" let-item="item">
  <span cursorTooltip [tooltipOptions]="{
    arrow: false,
    placement: 'bottom',
    followCursor: true,
    content: getTooltip(column, item)
    }">
    {{ getFormattedValue(column, item) | emptyValue }}
  </span>
</ng-template>
