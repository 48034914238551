
import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[appTwoDigitDecimaNumber]'
})
export class TwoDigitDecimaNumberDirectiveDirective {

  private regex: RegExp = new RegExp(/^\d*\.?\d{0,2}$/g);
  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Alt', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight', 'KeypadEqual', 'Del', 'Delete', '-', 'Minus'];
  constructor(private el: ElementRef) {
  }
  @Input('appTwoDigitDecimaNumber') numberOfDecimalDigits: string;
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys) {
      return;
    }
    const  d: string = this.numberOfDecimalDigits;
    switch (d) {
      case '1'  : this.regex = new RegExp(/^\d*\.?\d{0,1}$/g);  break;
      case '2'  : this.regex = new RegExp(/^\d*\.?\d{0,2}$/g);break;
      case '3'  : this.regex = new RegExp(/^\d*\.?\d{0,3}$/g); break;
      case '4'  : this.regex = new RegExp(/^\d*\.?\d{0,4}$/g); break;
      case '5'  : this.regex = new RegExp(/^\d*\.?\d{0,5}$/g); break;
      case '6'  : this.regex = new RegExp(/^\d*\.?\d{0,6}$/g); break;
      case '7'  : this.regex = new RegExp(/^\d*\.?\d{0,7}$/g); break;
      case '8'  : this.regex = new RegExp(/^\d*\.?\d{0,8}$/g); break;
      case '9' : this.regex = new RegExp(/^\d*\.?\d{0,9}$/g); break;
      case '10'  : this.regex = new RegExp(/^\d*\.?\d{0,10}$/g); break;
      case '11'  : this.regex = new RegExp(/^\d*\.?\d{0,11}$/g); break;
      default  : this.regex = new RegExp(/^[0-9]*$/g);break;
    }



    let current: string = this.el.nativeElement.value;
    let next: string = current.concat(event.key);
   
    if (next && !String(next).match(this.regex)) {
      event.preventDefault();
    }
  }
}


