import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyValue'
})
export class EmptyValuePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value === null || value === undefined || value ===''|| value === '0001-01-01T00:00:00' || value === '01 Jan 0001 00:00:00')
      return "-";
    return value;
  }

}
