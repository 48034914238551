import { HttpClient } from '@angular/common/http';
import { Injectable} from '@angular/core';
import { Observable } from 'rxjs';
import { config } from '../config';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { GeneralUserConfirmationModalComponent } from '../../dashboard/modals/general-user-confirmation-modal/general-user-confirmation-modal.component';
import { RiseToRelcareUnmappedAssetsModalComponent } from '../rise-to-relcare-unmapped-assets-modal/rise-to-relcare-unmapped-assets-modal.component';
import { v4 as uuid }  from 'uuid';

const PROPERTY = {
  ID: 'prop_id',
  NAME: 'prop_name',
  CATEGORY_ID: 'prop_catg_id',
  CATEGORY_NAME: 'prop_catg_name',
  DATA_TYPE: 'prop_datatype_name',
  DECIMAL_DIGITS: 'num_of_decimal_digits'
};

// the following are special properties in relcare that need to be there for all assets,
// and are require some special handling
const RELCARE_ASSET_CONFIG_PROP_ID = '0b1f14c3-aca6-4219-b229-6adcf65e7f48';
const RELCARE_SLD_EQPT_REF_PROP_ID = '0b614d4d-15b6-48d4-8849-8500a1ee2d9c';
const RELCARE_SLD_UNIQUE_REF_PROP_ID = '0ba4c9ef-7783-47e2-9b80-1ac9904d2e50';
const RELCARE_SLD_LOAD_REF_PROP_ID = '39453e97-7428-4764-a025-64919f31bd51';
const RELCARE_SLD_SOURCE_REF_PROP_ID = 'dadc288c-8b76-42bb-ac35-ca0b5e576b66';

@Injectable({
  providedIn: 'root',
})
export class RiseToRelcareService {

  private _riseToRelcarePropMap: { [key: string]: { rise: any, relcare: any, isManual?: boolean } };
  private _riseExpectedProperties: { [key: string]: boolean } = {}; // used as an extra validation to confirm whether substation is from rise or not

  private _riseToRelcareEqptMap: { [key: string]: { rise: any, relcare: any } }; // key here is RISE eqpt name id + category id + type id
  private _relcareEqptIdMap: { [key: string]: string }; // key here is RELCARE eqpt name id + category id + type id

  private _widgetKeyMappings: { [key: string]: string };
  private _relcareWidgetTemplates: { [key: string]: any } = {};

  private reliabilityDataTemplate: () => any;

  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    modalConfig: NgbModalConfig
  ) {

    modalConfig.backdrop = 'static';

    // read the rise to relcare property mapping file
    http.get(config.ASSETS_DIR + 'rise-to-relcare/property-mappings.json').subscribe((res: any) => {
      this._riseToRelcarePropMap = res;
      // the sld eqpt ref, load ref and source ref properties (used to validated whether substation is from rise)
      ['SLD equipment reference', 'Load reference', 'Source reference'].forEach(risePropName => {
        this._riseExpectedProperties[Object.keys(this._riseToRelcarePropMap)
          .find(risePropId => this._riseToRelcarePropMap[risePropId].rise[PROPERTY.NAME] === risePropName)] = true;
      });
    });

    // read the rise to relcare equipment mapping file
    http.get(config.ASSETS_DIR + 'rise-to-relcare/equipment-mappings.json').subscribe((res: any) => {
      this._riseToRelcareEqptMap = res;
      // create a map from equipment properties to equipmentId
      this._relcareEqptIdMap = {};
      Object.values(this._riseToRelcareEqptMap).filter(entry => entry.relcare).map(entry => entry.relcare).forEach(relEqpt => {
        const key = [relEqpt.equipmentNameId, relEqpt.equipmentCategoryId, relEqpt.equipmentTypeId].join(';;');
        this._relcareEqptIdMap[key] = relEqpt.equipmentId;
      });
    });

    // read the relcare reliability data default template (with empty values)
    http.get(config.ASSETS_DIR + 'rise-to-relcare/relcare-reliability-data-template.json').subscribe((res: any) => {
      this.reliabilityDataTemplate = () => JSON.parse(JSON.stringify(res));
    });

    // get the widet templates for relcare library
    http.get(config.ASSETS_DIR + 'widgets.json').subscribe((res: any[]) => {
      res.forEach(widget => {
        this._relcareWidgetTemplates[this._getWidgetKey(widget.name)] = widget;
      });
    });

    http.get(config.ASSETS_DIR + 'rise-to-relcare/widget-name-mappings.json').subscribe((res: any) => {
      this._widgetKeyMappings = res;
    });
  }

  /**
   * get widget key
   * rise to relcare widgets might have slight differences in widget names (spaces, capital letters mismatch)
   * this method just converts to lower case and removes spaces to act as a common key between rise and relcare for widgets
   * @param  {string} widgetName
   */
  private _getWidgetKey(widgetName: string) {
    return widgetName.toLowerCase().replace(/\s*/g, '');
  }

  /**
   * fix incorrect rise sld references
   * RISE uses a completely different convention for sld references (it uses bayName + '_' + sldRefIter)
   * BUT, this format is also incorrectly marked as sldEqptReference (even though sldEqptReference does not depend on bay)
   * This leads to mismatches and is a actually a mess.
   * So we just change all sldReferences to the actual sldEqpt reference (`assetSnippetSldReference` property)
   * @param  {any} substation
   */
  private _fixIncorrectRiseSldReferences(substation: any) {
    const incorrectSldRefMap = {};
    substation.serializedSld.nodes.forEach(node => {
      if (!node.assetSnippetSldReference) { return; }
      incorrectSldRefMap[node.sldReference] = node.assetSnippetSldReference;
      node.sldReference = node.assetSnippetSldReference;
      node.sldEqptReference = node.assetSnippetSldReference;
      if (node.sldData) { node.sldData.sldReference = node.assetSnippetSldReference; }
    });
    // Also, in the substationDto, the incorrect sldReference is used. So it needs to be changed there as well
    let components = [].concat(
      substation.psLs,
      substation.busbars,
      ...substation.bays.map(bay => bay.allComponents)
    );
    components.forEach(component => {
      if (incorrectSldRefMap[component.sldReference]) {
        component.sldReference = incorrectSldRefMap[component.sldReference];
      }
    });
  }

  /**
   * extract all component data from substation
   * @param substation - the substation object to get the values from
   * @returns {assetIdMap: {[key: string]: any}, sldRefMap:{[key: string]: any}} two maps of sld data one with assetId and other with sld reference as keys
   * parse through the given substation dto structure, and extract all component data for all equipments inside
   * also parse through the serialized sld because RISE backend weirdly doesn't add busbars and psLs inside the substation dto, only in serialized sld
   */
  private _extractAllComponentSldData(substation: any): { assetIdMap: {[key: string]: any},  sldRefMap: {[key: string]: any} } {
    // rise doesn't have sldReferences or assetIds for links
    // relcare needs it though (specifically sldReference)
    // so first we gather the set of links in serializedSld and create a map based on what components the link connects
    const goJSLinksMap = {};
    substation.serializedSld.links.forEach((link: any) => {
      const key = link.sldData.connectedComponentsFirst + ';;' + link.sldData.connectedComponentsSecond;
      // for each key, there can be more than one actual link (as each node has many ports and links are only unique between ports not nodes)
      // the key is made using the node information (sldReference of the nodes) because port information is not present in substationDTO
      goJSLinksMap[key] = goJSLinksMap[key] || [];
      goJSLinksMap[key].push(link);
    });

    let components = [].concat(
      substation.psLs,
      substation.busbars,
      ...substation.bays.map(bay => bay.allComponents)
    );
    const sldRefMap = {};
    const assetIdMap = {};
    components.forEach(component => {
      // if component is a link, then before anything else, we should assign it (and the corresponding entry in serializedSld)
      // a new asset id and a sldReference
      if (component.assetName === 'IdealCable') {
        // find the goJS link in serializedSld that has the same assetId and sldReference
        // (they are expected to be empty id and null reference, so essentially we just pick the first available link)
        const goJSLink = goJSLinksMap[component.connectedComponentsFirst + ';;' + component.connectedComponentsSecond]
          .filter(link => link.sldData.assetId === component.assetId && link.sldData.sldReference === component.sldReference)[0];

        // set new asset id if needed
        if (component.assetId === '00000000-0000-0000-0000-000000000000') {
          component.assetId = uuid();
        }
        // set new sldReference if needed
        if (!component.sldReference) {
          substation.serializedSld.sldReferenceIter += 1;
          component.sldReference = `default_${substation.serializedSld.sldReferenceIter}`;
        }
        // set the newly assigned values in the goJSLink (so we can mark this link as taken and assign it an sldReference for relcare)
        if (goJSLink) {
          goJSLink.sldData.assetId = component.assetId;
          goJSLink.sldReference = component.sldReference;
          goJSLink.sldData.sldReference = component.sldReference;
        }
      }

      // check for null sldRef
      // this will cause problems. don't include them in the map if they exist
      if (!component.sldReference) { return console.log('NULL SLD_REFERENCE RECEIVED DURING RISE IMPORT:', component); }
      // rise doesn't give an asset id to some components like Joint, we just add them here
      if (component.assetId === '00000000-0000-0000-0000-000000000000') { component.assetId = uuid(); }

      // add the component in the sldRef and assetId maps
      sldRefMap[component.sldReference] = component;
      assetIdMap[component.assetId] = component;
    });

    // we need to parse through the serializedSld aslo, because RISE backend for no good reason doesn't supply busbars and psLs
    // arrays in the substation dto. The only way to get the sld data for those assets is through the serializedSldData
    substation.serializedSld.nodes.forEach(node => {
      const component = node.sldData;
      // if it's an unsaved asset or it's already in the asset id map, return without doing anything
      if (assetIdMap[component.assetId] || component.assetId === '00000000-0000-0000-0000-000000000000') { return; }
      if (!component.sldReference) { return console.log('NULL SLD_REFERENCE RECEIVED DURING RISE IMPORT:', component); }

      // we found an asset that is present in serializedSld but NOT in the substation DTO.
      // add the component in the sldRef and assetId maps
      sldRefMap[component.sldReference] = component;
      assetIdMap[component.assetId] = component;

      // we're not done yet! While the asset maps are fine, we need to fix this abomination in the RISE substation,
      // by adding the components ONLY found in serailizedSld in the main substation data structure.
      // All other methods in this service rely on the assumption that all the assets data can be found in the substation bays, psLs, busbars arrays
      switch (node.class) {
        case 'power-supply':
        case 'load':
        case 'ground-component':
          substation.psLs.push(component);
          break;
        case 'busbar':
          substation.busbars.push(component);
          break;
        default:
          if (substation.bays.length) {
            substation.bays[0].allComponents.push(component);
          }
      }
    });

    return { assetIdMap, sldRefMap };
  }

  /**
   * convert rise widget template to relcare
   * there are small differences between rise and relcare widget templates for the same equipment
   * for example circuit breaker in rise has 3 ports, whereas relcare only has 2
   * to play it safe, we compare the rise widget to relcare widget and look for discrepencies
   * @param  {any} riseWidget
   * @returns {boolean} whether the widget template in relcare was found and used
   */
  private _convertRiseWidgetTemplateToRelcare(riseWidget: any): boolean {
    const riseWidgetKey = this._getWidgetKey(riseWidget.name);
    // some widgets in rise have different names entirely to the ones in relcare
    // for those a mapping is defined;
    const relcareWidgetKey = this._widgetKeyMappings[riseWidgetKey] || riseWidgetKey;
    const relcareWidgetTemplate = this._relcareWidgetTemplates[relcareWidgetKey];
    if (!relcareWidgetTemplate) { return false; }

    Object.keys(relcareWidgetTemplate).forEach(key => {
      // add properties present in relcare but not in the rise
      if (!riseWidget[key]) { riseWidget[key] = relcareWidgetTemplate[key]; }
    });
    // TODO: We should ideally also change the goJS node key here based on the relcare widget name
    // TODO: But this would involve also changing any link definitions that point to this node
    // borrow name from relcare widget
    riseWidget.name = relcareWidgetTemplate.name;
    // use only the ports defined in relcare widget
    // this is not full proof as there could be links in the diagram, to an extra port we removed
    // but we keep it simple and assume any extra port in RISE would be added at the end of the ports array
    riseWidget.ports = relcareWidgetTemplate.ports;
    // if the widget is not resizable, then ensure we use the relcare heights and widths instead of trusting the ones in rise
    if (!relcareWidgetTemplate.resizable) {
      riseWidget.height = relcareWidgetTemplate.height;
      riseWidget.width = relcareWidgetTemplate.width;
    }
    // remove some known extra properties in rise that relcare doensn't use
    const unusedProperties = [
      'hoverText',
      'displayInPalette',
      'paletteImageName',
      'shouldRotateOnDiagram',
      'metadataDescription',
      'changesOnViewMode',
      'labelAlign',
      'textFontController'
    ];
    unusedProperties.filter(key => !relcareWidgetTemplate[key]).forEach(key => delete riseWidget[key]);

    return true;
  }

  /**
   * convert equipment ids for component data
   * looks at the rise equipment name id, type id, category id and converts them all to the relcare versions
   * also converts the asset name to match the exact name in relcare database
   * the conversion happens "in place" i.e. given object is directly modified.
   * additionally, the equipmentId (which is a combiantion of equipment name, category and type) is returned
   * @param  {any} sldData - the component data for an asset
   * @returns whether a match was found and ids were transformed or not
   */
   private _convertEquipmentIdsForComponentData(sldData: any): boolean {
    const equipmentKey = [sldData.equipmentNameId, sldData.equipmentCategoryId, sldData.equipmentTypeId].join(';;');
    if (!this._riseToRelcareEqptMap[equipmentKey] || !this._riseToRelcareEqptMap[equipmentKey].relcare) { return false; }

    const relcareEquipment = this._riseToRelcareEqptMap[equipmentKey].relcare;
    const { equipmentNameId, equipmentCategoryId, equipmentTypeId } = relcareEquipment;
    Object.assign(sldData, { equipmentNameId, equipmentCategoryId, equipmentTypeId, assetName: relcareEquipment.equipmentName });
    delete sldData.assetClassId; // delete the "assetClassId" property as we are not mapping that to relcare (unnecessary)
    return true;
  }

  /**
   * convert properties for a single asset
   * map the property ids from rise to relcare
   * also output the asset properties in the appropriate data structure
   * relcare data structure has properties organised by category, while rise just has a flat list of properties
   * equipmentId and substationId are also present in the relcare data structure but absent in rise
   * @param  {string} assetId
   * @param  {any[]} properties
   * @param  {any} assetSldData
   * @param  {string} substationId
   * @returns {propertiesByCategory: any, propertiesDict: any}
   * returns the data structure for asset properties in relcare format (with relcare prop ids), and a dict of all properties
   */
  private _convertAndCategorizePropertiesForAsset(riseProperties: any[], includeReliability: boolean): any {
    // a dict of all properties by their relcare property id
    const propertiesDict = {};
    // a dict of properties by category for each category id
    const propertiesByCategoryDict = {};
    // helper fn to add a new property caetgory to the map
    // returns the newly added category
    const addCategoryFromProperty = (relcareProp => {
      const category = {
        category: relcareProp[PROPERTY.CATEGORY_NAME],
        categoryId: relcareProp[PROPERTY.CATEGORY_ID],
        properties: []
      };
      propertiesByCategoryDict[relcareProp[PROPERTY.CATEGORY_ID]] = category;
      return category;
    });

    // for each rise property, find the correct relcare property and place it in the categorized map
    riseProperties.forEach(riseProperty => {
      // check if the rise property exists in the property map, if not ignore this property
      if (!this._riseToRelcarePropMap[riseProperty.propertyId]) { return; }

      // find the corresponding relcare property and add it to the categorised property map
      const mapping = this._riseToRelcarePropMap[riseProperty.propertyId];
      const relcareProp = mapping.relcare;

      // user controls whether reliability data should be imported or not
      // so all proeprties related to reliablity should only be imported based on this boolean
      if (!includeReliability && relcareProp[PROPERTY.CATEGORY_NAME] === 'Reliability') { return; }

      // sometimes multiple rise properties can be mapped to a single relcare property
      // so in those cases we give preference to the manual rise properties
      // unless the current value is "null"
      if (propertiesDict[relcareProp[PROPERTY.ID]]) {
        // this relcare property has already been mapped
        // if previously mapped property has null value, and current mapping is not manual (user input),
        // then we ignore this new mapping
        if ((propertiesDict[relcareProp[PROPERTY.ID]].value !== null && propertiesDict[relcareProp[PROPERTY.ID]].value.length)
          && !mapping.isManual) { return; }

        // if current mapping value is null, then regardless we don't override even if it is manul
        if (riseProperty.propertyValue === null || !riseProperty.propertyValue.length) { return; }

        // if previously mapped value was null or the current mapping takes precedence,
        // remove the current property from the category list, so it can be replaced by the new mapping
        const propList = propertiesByCategoryDict[relcareProp[PROPERTY.CATEGORY_ID]].properties;
        propList.splice(propList.findIndex(p => p.propertyId === relcareProp[PROPERTY.ID]), 1);
      }

      // the relcare prop in the required JSON structure to be sent to backend
      const relcarePropJSONDto = {
        propertyId: relcareProp[PROPERTY.ID],
        propDataTypeName: relcareProp[PROPERTY.DATA_TYPE],
        assetPropSeqName: relcareProp[PROPERTY.NAME],
        assetPropSeqNum: 0,
        numOfDecimalDigits: Number(relcareProp[PROPERTY.DECIMAL_DIGITS]),
        value: riseProperty.propertyValue || null
      };
      if (mapping.isManual && relcarePropJSONDto.value) { (relcarePropJSONDto as any).isManual = true; }

      // rise sometimes uses "3-phase" instead of just "3" as the asset configuration value
      // which is problematic for relcare. So we extract only the number part from the rise string.
      if (relcareProp[PROPERTY.ID] === RELCARE_ASSET_CONFIG_PROP_ID) {
        if (!riseProperty.propertyValue.match(/^\d*/)) {
          relcarePropJSONDto.value = null;
        }
        relcarePropJSONDto.value = riseProperty.propertyValue.match(/^\d*/)[0];
      }

      // add the relcare mapped property to the category list and the property dict
      const category = propertiesByCategoryDict[relcareProp[PROPERTY.CATEGORY_ID]] || addCategoryFromProperty(relcareProp);
      category.properties.push(relcarePropJSONDto);
      propertiesDict[relcareProp[PROPERTY.ID]] = relcarePropJSONDto;

      // special handling for unique reference property
      // rise only has sld eqpt reference, relcare has a separate sld unique reference
      // so we duplicate the value and add the unique reference property in the relcare dto
      if ([RELCARE_SLD_EQPT_REF_PROP_ID, RELCARE_SLD_LOAD_REF_PROP_ID, RELCARE_SLD_SOURCE_REF_PROP_ID].indexOf(relcareProp[PROPERTY.ID]) !== -1) {
        const sldUniqueRefPropJSONDto = {
          propertyId: RELCARE_SLD_UNIQUE_REF_PROP_ID,
          propDataTypeName: 'Text',
          assetPropSeqName: 'SLD unique reference',
          assetPropSeqNum: 0,
          numOfDecimalDigits: 0,
          value: riseProperty.propertyValue
        };
        category.properties.push(sldUniqueRefPropJSONDto);
        propertiesDict[RELCARE_SLD_UNIQUE_REF_PROP_ID] = sldUniqueRefPropJSONDto;
      }

    });

    // return a list of categories, each with the respective properties
    return {
      propertiesByCategory: Object.values(propertiesByCategoryDict),
      propertiesDict
    };
  }

  /**
   * transform all rise asset snippet properties
   * @param  {{assetId: string, properties: any[]}[]} the rise snippet properties list for all assets
   * @param {[key: string]: any} assetIdMap
   * returns the transformed relcare snipppet properties data structur for all assets, and the equipment reference for the substation
   * @returns {substationSnippetProperties: any, equipmentReference: {assetId: string, equipmentReference: string}}
   */
  private _transformAllRiseAssetSnippetProperties(substationRiseAssetProperties: {assetId: string, properties: any[]}[], assetIdMap: any, substationId: string, includeReliability: boolean): any {
    // convert the asset propertes to relcare format
    const substationSnippetProperties = { snippetPropForLinkedAssetsDtos: [] };
    // the equipment reference (map of asset id and equipment reference that is needed by relcare backend)
    const equipmentReference = [];

    // for each asset in asset properties list, convert the structure to relcare format
    substationRiseAssetProperties.forEach((asset: any) => {
      if (!assetIdMap[asset.assetId]) { return console.log('PROPERTIES OF UNSAVED RISE ASSET FOUND', asset.assetId); }
      const assetSldData = assetIdMap[asset.assetId];
      const equipmentKey = [assetSldData.equipmentNameId, assetSldData.equipmentCategoryId, assetSldData.equipmentTypeId].join(';;');
      const equipmentId = this._relcareEqptIdMap[equipmentKey];

      // map the rise properties to relcare and transform data into required data structure
      const { propertiesByCategory, propertiesDict } = this._convertAndCategorizePropertiesForAsset(asset.properties, includeReliability);

      // asset config and sld unique ref properties are duplicated and added again in the main object below
      const assetConfigProp = propertiesDict[RELCARE_ASSET_CONFIG_PROP_ID];
      const sldUniqueRefProp = propertiesDict[RELCARE_SLD_UNIQUE_REF_PROP_ID];

      // rise doesn't set the asset configuration property in the sld data, but relcare needs this.
      if (assetConfigProp && assetConfigProp.value) {
        assetSldData.assetConfiguration = Number(assetConfigProp.value);
      }

      // the snippet properties for the asset that will go in the relcare dto
      const relcareAssetSnippet = {
        equipmentId,
        assetId: asset.assetId,
        substationId,
        propertiesByCategory,
        linkedAssetId2: '00000000-0000-0000-0000-000000000000',
        linkedAssetId3: '00000000-0000-0000-0000-000000000000',
        isParentAsset: true,
        assetConfiguration: assetConfigProp,
        sldUniqueReference: sldUniqueRefProp
      };

      // add the snippet properties for the asset in the relcare dto
      substationSnippetProperties.snippetPropForLinkedAssetsDtos.push(relcareAssetSnippet);
      // add the asset to the equipment reference list
      equipmentReference.push({
        assetId: relcareAssetSnippet.assetId,
        equipmentReference: sldUniqueRefProp.value
      });

      const createLinkedAsset = (number: number) => {
        const assetSnippetCopy = JSON.parse(JSON.stringify(relcareAssetSnippet));
        const newAssetId = uuid();
        assetSnippetCopy.assetId = newAssetId;
        assetSnippetCopy.isParentAsset = false;
        assetSnippetCopy.sldUniqueReference.value += `_${number}`; // {SLD_REF} + '_1' / '_2'

        // modify the eqpt reference and sld unique reference properties as well
        assetSnippetCopy.propertiesByCategory
          .reduce((props: any[], category: any) => props.concat(category.properties), [])
          .filter((prop: any) => [RELCARE_SLD_EQPT_REF_PROP_ID, RELCARE_SLD_UNIQUE_REF_PROP_ID].indexOf(prop.propertyId) !== -1)
          .forEach((prop: any) => prop.value += `_${number}`); // {SLD_REF} + '_1' / '_2'

        // we've created asset properties for the the linked asset
        // we also need to add this linked asset inisde the substation dto
        const parentSldData = assetIdMap[relcareAssetSnippet.assetId];
        if (parentSldData) {
          const linkedAssetSldData = JSON.parse(JSON.stringify(parentSldData));
          linkedAssetSldData.sldReference = assetSnippetCopy.sldUniqueReference.value;
          linkedAssetSldData.linkedAssetId1 = '00000000-0000-0000-0000-000000000000';
          linkedAssetSldData.linkedAssetId2 = '00000000-0000-0000-0000-000000000000';
          linkedAssetSldData.linkedAssetComponent1 = null;
          linkedAssetSldData.linkedAssetComponent2 = null;

          parentSldData[`linkedAssetComponent${number}`] = linkedAssetSldData;
          parentSldData[`linkedAssetId${number}`] = assetSnippetCopy.assetId;
        }

        return assetSnippetCopy;
      }

      // if the asset configuration is 1, the relcare component should have linked assets
      // RISE doesn't have this feature, so we need to create them here
      if (assetConfigProp && assetConfigProp.value === '1') {
        const linkedAssetProps2 = createLinkedAsset(1);
        const linkedAssetProps3 = createLinkedAsset(2);

        // add the linked assets to the substation asset propeties snippet array
        substationSnippetProperties.snippetPropForLinkedAssetsDtos.push(linkedAssetProps2, linkedAssetProps3);
        // modify the parent asset's linkedAsset variables to the newly generated asset ids
        relcareAssetSnippet.linkedAssetId2 = linkedAssetProps2.assetId;
        relcareAssetSnippet.linkedAssetId3 = linkedAssetProps3.assetId;
      }
    });

    return { substationSnippetProperties, equipmentReference };
  }

  /**
   * remove unmappable rise asset
   * some rise assets don't exist in relcare equipment library and need to be fully removed from substation
   * this includes removing the asset snippet properties, the component from the bays list and also from the serialized sld
   * further any idea cables and links connecting this asset should also be removed
   * @param  {any} substation
   * @param  {string} assetId
   * @param  {string} sldReferece
   */
  private _removeUnmappableRiseAsset(substation: any, assetId: string, sldReference: string): any[] {
    // store the sldData of the asset + ideal cables connected to it that will be removed
    // this will be returned, so that they can also be deleted from the asset maps
    const removedAssets = [];

    // remove the asset from the substation asset snippet properties list
    const assetPropertiesIndex = substation.assetProperties.findIndex((asset: any) => asset.assetId === assetId);
    if (assetPropertiesIndex !== -1) { substation.assetProperties.splice(assetPropertiesIndex, 1); }

    // list of equipment lists in substation dto
    // this just makes it easier to perform delete / modify operations on components without collecting the list of equipments again and again
    const equipmentLists = [];
    substation.bays.forEach(bay => equipmentLists.push(bay.allComponents));
    equipmentLists.push(substation.psLs);
    equipmentLists.push(substation.busbars);

    // define a helper fn that will remove an asset from the substation DTO (bay.allComponents, psLS, busbars), given a predicate fn
    // we're generalizing this so that we can also use the same code to remove ideal cables from the substation DTO
    const removeAssetFromSubstationDTO = (predicate: (component: any) => boolean): boolean => {
      for (let list of equipmentLists) {
        let index = 0;
        for (let componentSldData of list) {
          if (predicate(componentSldData)) {
            removedAssets.push(componentSldData);
            return list.splice(index, 1)[0]; // return immediately after removing the asset
          }
          index += 1;
        }
      }
    }

    // remove the asset from the substation dto
    removeAssetFromSubstationDTO((component) => component.assetId === assetId);
    // define a predicate to determine links connected to the component
    // const linkPredicate = (component) => {
    //   return component.connectedComponentsFirst === sldReference
    //     || component.connectedComponentsSecond === sldReference;
    // }

    // repeatedly remove all the ideal cables that connect the removed asset
    // NOTE: We don't have to bother with modifying the other component connected to this ideal cable because
    // in RISE ideal cables don't have sldReference and so their connectedComponentsFirst/Second property will be null anyway
    // while (removeAssetFromSubstationDTO(linkPredicate)) {}

    // the go js node key for the removed asset
    const nodeIndex = substation.serializedSld.nodes.findIndex(node => node.sldReference === sldReference);
    // remove the node from the serialized sld nodes list
    const goJSNodeKey = substation.serializedSld.nodes.splice(nodeIndex, 1)[0].key;
    // remove all the links that connect this node from the serialized sld links list
    substation.serializedSld.links = substation.serializedSld.links.filter(link => link.to !== goJSNodeKey && link.from !== goJSNodeKey);

    return removedAssets;
  }

  /**
   * transform reliability data from rise to relcare
   * @param  {any} riseReliabilityData
   * @returns {any} the reliability data in relcare format
   */
  private _transformReliabilityDataFromRiseToRecalre(riseReliabilityData: any): any {
    const relcareReliability = this.reliabilityDataTemplate();
    const relcareFloatProperties = Object.keys(relcareReliability)
      .filter(key => relcareReliability[key])
      .filter(key => relcareReliability[key].hasOwnProperty('floatProperty'));
    Object.keys(riseReliabilityData).forEach(riseKey => {
      if (!relcareReliability.hasOwnProperty(riseKey)) { return; }
      let value = riseReliabilityData[riseKey];
      if (relcareFloatProperties.indexOf(riseKey) !== -1) {
        value = { floatProperty: (value || 0) };
      }
      relcareReliability[riseKey] = value;
    });
    return relcareReliability;
  }

  /**
   * remove unnecessary properties from asset sld data
   * RISE and Relcare handle similar things differently sometimes, leading to properties that exist in the sld data
   * that Relcare doesn't use and would be confusing for developers reading the data
   * @param  {any} assetSldData
   */
  private _removeUnnecessaryPropertiesFromAssetSldData(assetSldData: any) {
    if (!assetSldData) { return; }

    delete assetSldData.bayId;
    delete assetSldData.bayName;
    delete assetSldData.uniqueReference;

    this._removeUnnecessaryPropertiesFromAssetSldData(assetSldData.linkedAssetComponent1);
    this._removeUnnecessaryPropertiesFromAssetSldData(assetSldData.linkedAssetComponent2);
  }
  /**
   * mark connected components
   * rise only marks connectedComponentFirst/Second for Ideal Cables
   * relcare on the other hand has these fields as non null for all assets
   * additionally assets that have more than 2 ports use the connectedComponentsList property
   * @param  {{[key:string]:any}} sldRefMap
   * @param {any} serializedSld
   */
  private _markConnectedComponents(sldRefToComponentMap: {[key: string]: any}, serializedSld: any) {
    // create a convenient map of all node sld reference to the goJS part
    // this will help us decide which component property should be updated (connectedComponentsList vs connectedComponentsFirst/Second)
    const sldRefToGoJSPartMap = {};
    serializedSld.nodes.concat(serializedSld.links).forEach(part => {
      if (!part.sldReference) { return; }
      sldRefToGoJSPartMap[part.sldReference] = part;
    });

    const keyFirst = 'connectedComponentsFirst';
    const keySecond = 'connectedComponentsSecond';

    // first we iter through all the components and set the connectedComponnetsList to empty array
    // if the component has more than 1 port or is a busbar
    // this way even components that are not connected to anything, will have this property as non-null if needed
    // relcare backend needs this to be non-null for busbars and other relevant equipments
    Object.values(sldRefToComponentMap)
      .filter(component => component.assetName !== 'IdealCable')
      .forEach(component => {
        const node = sldRefToGoJSPartMap[component.sldReference];
        if (!node) { return } // sanity check
        if (node.isConnector || node.ports.length > 2) {
          component.connectedComponentsList = component.connectedComponentsList || [];
        }
      });

    // we go through all the ideal cables in the components list
    // and for each ideal cable we find out what asset it connects and on which port
    // then we update the corresponding property on the component that the ideal cable connects to
    Object.values(sldRefToComponentMap)
      .filter(component => component.assetName === 'IdealCable')
      .forEach(idealCable => {
        // the link object contains information about which port of the asset is connected to the ideal cable
        const link = sldRefToGoJSPartMap[idealCable.sldReference];
        if (!link) { console.log(idealCable); }

        [keyFirst, keySecond].forEach(connectionKey => {
          const component = sldRefToComponentMap[idealCable[connectionKey]];
          if (!component) { return; } // don't expect this to happen, but just a sanity check

          const node = sldRefToGoJSPartMap[component.sldReference];
          if (node.isConnector || node.ports.length > 2) {
            component.connectedComponentsList.push(idealCable.sldReference);
          } else {
            // we need to figure out the port number that is used to connect the component to the link (ideal cable)
            // based on whether the component is marked as the "from" or "to" node in link data, we can get the port id from "fid" or "tid"
            const portNumber = link.from === node.key ? link.fid : link.tid;
            component[portNumber === '1' ? keyFirst : keySecond] = idealCable.sldReference;
          }
        });

      });
  }


  /**
   * fix gas compartment node data
   * Relcare gets the innovative module id from node.data, RISE only stores it in the node.data.sldData
   * @param  {} serializedSld
   */
  private _fixGasCompartmentNodeData(serializedSld) {
    serializedSld.gasCompartmentList.forEach(gasCompartment => {
      const node = serializedSld.nodes
        .filter(n => n.name === 'gas-compartment')
        .find(n => n.sldReference === gasCompartment.InnovativeModuleId);

      if (!node) { return; }
      node.InnovativeModuleId = gasCompartment.InnovativeModuleId;
      node.InnovativeModuleType = gasCompartment.InnovativeModuleType;
    });
  }

  private _confirmRemovalOfAssets(removedAssets: {equipmentDetails: any, goJSNodeData: any}[], sldImage: string, originalSerializedSld: any): Observable<boolean> {
    return Observable.create(observer => {
      const modalRef = this.modalService.open(RiseToRelcareUnmappedAssetsModalComponent, { centered: true, size: 'lg' });
      modalRef.componentInstance.unmappedAssets = removedAssets;
      modalRef.componentInstance.sldImage = sldImage;
      modalRef.componentInstance.serializedSld = originalSerializedSld;
      modalRef.result.then(modalReturn => {
        observer.next(modalReturn);
        observer.complete();
      });
    });
  }

  private _confirmWhetherReliabilityShouldBeImported(): Observable<boolean> {
    return Observable.create(observer => {
      const modalRef = this.modalService.open(GeneralUserConfirmationModalComponent, { centered: true });
      modalRef.componentInstance.title = 'Include reliability data from RISE';
      modalRef.componentInstance.message = 'It looks like you are importing a substation from RISE application.\n\nWould you like to include reliability data calculated / inputted from RISE in this import?';
      modalRef.componentInstance.confirmationText = 'Yes';
      modalRef.componentInstance.cancelText = 'No, exclude reliability data';
      modalRef.result.then(modalReturn => {
        observer.next(modalReturn);
        observer.complete();
      });
    });
  }

  /**
   * is substation dto from rise
   * check whether the structure of substation matches rise export structure
   * and validate whether the expected properties sld equipment reference, load reference or source reference can be found
   * @param  {any} substation
   */
   public isSubstationDTOFromRise(substation: any) {
    // rise export files have "assetProperties" array
    if (substation.assetProperties && substation.assetProperties.length) {
      // a double check to confirm that there exists a property for SldEquipmentReference that matches
      // the expected rise sld equipment reference property id.
      const hasRiseExpectedProperties = !!substation.assetProperties[0].properties
        .find(prop => this._riseExpectedProperties[prop.propertyId]);

      if (hasRiseExpectedProperties) { return true; }
      // if the structure of substation dto matches Rise structure, but we're unable to find the expected
      // sld equipment reference property, we have no choice but to throw an error
      else throw new Error('Unable to recognize the nature of the substation that is trying to be imported');
    }
    return false;
  }

  /**
   * convert substation dto to relcare
   * perform the entire conversion of property mappings + removal of invalid relcare assets + matching dto of relcare
   * @param  {any} substation (in rise data structure)
   * @returns {any} substation in relcare data structure
   */
  public async convertRiseSubstationDTOToRelcare(substation: any) {
    // just a sanity check to ensure that provided substation is indeed from RISE export file
    if (!this.isSubstationDTOFromRise(substation)) { throw new Error('Expected RISE Substation DTO, but unable to validate the same.'); }

    // RISE has a weird bug in serializedSld where if the asset lies inside a bay, the value of sldRef and sldEqptRef of nodes is set incorrectly
    // the correct value will always be found in `assetSnippetSldReference` however, so we can use that to set the values correctly
    this._fixIncorrectRiseSldReferences(substation);

    // we keep the unedited version of serialized sld as this will be used to map it to the sldImage,
    // in case we need to show the user where any unmapped equipments lie in the sld image
    const originalRiseSerializedSld = JSON.parse(JSON.stringify(substation.serializedSld));

    // remove the text nodes from serialized sld before anything else
    substation.serializedSld.nodes = substation.serializedSld.nodes.filter(node => node.name !== 'Text');
    // rise doesn't seem to have this field, so we just set it to true
    substation.bays.forEach(bay => {
      bay.userValidatedWeight = bay.validatedWeight ? bay.validatedWeight.floatProperty : null;
      bay.isCalculated = !bay.userValidatedWeight;
    });

    const substationId = substation.installationId;
    const { assetIdMap, sldRefMap } = this._extractAllComponentSldData(substation);

    const removedEquipments = [];
    // modify the sld data of each component in substation to the appropriate equipment related ids of relcare
    // i.e. map the equipment related ids from rise to relcare
    Object.keys(assetIdMap).forEach(assetId => {
      const component = assetIdMap[assetId];
      const assetSuccessfullyMapped = this._convertEquipmentIdsForComponentData(component);
      if (!assetSuccessfullyMapped) {
        // store the equipment details of the asset that is going to removed along with goJS Node data
        // these will be shown to the user for confirmation before continuining import
        const equipmentKey = [component.equipmentNameId, component.equipmentCategoryId, component.equipmentTypeId].join(';;');
        const riseEquipmentDetails = this._riseToRelcareEqptMap[equipmentKey].rise;
        const goJSNodeData = substation.serializedSld.nodes.filter(n => n.sldReference === assetIdMap[assetId].sldReference)[0];
        removedEquipments.push({ equipmentDetails: riseEquipmentDetails, goJSNodeData });

        // delete the asset and ideal cables attached to it from substation
        const assetWithIdealCables = this._removeUnmappableRiseAsset(substation, assetId, component.sldReference);
        // delete them from the maps
        assetWithIdealCables.forEach(sldData => {
          delete assetIdMap[sldData.assetId];
          delete sldRefMap[sldData.sldReference];
        });
      }
    });
    // for each of the widget nodes in rise, convert them to relcare format (as there are minor differences)
    substation.serializedSld.nodes.forEach(node => this._convertRiseWidgetTemplateToRelcare(node));

    // user needs to confirm whether the removal of some assets is okay or not before proceeding
    if (removedEquipments.length) {
      const userConfirmsRemovalOfAssets = await this._confirmRemovalOfAssets(removedEquipments, substation.sldImage, originalRiseSerializedSld).toPromise();
      if (!userConfirmsRemovalOfAssets) { throw new Error('Import cancelled by user'); }
    }

    const includeReliability = await this._confirmWhetherReliabilityShouldBeImported().toPromise();

    // tranform the rise snippet properties for all assets to relcare data structure
    const { substationSnippetProperties,  equipmentReference } = this._transformAllRiseAssetSnippetProperties(substation.assetProperties, assetIdMap, substationId, includeReliability);
    // remove the rise asset properties from substation and replace it with the relcare properties
    delete substation.assetProperties;
    Object.assign(substation, { substationSnippetProperties, equipmentReference });

    // transform reliability data from rise to relcare format for all assets in substation
    Object.values(assetIdMap).forEach(component => {
      const parentAndChildComponents = [component];
      if (component.linkedAssetComponent1) { parentAndChildComponents.push(component.linkedAssetComponent1); }
      if (component.linkedAssetComponent2) { parentAndChildComponents.push(component.linkedAssetComponent2); }

      parentAndChildComponents.forEach(sldData => {
        if (!sldData.componentReliabilityData) { return; }
        sldData.componentReliabilityData = this._transformReliabilityDataFromRiseToRecalre(sldData.componentReliabilityData);
      });
    });

    // rise doesn't mark connectedComponents properties in sldData for anything except ideal cable
    // relcare backend however needs these to be defined for all assets
    this._markConnectedComponents(sldRefMap, substation.serializedSld);

    // rise doesn't store innovative module id in gas compartment's node data
    this._fixGasCompartmentNodeData(substation.serializedSld);

    // remove unneceessary properties from sld data
    Object.values(assetIdMap).forEach(component => this._removeUnnecessaryPropertiesFromAssetSldData(component));

    // delete sld data from serializedSld (RISE includes sldData for each node in serializedSld)
    // relcare doesn't do the same (which is probably the right way to do things)
    // and we've already extracted all the info we needed from serializedSld's sldDatas by this point
    substation.serializedSld.nodes.concat(substation.serializedSld.links).forEach(part => delete part.sldData);
    substation.serializedSld.sldReferenceIter += 1

    // delete unnecessary RISE substation properties
    delete substation.installationId;
    delete substation.sldImage;
    delete substation.transferPathResults;
    delete substation.isCalculationsDirty;
    delete substation.serializedSld.areLabelOn;

    // add necessary RELCARE substation properties
    substation.outageAreasList = [];
    substation.facts = [];
    substation.isSubstationInstallationAvailable = false;
    substation.longitude = 0;
    substation.latitude = 0;
    substation.altitude = 0;
    substation.condition = 0;

    return substation;
  }

}
