import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class GisSldStateService {

    constructor() { }

    initialComponents = [];
    substationId: string;
    public setInitialComponentList(components) {
        this.initialComponents = components || [];
    }
    public setSubstationId(id) {
        this.substationId = id;
    }
}