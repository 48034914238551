import { Injectable } from '@angular/core';
import * as d3 from 'd3';

@Injectable()
export class WidgetsService {

    constructor() { }

    ellipsis(text1, length) {
        text1.each(function () {
            var text = d3.select(this);
            var words = text.text().split(/\s+/);

            var ellipsis = text.text('').append('tspan').attr('class', 'elip').text('...');
            // var width = parseFloat(text.attr('width')) - ellipsis.node().getComputedTextLength();
            var width = length;
            var numWords = words.length;

            var tspan = text.insert('tspan', ':first-child').text(words.join(' '));

            // Try the whole line
            // While it's too long, and we have words left, keep removing words

            while (tspan.node().getComputedTextLength() > width && words.length) {
                words.pop();
                tspan.text(words.join(' '));
            }

            if (words.length === numWords) {
                ellipsis.remove();
            }
        });
    }
}
