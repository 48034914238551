import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from 'keycloak-angular';
import { ApplicationService } from 'src/services/application.service';
import { RolesService } from 'src/services/roles.service';
import { LogoutConfirmationModalComponent } from '../modal/logout-confirmation-modal/logout-confirmation-modal.component';
import { ConstService } from 'src/services/const.service';
import { IndexDBService } from 'src/services/indexdb.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-log-out-details',
  templateUrl: './log-out-details.component.html',
  styleUrl: './log-out-details.component.scss'
})
export class LogOutDetailsComponent {
  @Input() openRightPanel; 
  @Output() rightPanelClosed = new EventEmitter();
  loading = false;
  userName: string;
  userRoles: any = [];
  filterRoles: any = [];
  allRoles: any = [
    { 'name': this.rolesService.PROJECT_MANAGER },
    { 'name': this.rolesService.PROJECT_MANAGER_C, 'active': false },
    { 'name': this.rolesService.PROJECT_SUPPORT },
    { 'name': this.rolesService.POLICY_SME  },
    { 'name': this.rolesService.OPERATION_SME },
    { 'name': this.rolesService.REMOTE_OPERATOR },
    { 'name': this.rolesService.EXECUTION_TEAM_LEAD },
    { 'name': this.rolesService.MAINTENANCE_MANAGER, 'active': false },
    { 'name': this.rolesService.EXECUTION_OWNER },
    { 'name': this.rolesService.SYSTEM_MANAGER },
    { 'name': this.rolesService.SYSTEM_SUPPORT },
    { 'name': this.rolesService.TENANT_ADMIN },
    { 'name': this.rolesService.PROJECT_MEMBER },
    { 'name': this.rolesService.POLICY_MANAGER },
    { 'name': this.rolesService.CONTROL_ROOM },
  ];
  offlineUserName: any;
  offlineUserRoles: any;

  constructor(private modalService: NgbModal, public appSvc: ApplicationService,  public router: Router,
    private keycloak :KeycloakService, public rolesService: RolesService,  private indexDbService:IndexDBService,
    private constService :ConstService) {
      this.userName = sessionStorage.getItem('UserName');
      const token = JSON.parse(sessionStorage.getItem('previleges'));
      const roles = token?.roles;
      this.filterRoles = [];

      if (Array.isArray(roles)) {
        roles.forEach(element => {
          this.allRoles.filter(role => {
            if (role.name === element) {
              this.filterRoles.push(role);
            }
          });
        });
      } else {
        this.allRoles.filter(role => {
          if (role.name === roles) {
            this.filterRoles.push(role);
          }
        });
      }
      this.userRoles = this.filterRoles;
    }

    ngOnInit() {
      if(this.router.url.indexOf('/offline') !== -1){
        this.indexDbService.getUsers().subscribe((res:any)=>{
          this.offlineUserName= res[0].name
          this.userRoles= res[0].userRoles.split(',')
        })
      }
    }

  logOut() {
    this.modalService.open(LogoutConfirmationModalComponent, { centered: true }).result.then(ret => {
      if (ret === 'Logout') {
        this.appSvc.logOut().subscribe({next:
          (res) => {
            this.loading = false;
            sessionStorage.setItem('isUserLogin', 'false');
            this.modalService.dismissAll();
            sessionStorage.clear();
            this.appSvc.setUserLogin(false);
            if (this.appSvc.subscription != undefined) {
              this.appSvc.subscription.unsubscribe();
            }
            this.appSvc.setPlanId(this.constService.emptyGuid);
            this.appSvc.setPlannerPlanId(this.constService.emptyGuid);
            this.keycloak.logout(window.location.origin+'/login').then(() => this.keycloak.clearToken());
          }, error: () => {
            this.loading = false;
          }});
      } else {
        return;
      }
    }, () => {
    });
  }

  sendValueBack() {
    this.rightPanelClosed.emit(this.openRightPanel);
  }
  offlineLogout(){
    sessionStorage.clear()
    this.router.navigateByUrl('/offline')
  }


  @HostListener('document:click', ['$event'])
  clickedOutside(event: MouseEvent) {
    if (this.openRightPanel) {
      const targetElement = event.target as HTMLElement;
      const clickedOutside = targetElement.closest('.right-container')
      if (!clickedOutside) {
        this.openRightPanel = false;
        this.rightPanelClosed.emit(this.openRightPanel);
      }
    }
  }
}

