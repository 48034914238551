<div class="modal-container">
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title">{{ title }}</span>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('back')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span class="message" i18n>{{ message }}</span>
  </div>
  <div class="modal-footer">
    <a class="btn btn-cancel" (click)="activeModal.close(false)" i18n>{{ cancelText }}</a>
    <a class="btn btn-sure" (click)="activeModal.close(true)" i18n>{{ confirmationText }}</a>
  </div>
</div>
