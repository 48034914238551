export enum NodeType {
  SubStation,
  Load,
  Source,
  Joint,
}

export enum Interaction {
  Normal,
  Hover,
  Active,
}

export const getNodeType = (index) => {
  if (NodeType.SubStation === Number(index)) {
    return NodeType.SubStation;
  } else if (NodeType.Load === Number(index)) {
    return NodeType.Load;
  } else if (NodeType.Source === Number(index)) {
    return NodeType.Source;
  } else if (NodeType.Joint === Number(index)) {
    return NodeType.Joint;
  } else {
    return NodeType.SubStation;
  }
};