@if (loading) {
  <div class="loading-box">
    <div class="img" alt="loading"></div>
  </div>
}

<div class="page-container">
  <div class="header">
    <header class="title">Select Installation</header>
    <sub class="sub">Select an existing installations from the installed base. Installations that are currently
      under SLA is an open project cannot be added to this project.
    </sub>
    <button type="button" class="close" (click)="closeModal()">
    </button>
  </div>
  <div class="listing">
    <div class="main_div">
      <ng-container ng-diable="disable">
        <div class="search-wrapper">
          <input type='text' class="input-ctrl search-ctrl" [(ngModel)]="filterStr"
            (keyup)="search($event.target.value)" placeholder='Type to search' />
          <div class="margin-top-30" style="padding-right: 3%" style="flex: 1;">
            <csp-common-filter class="filter" [filterOptions]="installMainFilterList"
              [multipleFilterOptions]="installCompFilterList" [totalOptionLength]="installAllFilterListCount"
              [isFullScreen]="true" [isPopup]="filterAPICall" (filterItemEmit)="filterItemEmit($event)"
              [filterStyles]="filterStyles" [filterAPICallType]="'Installations'"
              [projectId]="projectId">
            </csp-common-filter>
          </div>
        </div>
      </ng-container>
      <div class="filter-container" [ngClass]="{'filter-hide':!filterShow}">
        <div class="header">
          <i class="icon icon-filter" aria-hidden="true" (click)="filterShow=!filterShow"
          [ngClass]="{'checked':!filterShow}"></i>
          <span i18n>Column filter</span>
        </div>
        @for (item of filterColumnsInst; track item; let i = $index) {
          <div class="filter-option"
            (click)="item.selected=!item.selected;">
            <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':item.selected}"></i>
            <span>{{item.name}}</span>
          </div>
        }
      </div>
      <ngx-datatable [rows]="installationsData | paginate: configPlan" [count]="configPlan.totalItems"
        [offset]="configPlan.currentPage" [limit]="configPlan.itemsPerPage" (sort)="sort($event)"
        [rowClass]="getRowClass"  (page)="paginateData($event)" [columnMode]="'force'" [rowHeight]="52" (activate)="onRowClick($event)">

        @if (filterColumnsInst[0].selected) {
          <ngx-datatable-column name="Installation" prop='Installation'
            [draggable]="false" [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
              <div class="country-header">
                <span class="td_inst" [ngbTooltip]="row.installation">{{row.installation}}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
        }

        @if (filterColumnsInst[1].selected) {
          <ngx-datatable-column name="Owner" prop='substationOwner'
            [draggable]="false" [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
              <div class="country-header">
                <span class="td_inst" [ngbTooltip]="row.substationOwner">{{row.substationOwner}}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
        }

        @if (filterColumnsInst[2].selected) {
          <ngx-datatable-column name="Operator" prop='SubstationOperator'
            [draggable]="false" [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
              <div class="country-header">
                <span class="td_inst" [ngbTooltip]="row.substationOperator">{{row.substationOperator}}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
        }

        @if (filterColumnsInst[3].selected) {
          <ngx-datatable-column name="Country" prop='Country'
            [draggable]="false" [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
              <div class="country-header">
                <span class="td_inst" [ngbTooltip]="row.country">{{row.country}}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
        }

        @if (filterColumnsInst[4].selected) {
          <ngx-datatable-column name="Location" prop='location'
            [draggable]="false" [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
              <div class="country-header">
                <span class="td_inst" [ngbTooltip]="row.location">{{row.location}}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
        }

        @if (filterColumnsInst[5].selected) {
          <ngx-datatable-column name="Year of installation" prop='YearOFInstallation'
            [draggable]="false" [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
              <div class="country-header">
                <span class="td_inst" [ngbTooltip]="row.yearOFInstallation">{{row.yearOFInstallation}}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
        }

        @if (filterColumnsInst[6].selected) {
          <ngx-datatable-column name="Year of commissioning" prop='YearOfComissioning'
            [draggable]="false" [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
              <div class="country-header">
                <span class="td_inst" [ngbTooltip]="row.yearOfComissioning">{{row.yearOfComissioning}}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
        }

        @if (filterColumnsInst[7].selected) {
          <ngx-datatable-column name="Technology" prop='Technology'
            [draggable]="false" [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
              <div class="country-header" style="margin-left: 35px;">
                <span class="td_inst" [ngbTooltip]="row.technology">{{row.technology}}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
        }

        @if (filterColumnsInst[8].selected) {
          <ngx-datatable-column name="Under SLA" prop='UnderSLA'
            [draggable]="false" [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
              <div class="country-header" style="margin-left: 27px;">
                <span class="td_inst" [ngbTooltip]="row.underSLA">{{row.underSLA}}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
        }

        @if (filterColumnsInst[9].selected) {
          <ngx-datatable-column name="UID" prop='UId'
            [draggable]="false" [resizeable]="false">
            <ng-template ngx-datatable-cell-template let-row="row" let-value="value" let-i="index">
              <div class="country-header">
                <span class="td_inst" style="padding-right: 16px;" [ngbTooltip]="row.installationId">{{row.installationId}}</span>
              </div>
            </ng-template>
          </ngx-datatable-column>
        }

        @if (!filterColumnsInst[0].selected && !filterColumnsInst[1].selected &&
          !filterColumnsInst[2].selected &&!filterColumnsInst[3].selected &&
          !filterColumnsInst[4].selected && !filterColumnsInst[5].selected && !filterColumnsInst[6].selected &&
          !filterColumnsInst[7].selected && !filterColumnsInst[8].selected && !filterColumnsInst[9].selected) {
          <ngx-datatable-column [sortable]="false">
          </ngx-datatable-column>
        }

        <ngx-datatable-footer>
          <ng-template ngx-datatable-footer-template let-rowCount="rowCount" let-pageSize="pageSize"
            let-selectedCount="selectedCount"  let-curPage="curPage"  let-offset="offset"
            let-isVisible="isVisible">
            <div class="page-count"></div>
          </ng-template>
        </ngx-datatable-footer>
      </ngx-datatable>
    </div>
  </div>
</div>
<div class="footer">
  <div class="pagination">
    <pagination-controls id="pagination" (pageChange)="paginateData($event)"
      previousLabel="Previous" nextLabel="Next">
    </pagination-controls>
  </div>
  <button class="btn btn-primary" (click)="closeModal()">Cancel</button>
  <button class="btn btn-primary" [disabled]="!selectEnabled" (click)="selectInstallation($event)">Select Installation</button>
</div>