import {Component, Input} from '@angular/core';
import {get} from 'lodash';

@Component({
  selector: '[icon-severity]',
  templateUrl: './icon-severity.component.html',
  styleUrls: ['./icon-severity.component.scss']
})
export class IconSeverityComponent {

  _mapping = {
    'Low': 'icon-exclamation-low',
    'Medium': 'icon-exclamation-medium',
    'High': 'icon-exclamation-high'
  };

  @Input() alarm: any;

  public getClass(): string {
    return this._mapping[get(this.alarm, 'severity')];
  }
}
