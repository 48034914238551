import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
// import { v4 as uuid }  from 'uuid';
import * as _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import padStart from 'lodash/padStart';
import { ToastrService } from 'ngx-toastr';
import { ApplicationService } from '../../../../../services/application.service';
import * as RouteHelper from 'src/app/utility/routing.helper';

@Component({
  selector: 'app-create-connection-modal',
  templateUrl: './create-connection-modal.component.html',
  styleUrls: ['./create-connection-modal.component.scss']
})
export class CreateConnectionModalComponent implements OnInit {
  @Input() connections = [];
  @Input() isNew = false;
  localConnections = [];
  addNewConnection: any = {};
  name = '';
  oldName = '';
  @Input() types = [];
  userRolesObject: any = [];
  @Input() noOfConnections = 0;
  connectionSwap = false;
  ref: any;

  constructor(
    public activeModal: NgbActiveModal,
    private toastr: ToastrService,
    private router: Router,
    private dataService: ApplicationService) {
  }

  ngOnInit() {
    this.userRolesObject = this.dataService.getUserPrivilages();
    this.localConnections = [...this.connections];
    this.localConnections = _.orderBy(this.localConnections, ['reference'], ['asc']);
    // this.localConnections = _.uniqBy(this.localConnections, 'origin');
    this.addNewConnection = { ...this.connections[0], reference: 'SR-' + padStart(this.noOfConnections + 1, 4, '0') };
    this.name = this.connections[0].name;
    this.oldName = this.connections[0].name;
    this.ref = this.connections[0].reference;
  }

  /**
   * Swap origin and destination
   * @param connection - connection object
   */
  swap(connection) {
    connection.swap = !connection.swap;
    const temp = connection.origin;
    connection.origin = connection.destination;
    connection.destination = temp;
  }

  /**
   * Delete all connections
   */
  deleteAll() {
    let filteredConnections: any = [];
    this.localConnections = this.localConnections.map(function (connection) {
      if (connection.id === '00000000-0000-0000-0000-000000000000') {
        connection.removed = true;
      }
      return connection;
    });
    filteredConnections = this.localConnections.filter(function (value, index, arr) {
      return value.id !== '00000000-0000-0000-0000-000000000000';
    });
    this.activeModal.close({ type: 'delete', data: filteredConnections, noOfConnections: this.noOfConnections });
  }

  /**
   * Remove specific connection
   * @param id - connection id
   */
  remove(id) {
    const deleteConnectionByID: any = [];
    this.localConnections = this.localConnections.map(function (connection) {
      if (connection.id === id) {
        // connection.removed = true;
        deleteConnectionByID.push(connection);
        return connection;
      }
    });
    this.activeModal.close({ type: 'delete', data: deleteConnectionByID, noOfConnections: 1 });
  }

  /**
   * Save connection
   */
  confirm() {

    this.localConnections.forEach((connection) => {
      connection.name = this.name;
      // connection.reference = connection.reference;
      if (connection.new) {
        this.isNew = true;
      }
    });
    if (this.name !== this.oldName) {
      this.toastr.success('Connection name was edited', undefined, { timeOut: 2000 });
    }

    if (this.isNew) {
      this.toastr.success('Connection added', undefined, { timeOut: 2000 });
    } else if (this.name === this.oldName) {
      this.toastr.success('Connection was edited', undefined, { timeOut: 2000 });
    }

    this.activeModal.close({
      type: 'confirm', connectionSwap: this.connectionSwap,
      data: this.localConnections, noOfConnections: this.noOfConnections
    });
  }

  /**
   * close connection
   */
  close() {
    this.activeModal.close({ type: 'close' });
  }

  closeModal() {
    this.activeModal.close({ type: 'closeModal' });
  }
  /**
   * add new connection
   * connection - connection
   */
  add(connection) {
    const newConnection = cloneDeep(connection);
    newConnection.new = true;
    newConnection.name = this.name;
    newConnection.connectionId = this.connections[0].id;
    newConnection.id = '00000000-0000-0000-0000-000000000000';
    this.noOfConnections = this.noOfConnections + 1;
    this.addNewConnection.reference = 'SR-' + padStart(this.noOfConnections + 1, 4, '0');
    this.localConnections.push({ ...newConnection });
  }

  openAssetInfo(connection: any) {
    //debugger;
    sessionStorage.setItem('assetId', connection.assetId);
    sessionStorage.setItem('assetName', 'Overhead transmission line');
    sessionStorage.setItem('reference', connection.reference);
    sessionStorage.setItem('forConnectionLine', 'true');
    this.router.navigate([RouteHelper.addProjectPrefix(`/assets/2`)]); //  + connection.isSubStation ? "1" : "2"
    this.close();
  }

}
