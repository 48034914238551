import { GenericTableModel } from './models/generic-table.model';
import { some } from 'lodash';
import { GenericTableHelper } from './GenericTableHelper';

export class GridSearcher {
  constructor(public configTable: GenericTableModel) {
  }

  public search(searchValue: string, items: any[]): any[] {
    if (!searchValue || !searchValue.trim()) {
      return items;
    }

    const lowerSearchValue = searchValue.toLowerCase();

    var result = items
      .filter(item => some(this.configTable.columns,
        column => {
          const value = GenericTableHelper.getValue(column, item);
          if (typeof value !== "string" || column.notSearchable || value === null || value === undefined) {
            return false;
          }
          return value.toLowerCase().indexOf(lowerSearchValue) !== -1
        }));

    return result;
  }
}
