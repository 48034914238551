import { Injectable } from '@angular/core';
import { ManageProjectService } from './manageProject.service';
import { BaseDictionaryItem } from '../app/models/base-dictionary-item';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QrCodeService {
  constructor(private manageProjectHelper: ManageProjectService) {

  }  

  getQRCodeJson(projectId: string): Observable<Array<BaseDictionaryItem<string>>> {
    var url = '/Project/GetProjectMobileConfiguration?projectId='+projectId;
    return this.manageProjectHelper.get(url);
  }

}