import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import {
  FormatEvent,
  GenericColumn,
  GenericTableModel,
  Paginate,
  PaginationConfig,
  RowFactoryEvent,
  RowHoverEvent,
} from './models/generic-table.model';
import moment from 'moment';
import { GenericTableHelper } from './GenericTableHelper';
import { LocalPaginationConfig } from './local-pagination-config';

@Component({
  selector: 'app-generic-table',
  templateUrl: './generic-table.component.html',
  styleUrls: ['./generic-table.component.scss'],
})
export class GenericTableComponent implements OnInit, AfterViewInit {
  @ViewChild('tableWrapper', { static: false }) tableWrapper;

  ngOnInit(): void {
    this._paginationConfig.onInit(this);
  }

  ngAfterViewInit(): void {
    console.log(this.tableWrapper)
  }

  private _configTableModel: GenericTableModel;
  @Input()
  set configTableModel(value: GenericTableModel) {
    this._configTableModel = value;
    this._paginationConfig = this._configTableModel.externalPaginationConfig || new LocalPaginationConfig();
  }

  @Input() showLoadingSpinner = false;

  get configTableModel(): GenericTableModel {
    return this._configTableModel;
  }

  _items: any[] = [];

  @Input() set items(value: any[]) {
    this._items = value;
    this._paginationConfig.onReceivedNewItems(this);
  }

  get items(): any[] {
    return this._items;
  }

  public setItems(value: any[]) {
    this._items = value;
  }

  @Output() onRowHover = new EventEmitter<RowHoverEvent>();

  @Input() itemsPerPage = 10;

  @Input() autoHidePager = false;
  private _paginationConfig: PaginationConfig;

  public sort(column: GenericColumn): void {
    this._paginationConfig.sort(column, this);
  }

  public getInternalFormattedValue(column: GenericColumn, item: any): any {
    const value = GenericTableHelper.getValue(column, item);

    if (moment(value).isValid() &&
     (column.name == "ReceivedTs" || column.name == "UserAcknowledgedTs" || column.name == "AcknowledgedAtStationTs")) {
      return moment.utc(value).local().format('Do MMM YYYY - HH:mm');
    }

    return value;
  }

  public getFormattedValue(
    column: GenericColumn,
    item: any,
  ): any {
    return column.formatFn
      ? column.formatFn(this.buildFormatEvent(column, item))
      : this.getInternalFormattedValue(column, item);
  }

  public getVisibleColumns(): GenericColumn[] {
    return this._configTableModel.columns.filter((x) => !x.hidden);
  }

  public getPaginateConfig(): Paginate {
    return this._paginationConfig.getPaginateConfig(this);
  }

  public pageChanged(event: number) {
    this._paginationConfig.pageChanged(this, event);
  }

  public calculateBeforeCustomRow(
    item: any,
  ): TemplateRef<any> | null {
    return this._configTableModel.customBeforeRowFactoryFn
      ? this._configTableModel.customBeforeRowFactoryFn(
        this.buildRowFactoryEvent(item)
      )
      : null;
  }

  public calculateAfterCustomRow(
    item: any,
  ): TemplateRef<any> | null {
    return this._configTableModel.customAfterRowFactoryFn
      ? this._configTableModel.customAfterRowFactoryFn(
        this.buildRowFactoryEvent(item)
      )
      : null;
  }

  private buildRowFactoryEvent(item: any): RowFactoryEvent {
    return {
      item,
      index: this.calculateIndex(item),
      items: this._items,
      configTableModel: this._configTableModel,
      paginate: this.getPaginateConfig(),
    };
  }

  public getTooltip(column: GenericColumn, item: any) {
    return column.tooltipFn
      ? column.tooltipFn(this.buildFormatEvent(column, item))
      : this.getInternalFormattedValue(column, item);
  }

  private buildFormatEvent(
    column: GenericColumn,
    item: any,
  ): FormatEvent {
    return {
      column,
      item,
      configTableModel: this._configTableModel,
      paginate: this.getPaginateConfig(),
      items: this._items,
      index: this.calculateIndex(item),
      defaultFormatter: () => this.getInternalFormattedValue(column, item),
    };
  }

  private calculateIndex(item: any): number {
    return this._items.indexOf(item);
  }

  public onRowMouseEnter(item: any) {
    this.onRowHover.emit({ item });
  }

  public onRowMouseLeave() {
    this.onRowHover.emit({});
  }

  triggerLoadingData() {
    this._paginationConfig.triggerLoadingData(this);
  }
}
