import { Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { NgbModalConfig } from "@ng-bootstrap/ng-bootstrap";
import moment from "moment";
import { ApplicationService } from "src/services/application.service";
import { FileSaverService } from 'ngx-filesaver';
import { ThemePalette } from "@angular/material/core";

@Component({
    selector: 'app-audit-logs',
    templateUrl: './audit-logs.component.html',
    styleUrls: ['./audit-logs.component.scss']
  })
export class AuditLogsComponent implements OnInit {
  @ViewChild('picker') pickerStart: any;
  @ViewChild('picker') pickerEnd: any;
  filterColumn = [
    { prop: 'date', name: 'Date', selected: true },
    { prop: 'time', name: 'Time', selected: true },
    { prop: 'substation', name: 'Substation Name', selected: true },
    { prop: 'moduleName', name: 'Module Name', selected: true },
    { prop: 'userRole', name: 'User Role', selected: true },
    { prop: 'userName', name: 'User ID', selected: true },
    { prop: 'activity', name: 'Activity', selected: true },
    { prop: 'description', name: 'Detail Description', selected: true }
    ];
  panelOpenState = false;
  filterShow = false;
  minDate = null;
  config : any;
  SortOrder: string = "desc";
  sortBy: string = "dateTime";
  sortDirection = true;
  loading = false;
  allLogs? : any;
  startDateTime;
  endDateTime;
  filterStr : string = "";
  isDownloaded = false;
  isInvalid = false;
  start_dt = new FormControl(new Date(), Validators.required);
  end_dt = new FormControl(new Date(), Validators.required);
  showSpinners = true;
  showSeconds = false;
  touchUi = false;
  stepHour = 1;
  stepMinute = 1;
  stepSecond = 1;
  color : ThemePalette = 'primary';
  enableMeridian = false;
  disableMinute = false;
  hideTime = false;
  currentDateTime : Date;

      constructor(
        private appSvc: ApplicationService,
        config: NgbModalConfig,
        private _FileSaverService: FileSaverService) {
        config.backdrop = 'static';
        this.config = {
          id:"pagination1",
          currentPage: 1,
          itemsPerPage: 10,
          totalItems: 0
        };
      }
    ngOnInit() {
      this.getAuditLogs();  
    } 

  downloadAuditExcelFile(auditExcelFile) {
    this.loading = true;
    this.appSvc.downloadAuditExcelFile(auditExcelFile).subscribe({
      next: response => {        
        const blob = new Blob([response]);
        this._FileSaverService.save(blob, auditExcelFile);
        this.loading = false;
      }, error: () => {
        this.loading = false;
      }
    });
  }
  
  getAuditLogs() {
    this.loading = true;
    this.appSvc.getAuditLogs(this.config.currentPage, this.config.itemsPerPage, this.sortBy, this.SortOrder, this.filterStr).subscribe({
      next: res => {
        this.loading = false;
        this.allLogs = res.returnObj;
        this.allLogs.forEach(element => {
          if (element.substationName === "" || element.substationName === null) {
            element.substationName = 'NA';
          }
          if (element.isJson) {
            element.detailsJSON = JSON.parse(element.detailsDescription);

            }
            element.dateTime = moment.utc(element.dateTime).local().toDate();
            element.time = moment.utc(element.dateTime).format('hh : mm : ss');
            element.dateTime = moment(element.dateTime).format('YYYY-MM-DD');   
          });
          this.config.totalItems = res.totalNoOfObjects;
        }, error:() => {
            this.loading = false;
          }
        });
    }

    downLoadFile(data: any, type: string) {
      const blob = new Blob([data], { type: type.toString() });  
      this.currentDateTime = new Date();
      let fileName = moment(this.currentDateTime).format('DD-MM-YYYY HH:mm:ss') + '.xlsx';
      this._FileSaverService.save(blob, fileName);
  }

    downloadAuditLogs(start, end) {  
      this.isDownloaded = true;
      if(this.isInvalid) {
        return;
      } else if(this.start_dt.status === 'VALID' && this.end_dt.status === 'VALID') {
        start = moment.utc(this.startDateTime).local().toDate();    
        start = moment.utc(this.startDateTime).format('YYYY-MM-DD HH:mm:ss'); 
        end = moment.utc(this.endDateTime).local().toDate();      
        end = moment.utc(this.endDateTime).format('YYYY-MM-DD HH:mm:ss');      
        this.appSvc.downloadLogs(start, end).subscribe({next: (respData) => {
          this.downLoadFile(respData, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
          this.loading = false;
        }, error: () => {
          this.loading = false;
          }});
      }
    }
    
    sort(field) {
      if (this.sortBy === field) {
        this.sortDirection = !this.sortDirection;
      } else {
        this.sortDirection = true;               
      }
      this.sortBy = field;
      this.getAuditLogs();
      this.updateSortDirection();
    }

    updateSortDirection() {
      if(this.SortOrder === 'asc') {
        this.SortOrder = 'desc';
      } else if(this.SortOrder === 'desc') {
        this.SortOrder = 'asc';
      }
    }

    search(str : string) {
      this.loading = true;
      this.filterStr = str;
      this.appSvc.searchAuditLogs(1,10, str).subscribe({next:res => {
        this.loading = false;
        this.allLogs = res.returnObj;
        res.returnObj.forEach(element => {
          element.dateTime = moment.utc(element.dateTime).local().toDate();
          element.time = moment.utc(element.dateTime).format('hh : mm : ss');
          element.dateTime = moment(element.dateTime).format('YYYY-MM-DD');   
        });
        this.config.totalItems = res.totalNoOfObjects;
        this.config.currentPage = 1;
      }, error:() => {
          this.loading = false;
        }
      }); 
    }

    paginateData(newPage:number) {
      this.config.currentPage = newPage;
      this.getAuditLogs();
    }

    validation(name: FormControl): any {
      this.isInvalid = this.isDownloaded && name.errors && name.errors.required;
      return this.isInvalid;
    }

    rangeValidation(): any {
      if(!this.isInvalid) {
        if(new Date(this.startDateTime) > new Date(this.endDateTime)) {
          return true;
        } else {
          return false;
        }
      }
    }
}