import { Component, Input } from '@angular/core';
import { GenericColumn as GenericColumn } from '../models/generic-table.model';

@Component({
  selector: 'app-column-filter',
  templateUrl: './column-filter.component.html',
  styleUrls: ['./column-filter.component.scss']
})
export class ColumnFilterComponent {
  @Input() columns: GenericColumn[];

  public show = false;

  constructor() { }

  public toggle() {
    this.show = !this.show
  }

  public getConfigurableColumns(): GenericColumn[] {
    return this.columns?.filter(x => !x.disableHiding);
  }

  public toggleHide(column: GenericColumn) {
    column.hidden = !column.hidden;
  }
}
