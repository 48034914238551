import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';

@Injectable()
export class MainHeaderService {
  // Observable navItem source
  private _navItemSource = new BehaviorSubject<number>(0);
  private _selectedRole = new BehaviorSubject<string>('');
  private _rolesUpdated = new Subject<void>();
  private _assessReliabilityUpdated = new Subject<void>();
  // Observable navItem stream
  navItem$ = this._navItemSource.asObservable();
  selectedRole$ = this._selectedRole.asObservable();
  rolesUpdated$ = this._rolesUpdated.asObservable();
  assessReliabilityUpdated$ = this._assessReliabilityUpdated.asObservable();
  // service command
  changeNav(number) {
    this._navItemSource.next(number);
  }
  // service command
  changeSelectedRole(string){
    this._selectedRole.next(string);
  }

  onRolesUpdated() {
    this._rolesUpdated.next();
  }
  onAssessReliabilityUpdated(){
    this._assessReliabilityUpdated.next();
  }
}
