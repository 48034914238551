import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QrCodeService } from 'src/services/qr-code.service';

@Component({
  selector: 'app-mobile-qr-code',
  templateUrl: './mobile-qr-code.component.html',
  styleUrls: ['./mobile-qr-code.component.scss']
})
export class MobileQrCodeComponent implements OnInit {
    value = "";
    projectId: string = "";

    constructor(private qrCodeService: QrCodeService, private route: ActivatedRoute) {
        this.projectId = sessionStorage.getItem('projectId');//this.route.snapshot.paramMap.get('projectId');
    }

    ngOnInit(): void {
        this.qrCodeService.getQRCodeJson(this.projectId).subscribe((res: any) => {
            if(res?.returnObj?.item2){
                this.value = res?.returnObj?.item2;
            }
        });
        
    }
}