
<div class="details" >
  <ul class="nav-tabs">
    <li>
      <button class="projectsbtn">Projects</button>
    </li>
    <li>
      <button disabled>Installations</button>
    </li>
  </ul>


  <div class="container-1">
    @for( project of projects ;track project){
  <span class="heading">
      <div class="hegi">{{ project?.projectName }}</div>

      <div class="proj-details">
        <div class="location">
          <div>Location</div>
          <div>{{ project.location }}</div>
        </div>
        <div class="tech">
          <div>Technology</div>
          <div>{{ project.technology }}</div>
        </div>
        <div class="installations">
          <div>Total Installations</div>
          <div>{{ project.totalInstallations }}</div>
        </div>
        <div class="tasks">
          <div>Open Tasks</div>
          <div>{{ project.openTaskCount}}</div>
        </div>
        <button type="button" class="btn btn-primary" (click)="onProjectSelected(project)">
          Go to project
        </button>
      </div>
    </span>
  }
  </div>