import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-add-new',
  templateUrl: './add-new.component.html',
  styleUrls: ['./add-new.component.scss']
})
export class AddNewComponent {
  @Input() type = 'default';
  @Input() isPlanning;
}
