import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../../../services/notification.service';
import { ApplicationService } from './../../../../services/application.service';
import { SuccesspopupComponent } from 'src/app/containers/dashboard/modals/successpopup/successpopup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GisSldStateService } from 'src/services/gis-sld-state.service';
import { MainHeaderService } from '../main-header.service';
import * as RouteHelper from 'src/app/utility/routing.helper';
@Component({
  selector: 'app-notification-item',
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.scss']
})
export class NotificationItemComponent implements OnInit, OnChanges {
  @ViewChild('notificationItem', { static: false }) notificationItem: ElementRef;
  @ViewChild('circle', { static: false }) circle: ElementRef;
  @Input() notification;
  @Input() isMarkAllRead = false;
  @Output() clickNotification: EventEmitter<any> = new EventEmitter();



  constructor(
    private router: Router,
    private notificationService: NotificationService,
    private appSvc: ApplicationService,
    private modalService: NgbModal,
    private gisSldStateService: GisSldStateService,
    private mainHeader: MainHeaderService
  ) {
  }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {

  }

  handleClick(id) {
    if (this.notification.isRead) {
      if (this.notification.type.toLowerCase() === 'substation') {
        this.clickNotification.emit({
          notification: this.notification,
          isRead: this.notification.isRead
        });
      }
      //return;
    }

    this.notificationService.toggleNotificationReadStatus(id, !this.notification.isRead).subscribe(() => {
      if (!this.notification.isRead) {
        this.circle.nativeElement.className = 'circle';
        this.notification.isRead = true;
      } else {
        this.circle.nativeElement.className = 'circle unread';
        this.notification.isRead = false;
        for (let task of this.notification.taskIds)
          task.isRead = false;
      }

      this.clickNotification.emit({
        notification: this.notification,
        isRead: this.notification.isRead
      });


      if (this.notification.type.toLowerCase() === 'performance') {
        if (this.notification.substationIds.length === 0) {
          const modalRef = this.modalService.open(SuccesspopupComponent, { centered: true });
          modalRef.componentInstance.title = 'Message';
           modalRef.componentInstance.message = 'Asset not assigned to this notification.';
        } else if (this.notification.subtype === 'Asset risks') {
          // this.gisSldStateService.setSubstationId(this.notification.substationIds[0].);
          sessionStorage.setItem('substationId', this.notification.substationIds[0].id);
          this.appSvc.notificationId = this.notification.notificationIds[0];
          let queryData = this.notification.subtype.toLowerCase() === 'asset risks' ? {
            queryParams: { fromNotification: true }
          } : {};
          this.router.navigate([RouteHelper.addProjectPrefix(`/sld-editor/true/${this.notification.substationIds[0].id}`)], queryData);
      }
      else {
        this.router.navigate([RouteHelper.addProjectPrefix(`/reliability-center/information-explorer`)]);
      }
        // this.router.navigate(['/sld-editor/true']);
      }
      else if (this.notification.type.toLowerCase() === 'historization') {
        let isExport = this.notification.subtype.toLowerCase() == 'isexport' ? true: false;
        this.router.navigate([RouteHelper.addProjectPrefix(`/importorexportComponentData`)], {queryParams:{substationId:this.notification.substationIds[0].id, isExport: isExport, type: 'historicalData'}});
      }
      else if (this.notification.type.toLowerCase() === 'task') {
        let categoryName = this.notification.categoryName;
        let roleType = sessionStorage.getItem('UserRoles');

        if(this.notification.categoryName === 'Validated'){
          categoryName = 'Under validation';
        }
        if(this.notification.categoryName === 'Cancel'){
          categoryName = 'Cancelled';
        }
        this.appSvc.notificationId = this.notification.notificationIds[0];
        if (roleType.includes('Execution Team Lead')) {
          this.mainHeader.changeSelectedRole('Execution Team Lead');
          this.router.navigate([RouteHelper.addProjectPrefix(`/execution/landing/teamLead`)], { queryParams: { category: categoryName, categoryId: this.notification.categoryId } });
        } else if(roleType.includes('Execution Owner')){
          this.mainHeader.changeSelectedRole('Execution Owner');
          this.router.navigate([RouteHelper.addProjectPrefix(`/execution/landing/ExecutionOwner`)], { queryParams: { category: categoryName, categoryId: this.notification.categoryId, priority: null } });
        }
      }
      else if (this.notification.type.toLowerCase() === 'plan import' || this.notification.type.toLowerCase() === 'plan export') {
        let isExport = this.notification.subtype.toLowerCase() == 'plan export' ? true: false;
        this.router.navigate([RouteHelper.addProjectPrefix(`/importorexportComponentData`)], {queryParams:{Id:this.notification.planId, isExport: isExport, type: 'planexcelreport'}});
      }
    });
  }
}
