import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import * as RouteHelper from 'src/app/utility/routing.helper';

@Component({
  selector: 'app-successpopup',
  templateUrl: './successpopup.component.html',
  styleUrls: ['./successpopup.component.scss']
})
export class SuccesspopupComponent implements OnInit {
  @Input() message;
  @Input() isSubstation;
  @Input() title: string;

  constructor(public activeModal: NgbActiveModal, config: NgbModalConfig, private router: Router) {
    config.backdrop = 'static';
  }

  ngOnInit() {
  }
  close() {
    if (this.router.url.indexOf('/resetPassword') === 0) {
      this.router.navigate(['/login'])
      this.activeModal.dismiss('close');
    }
    else if (!this.isSubstation) {
      this.activeModal.dismiss('close');
    } else {

      this.router.navigateByUrl(RouteHelper.addProjectPrefix(`/dashboard/project-modelling`), { skipLocationChange: true }).then(() =>
        this.router.navigate([RouteHelper.addProjectPrefix(`/project-modelling/1`)]));
      this.activeModal.dismiss('close');
    }

  }
}
