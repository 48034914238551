<div class="modal-container">
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title">{{title}}</span>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('back')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    @if (loading) {
      <div class="loading-box">
        <div class="img" alt="loading"></div>
      </div>
    }
    <div class="all-roles-list">
      <app-role-card [allRoles]="roles"></app-role-card>
    </div>
  </div>
  <div class="modal-footer">
    <a class="btn btn-save" [class.disabled]="selectedItemCount()<=0" (click)="save()" i18n>Save</a>
  </div>
</div>