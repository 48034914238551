<div [class.line-chart-wrapper]="showTitleArea" class="line-chart-container">

  @if (showTitleArea) {
    <div class="title">
      <h1>{{settings.title}} (&#8486;) by years</h1>
      <div class="settings" [class.open]="settingsPopup">
        <a (click)="settingsPopup = !settingsPopup;" href="javascript:;"><i class="icon icon-setting" aria-hidden="true"></i></a>
        @if (settingsPopup) {
          <div class="setting-popup p-3">
            <h2>Chart customisation</h2>
            <div class="inner">
              <div class="row">
                <div class="col-md-12">
                  <h3 class="d-flex flex-row align-items-center">
                    <span class="mr-3 d-flex flex-grow-0 text-nowrap">Y axis</span>
                    <span class="border-bottom d-flex w-100"></span>
                  </h3>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label>Chart title</label>
                  <select (change)="renderChart()" [(ngModel)]="settings.title" type="text">
                    <option value="NGR Resistance test">NGR Resistance test</option>
                    <option value="NGR Resistance test2">NGR Resistance test2</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label>Custom Label</label>
                  <input [(ngModel)]="settings.yCustomLabel" type="text"/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label>Min value</label>
                  <input (change)="renderChart()" [(ngModel)]="settings.yMin" type="number"/>
                </div>
                <div class="col-md-6">
                  <label>Max value</label>
                  <input (change)="renderChart()" [(ngModel)]="settings.yMax" type="number"/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <h3 class="d-flex flex-row align-items-center">
                    <span class="mr-3 d-flex flex-grow-0 text-nowrap">X axis</span>
                    <span class="border-bottom d-flex w-100"></span>
                  </h3>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label>Chart title</label>
                  <select (change)="renderChart()" [(ngModel)]="settings.chartTitle" type="text">
                    <option value="Year">Year</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label>Custom label</label>
                  <input (change)="renderChart()" [(ngModel)]="settings.xCustomLabel" type="text"/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label>Min value</label>
                  <input (change)="renderChart()" [(ngModel)]="settings.xMin" type="number"/>
                </div>
                <div class="col-md-6">
                  <label>Max value</label>
                  <input (change)="renderChart()" [(ngModel)]="settings.xMax" type="number"/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <h3 class="d-flex flex-row align-items-center">
                    <span class="mr-3 d-flex flex-grow-0 text-nowrap">Line Segment</span>
                    <span class="border-bottom d-flex w-100"></span>
                  </h3>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label>Chart title</label>
                  <select (change)="renderChart()" [(ngModel)]="settings.lineSegmentTitle" type="text">
                    <option value="Actual">Actual</option>
                  </select>
                </div>
                <div class="col-md-6">
                  <label>Custom label</label>
                  <input (change)="renderChart()" [(ngModel)]="settings.segmentCustomLabel" type="text"/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <label>Lower limit line</label>
                  <input (change)="renderChart()" [(ngModel)]="settings.segmentLowerLimit" type="number"/>
                </div>
                <div class="col-md-6">
                  <label>Uper limit line</label>
                  <input (change)="renderChart()" [(ngModel)]="settings.segmentUpperLimit" type="number"/>
                </div>
              </div>
              @for (item of settings.yAxis; track item; let idx = $index) {
                <div class="row">
                  <div class="col-md-12">
                    <h3 class="d-flex flex-row align-items-center">
                      <span class="mr-3 d-flex flex-grow-0 text-nowrap">Line Segment</span>
                      <span class="border-bottom d-flex w-100"></span>
                    </h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <label>Chart title</label>
                    <div class="axis-input">
                      <div class="color" (click)="item.open = true;" [style.backgroundColor]="item.color">
                        @if (item.open) {
                          <div class="color-pallet">
                            @for (c of colors; track c) {
                              <div class="color-dots"
                                [class.active]="c === item.color"
                                (click)="selectColor($event, item, c)"
                              [style.backgroundColor]="c"></div>
                            }
                          </div>
                        }
                      </div>
                      <div class="value">{{item.value}}</div>
                      @if (settings.yAxis.length > 1) {
                        <a (click)="settings.yAxis.splice(idx, 1); renderChart()" href="javascript:;" class="close"><i
                        class="icon icon-remove" aria-hidden="true"></i></a>
                      }
                    </div>
                  </div>
                  <div class="col-md-6">
                    <label>Custom label</label>
                    <input (change)="renderChart()" [(ngModel)]="item.label" type="text"/>
                  </div>
                </div>
              }
              <!-- @if (getYAxisValues().length > 0) {
                <div class="row">
                  <div class="col-md-12">
                    <h3 class="d-flex flex-row align-items-center">
                      <span class="mr-3 d-flex flex-grow-0 text-nowrap">Line Segment</span>
                      <span class="border-bottom d-flex w-100"></span>
                    </h3>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    @if (getYAxisValues().length > 0) {
                      <div [class.watermark]="selectedComboAxis === ''">
                        @if (getYAxisValues().length > 0) {
                          <select placeholder="Select to add more"
                            [(ngModel)]="selectedYAxis">
                            <option></option>
                            @for (item of getYAxisValues(); track item) {
                              <option [value]="item">{{item}}</option>
                            }
                          </select>
                        }
                        @if (selectedComboAxis === '') {
                          <span>Select to add more</span>
                        }
                      </div>
                    }
                  </div>
                </div>
              } -->
            </div>
          </div>
        }
      </div>
    </div>
  }
  <div class="legends justify-content-start align-items-center">

    @if (settings) {
      <div>
        @for (item of settings.yProperties; track item; let idx = $index) {
          <div class="legend-item align-items-center mr-3">
            <span class="legend-border" [style.border-color]="item.color"></span>
            <span class="label">{{ item.name || item.label }}</span>
          </div>
        }
      </div>
    }
  </div>
  <div class="line-chart" #resistanceChart ></div>

</div>
