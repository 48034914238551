<div class="modal-container">
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title">{{ title }}</span>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('back')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    @if (messageHtml) {
      <span class="message" i18n [innerHTML]="messageHtml"></span>
    }
    @else {
      <span class="message" i18n>{{ message }}</span>
    }
  </div>
  <div class="modal-footer">
    @if (cancelText) {
      <a class="btn btn-secondary" (click)="activeModal.close(false)" i18n>{{ cancelText }}</a>
    }
    <a class="btn btn-primary" (click)="activeModal.close(true)" i18n>{{ confirmationText }}</a>
  </div>
</div>

