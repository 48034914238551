import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cancel-assessReliability-modal',
  templateUrl: './cancel-assessReliability-modal.html',
  styleUrls: ['./cancel-assessReliability-modal.scss']
})
export class CancelAssessReliabilityModalComponent {
  @Input() substationName;
  constructor(public activeModal: NgbActiveModal) { }

  submit(response) {
    this.activeModal.close(response);
  }
}
