import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA, isDevMode } from '@angular/core';
import { DatePipe, LocationStrategy, HashLocationStrategy, PathLocationStrategy, APP_BASE_HREF } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { MyInterceptor } from '../services/token-interceptor.service';
import { HelperService } from '../services/helper.service';
import { BrowserWindowRef } from '../services/window.service';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { ManageProjectService } from '../services/manageProject.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LoginComponent } from './containers/login/login.component';
import { MainHeaderComponent } from './components/main-header/main-header.component';
import { MainFooterComponent } from './components/main-footer/main-footer.component';
import { UserConfirmationComponent } from './containers/user-confirmation/user-confirmation.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReliabilityCenterComponent } from './containers/reliability-center/reliability-center.component';
import { DragulaModule } from 'ng2-dragula';
import * as go from 'gojs';
import { EncrDecrService } from '../services/EncrDecrService';
import { MainHeaderService } from './components/main-header/main-header.service';
import { DashboardService } from './containers//dashboard/dashboard.service';
import { NgxImageCompressService } from 'ngx-image-compress';
import { AlarmItemComponent } from './components/main-header/alarm-item/alarm-item.component';
import { NotificationItemComponent } from './components/main-header/notification-item/notification-item.component';
import { NotificationSettingsComponent } from './components/main-header/modal/notification-settings/notification-settings.component';
import { LogoutConfirmationModalComponent } from './components/main-header/modal/logout-confirmation-modal/logout-confirmation-modal.component';
import { SuccesspopupComponent } from './containers/dashboard/modals/successpopup/successpopup.component';
import { GeneralUserConfirmationModalComponent } from './containers/dashboard/modals/general-user-confirmation-modal/general-user-confirmation-modal.component';
import { PopupUserConfirmationModalComponent } from './containers/dashboard/modals/popup-user-confirmation-modal/popup-user-confirmation-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { AddSubystemModalComponent } from './containers/dashboard/modals/add-subystem-modal/add-subystem-modal.component';
import { SettingsHttpService } from 'src/services/settings.http.service';
import { ExportSubstationDataComponent } from './containers/export-substation-data/export-substation-data.component';
import { PopUpService } from 'src/services/popUp.service';
import { AlarmNotificationSettingsComponent } from './components/main-header/modal/alarm-notification-settings/alarm-notification-settings.component';
import { SharedModule } from './components/shared.module';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouteReuseStrategy } from '@angular/router';
import { AppRouteReuseStrategy } from './app-route-reuse-strategy';
import { WidgetsService } from './components/dashboard/widgets/edit-widget-dashboard/services/widgets.service';
import { AlertModalComponent } from './containers/alert-modal/alert-modal.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { KeycloakAngularModule , KeycloakService} from 'keycloak-angular';
import { initializeKeycloak } from '../app/utility/app.init';
import { BnNgIdleService } from 'bn-ng-idle';
import { ReLoginOpcScenerioComponent } from './containers/re-login-opc-scenerio/re-login-opc-scenerio.component';
import { SldUtilService } from './containers/sld-editor/services/util.service';
import { TenantAdminComponent } from './containers/tenant-admin/tenant-admin.component';
import { AddNewTenantComponent } from './containers/add-new-tenant/add-new-tenant.component';
import { AuditLogsComponent } from './containers/audit-logs/audit-logs.component';
import { AddEditProjectComponent } from './containers/add-edit-project/add-edit-project.component';
import { ProjectDashboardComponent } from './containers/project-dashboard/project-dashboard.component';
import { DirectivesModule } from 'src/app/directive/directives.module';
import { MobileQrCodeComponent } from './containers/mobile-qr-code/mobile-qr-code.component';
import { QrCodeService } from 'src/services/qr-code.service';
import { InstalledBaseComponent } from './containers/installed-base/installed-base.component';
import { OperationsCenterComponent } from './containers/operations-center/operations-center.component';
import { OpCenterService } from 'src/services/op-center.service';
import { InstallationTableComponent } from './components/installation-table/installation-table.component';
import { FileSaverModule } from 'ngx-filesaver';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatInputModule } from '@angular/material/input';
import { QRCodeModule } from 'angularx-qrcode';
import { PerfectScrollbarModule } from './components/perfect-scrollbar-wrapper/perfect-scrollbar.module';
import { UrlNotFoundComponent } from './containers/url-not-found/url-not-found.component';
import { HomePageComponent } from './containers/home-page/home-page.component';
import { LogOutDetailsComponent } from './components/main-header/log-out-details/log-out-details.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { OfflineLandingComponent } from './containers/offline-landing/offline-landing.component';
import { IndexdbPasswordModalComponent } from './components/indexdb-password-modal/indexdb-password-modal.component';
import { NotificationAssessReliabilityComponent } from './components/main-header/notification-assessReliability/notification-assessReliability.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

export function app_Init(settingsHttpService: SettingsHttpService) {
  return () => settingsHttpService.initializeApp();
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UrlNotFoundComponent,
    MainHeaderComponent,
    MainFooterComponent,
    AddEditProjectComponent,
    UserConfirmationComponent,
    ReliabilityCenterComponent,
    NotificationItemComponent,
    AlarmItemComponent,
    NotificationSettingsComponent,
    LogoutConfirmationModalComponent,
    SuccesspopupComponent,
    GeneralUserConfirmationModalComponent,
    PopupUserConfirmationModalComponent,
    AddSubystemModalComponent,
    ExportSubstationDataComponent,
    AlarmNotificationSettingsComponent,
    ReLoginOpcScenerioComponent,
    TenantAdminComponent,
    AddNewTenantComponent,
    AuditLogsComponent,
    AlertModalComponent,
    ProjectDashboardComponent,
    MobileQrCodeComponent,
    InstalledBaseComponent,
    OperationsCenterComponent,
    InstallationTableComponent,
    HomePageComponent,
    LogOutDetailsComponent,
    OfflineLandingComponent,
    IndexdbPasswordModalComponent,
    NotificationAssessReliabilityComponent,
  ],
  imports: [
    NgxJsonViewerModule,
    MatProgressBarModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatDatepickerModule,
    PerfectScrollbarModule,
    NgxPaginationModule,
    BrowserModule,
    AppRoutingModule,
    DirectivesModule,
    ReactiveFormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    NgbModule,
    NgSelectModule,
    FormsModule.withConfig({ callSetDisabledState: 'whenDisabledForLegacyCode' }),
    HttpClientModule,
    CarouselModule,
    BrowserAnimationsModule,
    KeycloakAngularModule,
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      closeButton: true,
      enableHtml: true,
      timeOut: 20000,
      preventDuplicates: true,
      maxOpened: 1
    }),
    SharedModule,
    DragulaModule.forRoot(),
    QRCodeModule,
    FileSaverModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    MatInputModule,
    NgxMatNativeDateModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    SldUtilService,
    HelperService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService,SettingsHttpService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyInterceptor,
      multi: true
    },
    BnNgIdleService,
    BrowserWindowRef,
    MainHeaderService,
    OpCenterService,
    DashboardService,
    HelperService,
    ManageProjectService,
    EncrDecrService,
    DatePipe,
    NgxImageCompressService,
    SettingsHttpService,
    PopUpService,
    WidgetsService,
    QrCodeService,
    { provide: LocationStrategy, useClass: HashLocationStrategy, useValue: 'en' },
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: APP_BASE_HREF, useValue: ''}
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA]
})
export class AppModule {
  constructor(private windowRef: BrowserWindowRef) {
    // set global function to get current substation
    windowRef.nativeWindow['getSubstation'] = () => {
      const rawSubstation = localStorage.getItem('substation');
      if (!rawSubstation) { return {}; }

      let substation;
      try {
        substation = JSON.parse(rawSubstation);
      } catch (e) {
        substation = {};
      }
      return substation;
    };
    // GoJS License Key Added
    (go as any).Diagram.licenseKey =
      '73f943e3ba6e28a800ca0d2b113f69ef1bb37f3b9ed01ef6590046a7ea08681c3089ef2a01db8b9081af1aac192ac089d5c06d2b9e1c013be564d38b45b584f9e13175bb4358448aa2042e97ccfd2fa7ac7175fac3e272a2897edfa1eea9c59a59eff5861ace1cab2f2d5637562cff4ba5ebda7afa00d34c746e98';
  }

}
