import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})

export class SldMainService {
    onButtonClick = new Subject();
    onProgressUpdate = new Subject();
}