import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DashboardService {
  // Observable navItem source
  private _navItemSource = new BehaviorSubject<string>('role');
  // Observable navItem stream
  navItem$ = this._navItemSource.asObservable();
  // service command
  changeRole(role) {
    this._navItemSource.next(role);
  }
}
