<div class="modal-container">
  <div class="modal-header">
    @if (edit) {
      <span class="modal-title" i18n>Editing Joint</span>
    }
    @if (!edit) {
      <span class="modal-title" i18n>Creating Joint</span>
    }
    <button type="button" class="close" (click)="deleteMarker()">
    </button>
  </div>
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <h2 class="h2-title" i18n>Basic</h2>
      <div class="row">
        <div class="col-lg-6 col-sm-12">
          <div class="form-wrapper">
            <label class="label-element" i18n><sup>*</sup>Node Reference</label>
            <input [class.invalid]="validation('nodeReference')" formControlName="nodeReference" type="text"
              class="form-input" />
          </div>
        </div>
        <div class="col-lg-6 col-sm-12">
          <div class="form-wrapper">
            <label class="label-element" i18n>Location Reference</label>
            <input [class.invalid]="validation('locationReference')" formControlName="locationReference" type="text"
              class="form-input" />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-sm-12">
          <div class="form-wrapper">
            <label class="label-element" i18n>Longitude (°)</label>
            <input [class.invalid]="validation('longitude')" formControlName="longitude" type="text" class="form-input"
              readonly />
          </div>
        </div>
        <div class="col-lg-4 col-sm-12">
          <div class="form-wrapper">
            <label class="label-element" i18n>Latitude (°)</label>
            <input [class.invalid]="validation('latitude')" formControlName="latitude" type="text" class="form-input"
              readonly />
          </div>
        </div>

      </div>
    </div>
    @if (userRolesObject.GISSLDEditorEdit) {
      <div class="modal-footer">
        @if (edit) {
          <button (click)="deleteMarker()" type="button" class="btn btn-link" i18n>Delete Joint</button>
        }
        @if (!edit) {
          <button (click)="onSubmit()" type="button" class="btn btn-primary" i18n>Save</button>
        }
        @if (edit) {
          <button type="submit" class="btn btn-primary" i18n>Edit Joint</button>
        }
      </div>
    }
  </form>
</div>