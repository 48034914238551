import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { DisplayConfiguration } from 'src/app/models/display-config';

@Component({
  selector: 'app-toggle-icons',
  templateUrl: './toggle-icons.component.html',
  styleUrl: './toggle-icons.component.scss'
})
export class ToggleIconsComponent {
  @Input() displayConfiguration: DisplayConfiguration;
  @Input() isRiskMatrixVisible = true;
  @Input() isTableVisible = true;
  @Input() tableName: string;
  @Output() emitState = new EventEmitter();

  private _lastWindowSize: number;

  ngOnInit() {
    // give risk matrix preference on small screens
    this.toggleClicked('riskMatrix');
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event: any) {
    if (this._lastWindowSize > 1200 && window.innerWidth < 1200) {
      // give risk matrix preference on small screens
      this.toggleClicked('riskMatrix');
    }

    this._lastWindowSize = window.innerWidth;
  }

  toggleClicked(itemClicked: 'riskMatrix' | 'table') {
    // can't fit both on small screens
    if (window.innerWidth < 1200) {
      if (itemClicked === 'riskMatrix') {
        this.isTableVisible = !this.isRiskMatrixVisible && this.isTableVisible;
      } else if (itemClicked === 'table') {
        this.isRiskMatrixVisible = !this.isTableVisible && this.isRiskMatrixVisible;
      }
    }

    this.emitState.emit({
      isRiskMatrixVisible: this.isRiskMatrixVisible,
      isTableVisible: this.isTableVisible
    });
  }
}
