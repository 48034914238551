import { Component, Input, OnDestroy, OnInit, Optional, ViewChild, ViewEncapsulation, OnChanges, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
// tslint:disable-next-line: max-line-length
import { EditDashnoardWidgetsService } from 'src/app/components/dashboard/widgets/edit-widget-dashboard/services/edit-dashboard-widgets.service';
import moment from 'moment';
import { LineChartSettings } from '../dashboard/widgets/widget-components/line-widget/line-widget.component';
import { optionItem } from '../dashboard/widgets/models/optionItem';
export interface LineChartAxisData {
  color: string;
  value: string;
  label: string;
}

@Component({
  selector: 'app-resistance-chart',
  templateUrl: './resistance-chart.component.html',
  styleUrls: ['./resistance-chart.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ResistanceChartComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('resistanceChart', { static: true }) resistanceChart;
  @Input() data: Array<any> = [];
  @Input() showTitleArea = true;
  @Input() barChartColumns = [];
  @Input() settings: LineChartSettings;
  @Input() dateRangeLabel = '';
  @Input() availablePropertiesForXAxis: Array<optionItem>;
  resizeSub: Subscription;
  settingsPopup = false;
  selectedYAxis = '';
  colors = ['#f291f2', '#cf57cf', '#8f158f', '#4a004a', '#7acfc0', '#339686', '#185c50', '#082b25', '#98d694',
    '#469e3f', '#1e5e19', '#032e00', '#d4c717', '#948a00', '#575100', '#2a2700', '#f2ac66', '#c27223', '#733900',
    '#331b03', '#ff9f8c', '#e85335', '#a31b00', '#4d0d00', '#9ebff7', '#4c85ff', '#2a35ff', '#160c9c', '#9f9f9f',
    '#686868', '#3d3d3d', '#101010'];

  constructor(@Optional() private gridDashboardSrv: EditDashnoardWidgetsService) {
  }

  handleGridResize() {
    // if chart rendered under edit-widget-dashboard
    if (this.gridDashboardSrv) {
      // if grid resized re-draw chart
      this.resizeSub = this.gridDashboardSrv.gridResized$.subscribe(() => this.renderChart());
    }
  }

  ngOnDestroy() {
    if (this.resizeSub) {
      this.resizeSub.unsubscribe();
    }
  }

  ngOnInit() {
    this.handleGridResize();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['settings'] || changes['data']) {
      this.drawChart();
    }
  }

  renderChart() {
    const element = this.resistanceChart.nativeElement;
    d3.select(element).select('svg').remove();
    d3.select(element).select('div').remove();
    this.drawChart();
  }

  wrap(texts, width) {
    texts.each(function() {
      let text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 0.2, // ems
          y = text.attr('y'),
          dy = 0.66,
          tspan = text.text(null).append('tspan').attr('x', -20).attr('y', y).attr('dy', dy + 'em');
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(' '));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(' '));
          line = [word];
          tspan = text.append('tspan').attr('x', -20).attr('y', y).attr('dy', ++lineNumber * lineHeight + dy + 'em').text(word);
        }
      }
    });
  }

  drawChart() {
    if (this.resistanceChart && this.data && this.settings) {
      var isSystemWidget = this.data[0]?.values[0]?.x.length > 4;

      const _this = this;
      const chartData = [];
      const color = [];
      const _chartData = _.cloneDeep(this.data);
      const keys = _.map(_this.settings.yProperties, 'name');
      const years = _.map(_chartData[0]?.values, 'x');
      const timePeriod = [
        {labelX: this.settings.xMin},
        {labelX: this.settings.xMax}
      ];
      //const colors = _.map(this.settings.yProperties, 'color');

      if (!isSystemWidget && !this.settings.isDependency) {
        this.setDataForNoneDependencyWidget(years, _this, _chartData, keys, color, chartData);
      }
      else {
        this.setDataForDependencyWidget(_this, _chartData, keys, color, chartData);
      }

      const element = this.resistanceChart.nativeElement;
      const width = element.clientWidth - 100;
      const height = 300;
      const margin = 50;
      const duration = 100;
      const lineOpacity = '1';
      const circleOpacity = '1';
      const circleOpacityOnLineHover = '1';
      const circleRadius = 3.5;
      const circleRadiusHover = 6;
      const ymin = this.getMinValue(chartData, 'y');
      const ymax = this.getMaxValue(chartData, 'y');
      const xScale = this.settings.isDependency ?
        this.getXLineScale(width, margin, timePeriod) :
        this.getXTimeScale(chartData, isSystemWidget, width, margin, timePeriod);

      const yScale = this.getYScale(ymin * 1.2, ymax * 1.2, height, margin);

      const div = d3.select(element)
        .append('div')
        .attr('class', 'tooltip')
        .style('opacity', 0)
        .style('border-radius', '5px')
        .style('padding', '5px')
        .style('position', 'absolute');

      // const limitHoverDiv = d3.select(element)
      //   .append('div')
      //   .attr('class', 'limit-hover')
      //   .style('opacity', 0)
      //   .style('width', (width) + 'px')
      //   .style('height', limitLineHoverHeight + 'px')
      //   .style('border-radius', limitLineHoverHeight / 2 + 'px');

      const svg = this.appendSvg(element, height, margin);
      const lines = this.getLines(svg);
      const labels = this.getLabelObjects(svg);
      const xTicks = this.getXTicksNumber();

      this.drawLines(xScale, yScale, lines, chartData, color, lineOpacity);
      this.setControlValues(xScale, yScale, svg, chartData, lineOpacity, width, margin);
      this.setXGridScale(svg, height, xScale, xTicks);
      this.setYGridScale(svg, yScale, width);
      this.addCircleTooltips(_this,lines, chartData, color, div, xScale, yScale, circleRadius, circleOpacity, duration, circleOpacityOnLineHover, circleRadiusHover);
      this.drawCustomLabels(labels, height, width);

      this.drawUnitsLabels(labels, width, height);
    }
  }

  private appendSvg(element: any, height: number, margin: number) {
    return d3.select(element).append('svg')
      .attr('width', '100%')
      .attr('height', (height + margin) + 'px')
      .append('g')
      .attr('transform', `translate(${margin + 20}, 30)`);
  }

  private getYScale(minValue: any, maxValue: any, height: number, margin: number) {
    return d3.scaleLinear()
      .domain([minValue < 0 ? minValue : minValue * 1.1, maxValue * 1.1])
      .range([height - margin, 0]);
  }

  private getXTimeScale(chartData: any[], isSystemWidget: boolean, width: number, margin: number, timePeriod: any[]) {
    if(chartData !== undefined && chartData !== null && chartData.length !== 0) {
    return d3.scaleBand()
    .domain(chartData[0].values.map(d => d.x))
    .range([0, width - margin]);
    } else {
      return d3.scaleBand()
      .domain(timePeriod.map(x => x.labelX))
      .range([0, width - margin]);
    }
  }

 

  private getXLineScale(width: number, margin: number, timePeriod) {
    if(this.data !== undefined && this.data !== null && this.data.length !== 0) {
      var xValues = this.data[0]?.values.map(x => x.x);

      return d3.scalePoint()
        .domain(xValues)
        .range([0, width - margin]);
    } else {
      return d3.scaleBand()
      .domain(timePeriod.map(x => x.labelX))
      .range([0, width - margin]);
    }
  }

  private prepareDataForTimeLine(chartData: any[], isSystemWidget: boolean) {
    const parseDate = d3.timeParse('%Y');
    chartData.forEach(function (cd: any) {
      cd.values.forEach(function (d) {
        d.x = isSystemWidget ? d.x : parseDate(d.x);
        d.y = +d.y;
      });
    });
  }

  private getLines(svg: d3.Selection<SVGGElement, unknown, null, undefined>) {
    return svg.append('g')
      .attr('transform', 'translate(30, 0)')
      .attr('class', 'lines');
  }

  private drawLines(xScale, yScale, lines: d3.Selection<SVGGElement, unknown, null, undefined>, chartData: any[], color: any[], lineOpacity: string) {
    /* Add line into SVG */
    const line2 = d3.line<{ x: any, y: any }>()
      .x((d: any) => {
        return d ? xScale(d.x) : null;
      })
      .y((d: any) => {
        return d ? yScale(d.y) : null;
      });

    lines.selectAll('.line-group')
      .data(chartData)
      .enter()
      .append('g')
      .attr('class', 'line-group')
      .append('path')
      .attr('class', 'line')
      .attr('d', (d: any) => {
        return line2(d.values);
      })
      .attr('transform', 'translate(' + (xScale.bandwidth() / 2) + ', 0)')
      .style('stroke', (d, i) => color[i])
      .style('opacity', lineOpacity);
  }

  private addCircleTooltips(_this: this, lines: d3.Selection<SVGGElement, unknown, null, undefined>, chartData: any[], color: any[], div: d3.Selection<HTMLDivElement, unknown, null, undefined>, xScale, yScale: d3.ScaleLinear<number, number>, circleRadius: number, circleOpacity: string, duration: number, circleOpacityOnLineHover: string, circleRadiusHover: number) {
    //const formatYear = d3.timeFormat('%Y');
    /* Add circles in the line */

    lines.selectAll('circle-group')
      .data(chartData).enter()
      .append('g')
      .style('fill', (d, i) => color[i])
      .selectAll('circle')
      .data((d: any) => d.values.map(y => { return { ...y, name : d.name }; })).enter()
      .append('g')
      .attr('transform', 'translate(' + (xScale.bandwidth() / 2) + ', 0)')
      .attr('class', 'circle')
      .on('mouseover', function (event: any, d: any) {
        d3.select(this)
          .style('cursor', 'pointer');
        div.transition()
          .duration(100)
          .style('opacity', 0);
        div.transition()
          .duration(100)
          .style('opacity', .9);
        div.html('<div class="header pt-1 pb-1">' + _this.settings.xUnit + ' - ' + d.x +'</div>' +
          '<div class="content pt-1 pb-1">' + (d.name ? d.name : '')  + ' - ' + d.y + ' ' + ((_this.settings && _this.settings.yUnit) ? _this.settings.yUnit : '') + ' </span></div>')
          .style('left', xScale(d.x) + 100 + 'px')
          .style('top', yScale(d.y) + (-50) + 'px');
      })
      .on('mouseout', function (d: any) {
        d3.select(this)
          .style('cursor', 'none');
        div.transition()
          .duration(100)
          .style('opacity', 0);
      })
      .append('circle')
      .attr('cx', (d: any) => d ? xScale(d.x) : null)
      .attr('cy', (d: any) => d ? yScale(d.y) : null)
      .attr('r', circleRadius)
      .style('opacity', circleOpacity)
      .on('mouseover', function (d) {
        d3.select(this)
          .transition()
          .duration(duration)
          .style('opacity', circleOpacityOnLineHover)
          .attr('r', circleRadiusHover)
          .attr('stroke', '#f4f4f4')
          .attr('stroke-width', 6);
      })
      .on('mouseout', function (d) {
        d3.select(this)
          .transition()
          .duration(duration)
          .attr('r', circleRadius)
          .style('opacity', circleOpacityOnLineHover)
          .attr('stroke', '#f4f4f4')
          .attr('stroke-width', 0);
      });
  }

  private getXTicksNumber() {
    return this.data[0]?.values.length;
  }

  private setControlValues(xScale, yScale, svg, chartData: any[], lineOpacity: string, width: number, margin: number) {
    if (this.settings.hasControlValues) {
      /* Add minLine into SVG */
      const minLine = d3.line<{ x: any, y: any }>()
        .x((d: any) => {
          return d ? xScale(d.x) : null;
        })
        .y((d: any) => {
          return yScale(Number(this.settings.minControlValue));
        });

      /* Add maxLine into SVG */
      const maxLine = d3.line<{ x: any, y: any }>()
        .x((d: any) => {
          return d ? xScale(d.x) : null;
        })
        .y((d: any) => {
          return yScale(Number(this.settings.maxControlValue));
        });

      const limitLines = svg.append('g')
        .attr('transform', 'translate(30, 0)')
        .attr('class', 'limitLines');

        if(chartData.length !== 0) {
          limitLines.selectAll('limit-line')
          .data([chartData[0]]).enter()
          .append('g')
          .attr('class', 'limit-line')
          .append('path')
          .attr('class', 'limit-line')
          .attr('d', (d: any) => minLine(d.values))
          .attr('stroke', this.settings.controlValuesColor)
          .style('opacity', lineOpacity);

          limitLines.selectAll('limit-line')
          .data([chartData[0]]).enter()
          .append('g')
          .attr('class', 'limit-line')
          .append('path')
          .attr('class', 'limit-line')
          .attr('width', (width + margin) + 'px')
          .attr('d', d => maxLine(d.values))
          .attr('stroke', this.settings.controlValuesColor)
          .style('opacity', lineOpacity);
        }
    }
  }

  private setXGridScale(svg, height: number, xScale, xTicks: number) {
    let translate = 30; // X scale horizontal position
    let xBottom = xScale;
    if (this.settings.isDependency) {
      xBottom = this.prepareXLabelsforHardcoded(xBottom);
    }
    let xAxis;
    if ( (xTicks) > 12 && (xTicks) <= 24) {
       xAxis = d3.axisBottom(xBottom).tickValues(xBottom.domain().filter(function(d, i) { return !(i % 2); }));
    } else if ( (xTicks) > 24 && (xTicks) <= 36) {
      xAxis = d3.axisBottom(xBottom).tickValues(xBottom.domain().filter(function(d, i) { return !(i % 3); }));
    } else if ( (xTicks) > 36 && (xTicks) <= 48) {
      xAxis = d3.axisBottom(xBottom).tickValues(xBottom.domain().filter(function(d, i) { return !(i % 4); }));
    } else if ( (xTicks) > 48 && (xTicks) <= 60) {
      xAxis = d3.axisBottom(xBottom).tickValues(xBottom.domain().filter(function(d, i) { return !(i % 5); }));
    } else if ( (xTicks) > 60 && (xTicks) <= 80) {
      xAxis = d3.axisBottom(xBottom).tickValues(xBottom.domain().filter(function(d, i) { return !(i % 8); }));
    }  else if ( (xTicks) > 80 && (xTicks) <= 100) {
      xAxis = d3.axisBottom(xBottom).tickValues(xBottom.domain().filter(function(d, i) { return !(i % 9); }));
    } else if ( (xTicks) > 100) {
      xAxis = d3.axisBottom(xBottom).tickValues(xBottom.domain().filter(function(d, i) { return !(i % 12); }));
    } else {
      xAxis = d3.axisBottom(xBottom); // .tickSizeOuter(0);
    }

    svg.append('g')
      .attr('class', 'grid')
      .attr('transform', `translate(${translate},` + (height - 50) + ')')
      .call(
        this.settings.showVertLines ? xAxis.tickSize(-(height - 40)) : xAxis);
  }

  private prepareXLabelsforHardcoded(xBottom: any) {
    let newXBottom = xBottom.copy();

    newXBottom.domain(this.data[0]?.values.map(item => item.hardcodedName));

    return newXBottom;
  }

  private setYGridScale(svg, yScale: d3.ScaleLinear<number, number>, width: number) {
    svg.append('g')
      .attr('class', 'grid')
      .call(this.settings.showHoriLines
        ? d3.axisLeft(yScale)
          .ticks(10).tickSize(-width)
        : d3.axisLeft(yScale)
          .ticks(10));
  }

  private drawUnitsLabels(labels: any, width: number, height: number) {
    labels.append('text').text(this.settings.yUnit)
      .attr('fill', '#727272')
      .style('font-weight', 'bold')
      .attr('x', 0)
      .attr('y', -60)
      .attr('transform', 'rotate(-90)')
      .style("text-anchor", "end");
    labels.append('text').text(this.settings.xUnit + (this.dateRangeLabel !== '' ? ` (${this.dateRangeLabel})` : ''))
      .attr('fill', '#727272')
      .attr('x', width - 30 - (this.dateRangeLabel !== '' ? (this.IsUnitSetToHourly() ? 185 : 122) : 0))
      .attr('y', height - 4);
  }

  private getLabelObjects(svg) {
    return svg.append('g')
      .attr('class', 'labels');
  }

  IsUnitSetToHourly(): boolean {
    return this.settings.xUnit === this.availablePropertiesForXAxis[3].label;
  }

  private drawCustomLabels(labels, height: number, width: number) {
    labels.append('text').text(this.settings.yCustomLabel || this.settings.yLabel)
      .call(this.wrap, 270)
      .attr('fill', '#727272')
      .attr('x', (height / 2) - 30)
      .attr('y', -75)
      .attr('text-anchor', 'middle')
      .attr('transform', 'rotate(-90, 70,60)');

    labels.append('text').text(this.settings.xCustomLabel || this.settings.xLabel)
      .attr('fill', '#727272')
      .attr('text-anchor', 'middle')
      .attr('x', width / 2)
      .attr('y', height - 20);
    return labels;
  }

  private setDataForNoneDependencyWidget(years: any, _this: this, _chartData: any, keys: any, color: any[], chartData: any[]) {
    const minYear = years.includes(_this.settings.xMin && _this.settings.xMin.toString()) ? _this.settings.xMin : years[0];
    const maxYear = years.includes(_this.settings.xMax && _this.settings.xMax.toString()) ? _this.settings.xMax : years[years.length - 1];

    for (let i = 0; i < _chartData.length; i++) {
      const axisIndex = keys.indexOf(_chartData[i].name);
      if (axisIndex >= 0) {
        color.push(_this.settings.yProperties[axisIndex].color);
        const tempLine = { name: '', values: [] };
        tempLine.name = _chartData[i].name;
        let isTempLineValues = false;
        if (this.settings.xUnit === this.availablePropertiesForXAxis[0].label) {
          for (let j = parseInt(minYear, 10); j <= parseInt(maxYear, 10); j++) {
            for (let k = 0; k < _chartData[i].values.length; k++) {
              if (j === parseInt(_chartData[i].values[k].x, 10)) {
                tempLine.values[j - minYear] = _chartData[i].values[k];
                isTempLineValues = true;
              }
            }
          }
        } else {
            for (let k = 0; k < _chartData[i].values.length; k++) {
                tempLine.values[k] = _chartData[i].values[k];
                isTempLineValues = true;
            }
        }
        if (isTempLineValues) {
          chartData.push(tempLine);
        }
      }
    }
  }

  private setDataForDependencyWidget(_this: this, _chartData: any, keys: any, color: any[], chartData: any[]) {
    for (let i = 0; i < _chartData.length; i++) {
      const axisIndex = keys.indexOf(_chartData[i].name);

      if (axisIndex >= 0) {
        color.push(_this.settings.yProperties[axisIndex].color);
        const tempLine = { name: _chartData[i].name, values: [] };

        for (let j = 0; j < _chartData[i].values.length; j++) {
          _chartData[i].values[j].x = moment(_chartData[i].values[j].x)
          tempLine.values.push(_chartData[i].values[j]);
        }
        chartData.push(tempLine);
      }
    }
  }

  private getMaxValue(chartData: any[], propName: string) {
    let max = 0;

    for (let i = 0; i < chartData.length; i++) {
      const line = chartData[i];

      for (let j = 0; j < line.values.length; j++) {
        const val = line.values[j];

        if (!Number.isNaN(val[propName]) && max < val[propName])
          max = parseFloat(val[propName])
      }
    }

    return max;
  }

  private getMinValue(chartData: any[], propName: string) {
    let min = 0;

    for (let i = 0; i < chartData.length; i++) {
      const line = chartData[i];

      for (let j = 0; j < line.values.length; j++) {
        const val = line.values[j];

        if (!Number.isNaN(val[propName]) && min > val[propName])
          min = parseFloat(val[propName])
      }
    }
    return min;
  }

  selectColor($event, item, color) {
    item.color = color;
    item.open = false;
    $event.stopPropagation();
    this.renderChart();
  }
}

