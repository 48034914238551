<div class="asset-info-data">
  <div class="component-data-header">
    <span>{{equipment.equipmentName}}</span>
    <div class="buttons">
      @if (showDelete) {
        <button (click)="deleteMarker.emit(data.id)" i18n>
        <i class="icon icon-remove-marker" aria-hidden="true"></i>
      </button>
      }

      <div class="separator"></div>
      <button i18n (click)='getAssetInfoTab()'><i class="icon icon-white-edit" aria-hidden="true"></i></button>
      <div class="separator"></div>
      <button (click)="close.emit()" i18n>
        <i class="icon icon-remove-marker" aria-hidden="true"></i>
      </button>
    </div>
  </div>
  <div class="component-data-content">
    <form [formGroup]="form">
      <ng-container>
        @for (category of equipment.propertiesByCategory; track category) {
          <div class="component-data-accordion">
            <div class="component-data-accordion-header" (click)="selected = category.category">
              {{category.category}}
              <a href="javascript:;"><i class="icon icon-up" aria-hidden="true"
                [class.down]="category.category === selected && hide===true"
              (click)="hide === false ? hide_option() :  hide_option()"></i></a>
            </div>
            @if (category.category === selected &&!hide) {
              <div [perfectScrollbar]="{suppressScrollX: true}" [style.overflow]="'auto'" [style.height.px]="'250'"
                class="component-data-accordion-body">
                <perfect-scrollbar fxFlex="auto" [scrollIndicators]="true">
                  <div class="container-fluid">
                    @for (field of category.properties; track field) {
                      <div class="row">
                        <div class="col-sm-12">
                          <div class="form-wrapper">
                            <label class="label-element" i18n>{{field.propertyName}}
                      {{ field.propDisplayUnitName != 'N/A' ? '['+ field.propDisplayUnitName +']' : ''}}
                    </label>
                            @if (field.propDataTypeName === 'Text') {
                              <input type="text"
                      [readonly]="forceViewModeActive || (priviledges.GISSLDEditorView && !priviledges.GISSLDEditorEdit) || isRole()
                                            || field.editPermission === 'Read' || field.editPermission === 'Read only' || field.editPermission === 'Read+Write' "
                                [class.invalid]="validation(field.propertyLabel)" [class.test]="field.editPermission"
                                [formControlName]="getFieldName(field.propertyLabel)" class="form-input" />
                            }
                            @if (field.propDataTypeName === 'Number') {
                              <input type="text"
                      [readonly]="forceViewModeActive || (priviledges.GISSLDEditorView && !priviledges.GISSLDEditorEdit) || isRole()
                                                || field.editPermission === 'Read' || field.editPermission === 'Read only' || field.editPermission === 'Read+Write'"
                                [formControlName]="getFieldName(field.propertyLabel)"
                                class="form-input" maxlength="5" [appTwoDigitDecimaNumber]="field.noOfDecimals"/>
                            }
                            @if (field.propDataTypeName === 'Customizable' || field.propDataTypeName === 'Enumeration') {
                              <select
                                [class.invalid]="validation(field.propertyLabel)"
                                [formControlName]="getFieldName(field.propertyLabel)" class="form-input"
                                [attr.disabled]="(forceViewModeActive || (priviledges.GISSLDEditorView && !priviledges.GISSLDEditorEdit) || isRole()) ? true : null">
                                <option value=""></option>
                                @for (item of field.options; track item) {
                                  <option [value]="item">
                                    {{item}}
                                  </option>
                                }
                              </select>
                            }
                            @if (field.propDataTypeName === 'Customizable' && form.value[getFieldName(field.propertyLabel)] === 'Others') {
                              <input
                                type="text" [class.invalid]="validation(field.propertyLabel + 'Etc')"
                                [formControlName]="getFieldName(field.propertyLabel + 'Etc')" class="form-input mt-2"
                                [readonly]="forceViewModeActive || (priviledges.GISSLDEditorView && !priviledges.GISSLDEditorEdit) || isRole()" />
                            }
                            <span class="error-indicate" [class.invalid]="validation(field.propertyLabel)"
                            i18n>{{field.propertyLabel}} is Required</span>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                </perfect-scrollbar>
              </div>
            }
          </div>
        }
      </ng-container>
    </form>
    @if (!isRole()) {
      <div class="buttons">
        @if (nodeType === 'substation' || nodeType === 'Substation' || nodeType === 'SubStation') {
          <button
          (click)="sld.emit()" class="btn btn-secondary">Edit SLD</button>
        }
        <button (click)="form.value.commisioningYear<form.value.installationYear?messagePopup():saveData('save')" class="btn btn-secondary">Save</button>
        <button (click)="deleteData()" class="btn btn-secondary">Delete</button>
      </div>
    }
    @if (isRole()) {
      <div class="buttons">
        @if (nodeType === 'substation' || nodeType === 'Substation' || nodeType === 'SubStation') {
          <button
          (click)="sld.emit()" class="btn btn-secondary">View SLD</button>
        }
      </div>
    }
  </div>
</div>