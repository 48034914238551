import { Component, EventEmitter, HostListener, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../../../../../services/notification.service';
import { RolesService } from 'src/services/roles.service';

@Component({
    selector: 'app-notification-settings',
    templateUrl: './notification-settings.component.html',
    styleUrls: ['./notification-settings.component.scss']
})
export class NotificationSettingsComponent implements OnInit {
    manualRefresh: EventEmitter<void> = new EventEmitter<void>();
    @Input() title = '';
    submitted = false;
    loading = false;
    isClose = false;
    public emailPopup = false;
    public emailOptions = [];
    public openSide = false;
    public data = {
        tasks: {
            enableNewTasks: false,
            enableOverdueTasks: false,
            enableRejectedTasks: false,
            enableValidatedTasks: false,
        },
        performance: {
            enableSubstationRiskLevels: false,
            enableFactsRiskLevels: false,
            enableAssetRiskLevels: false,
            enableAccountUpdates: false,
            enableSystemEvents: false,
        },
        email: {
            enable: false,
            option: 'Once an hour'
        }
    };
    public isAboutPanelOpen = true;
    public isThroughPanelOpen = true;

    constructor(
        public activeModal: NgbActiveModal,
        private toastr: ToastrService,
        private notificationSvc: NotificationService,
        public rolesService: RolesService
    ) { }

    ngOnInit() {
        this.notificationSvc.getEmailSettingOptions().subscribe(res => {
            this.emailOptions = res.returnObj;
        });
        this.notificationSvc.getNotificationSettings().subscribe(data => {
            this.data = data.returnObj;
        });
    }

    onSubmit() {
        this.submitted = true;
        this.activeModal.close(this.data);
        this.notificationSvc.updateNotificationSettings(this.data).subscribe(() => {
            this.toastr.success('Successfully saved');
        });
    }

    clickedInside($event: Event) {
        $event.preventDefault();
        $event.stopPropagation();
        if (this.data.email.enable) {
            if (!this.isClose) {
                this.emailPopup = true;
            } else {
                this.isClose = false;
            }
        } else {
            this.isClose = true;
        }
    }

    @HostListener('document:click', ['$event'])
    clickedOutside($event) {
        this.emailPopup = false;
    }

    changeEmailOption(item) {
        this.data.email.option = item;
        this.openSide = false;
        this.emailPopup = false;
        this.isClose = true;
    }

    onChangeChecked(e, type, name) {
        this.data[type][name] = e.target.checked;
        this.manualRefresh.emit();
    }

    getStatus(type, name) {
        if (_.has(this.data, type)) {
            if (_.has(this.data[type], name)) {
                return this.data[type][name];
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    clickThroughPanel() {
        this.isThroughPanelOpen = !this.isThroughPanelOpen;
    }

    clickAboutPanel() {
        this.isAboutPanelOpen = !this.isAboutPanelOpen;
    }

    isSystemRole() {
        return this.rolesService.isSystemRole();
    }

}
