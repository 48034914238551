import { Injectable } from '@angular/core';
import { ConstService } from './const.service';
import * as RouteHelper from 'src/app/utility/routing.helper';
@Injectable({
  providedIn: 'root'
})

export class RolesService {

  readonly TENANT_ADMIN = 'Tenant Admin';
  readonly ADMIN = 'Admin';
  readonly SYSTEM_MANAGER = 'System Manager';
  readonly SYSTEM_SUPPORT = 'System Support';
  readonly POLICY_MANAGER = 'Policy Manager';
  readonly CONTROL_ROOM = 'Control Room';
  readonly PROJECT_MEMBER = 'Project Member';
  readonly PROJECT_MANAGER = 'Project Manager';
  readonly PROJECT_SUPPORT = 'Project Support';
  readonly PROJECT_MANAGER_C = 'Project Manager (C)';
  readonly EXECUTION_TEAM_LEAD = 'Execution Team Lead';
  readonly MAINTENANCE_MANAGER = 'Maintenance Manager';
  readonly EXECUTION_OWNER = 'Execution Owner';
  readonly OPERATION_SME = 'Operation SME';
  readonly REMOTE_OPERATOR = 'Remote Operator';
  readonly POLICY_SME = 'Policy SME';
  projectId: any;
  userRoles: any;
  operationsRoles = [
    this.OPERATION_SME,
    this.REMOTE_OPERATOR
  ];
  // Both edit and view
  maintenanceRoles = [
    this.MAINTENANCE_MANAGER,
    this.EXECUTION_TEAM_LEAD,
    this.EXECUTION_OWNER,
    this.PROJECT_MANAGER,
    this.PROJECT_SUPPORT,
    this.PROJECT_MANAGER_C,
    this.CONTROL_ROOM
  ];
  constructor(private constService: ConstService) { }

  getUserRoles() {
    const roles = sessionStorage.getItem('UserRoles');
    // Handle in case of absence of roles in session storage
    if (!roles) {
      return [];
    }
    return roles;
  }

  // Checks if the user has a specific role
  hasRole(role: string): boolean {
    const userRoles = this.getUserRoles();
    return userRoles.indexOf(role) !== -1;
  }
  // Checks if the user has any of the specified roles
  hasAnyRoles(roles: string[]): boolean {
    return roles.some(role => this.hasRole(role));
  }

  // Checks if the user has only the specified roles
  hasOnlyRoles(roles: string[]): boolean {
    const allRoles = this.getUserRoles();
    if (!allRoles) {
      return false;
    }
    const userRoles = typeof allRoles === 'string' ? allRoles.split(',').map(role => role.trim()) : allRoles;
    return userRoles.length === roles.length && roles.every(role => userRoles.includes(role));
  }

  // Added to pass the headers as project member
  getRoleNameforHeaders() { 
    this.projectId = sessionStorage.getItem('projectId');
    this.userRoles = this.getUserRoles();
    return this.projectId === (this.constService.emptyGuid && this.hasPermissionToEditInSystem()) ? this.userRoles.split(",")[0] :
    (this.userRoles.indexOf(this.CONTROL_ROOM) === 0 ? this.CONTROL_ROOM : this.PROJECT_MEMBER);
  }

  // Checks if user has admin role
  isSystemAdmin(): boolean {
    return this.hasRole(this.ADMIN);
  }

  // Checks for control room role in project environment
  isControlRoomProject(): boolean {
    return this.hasRole(this.CONTROL_ROOM) && !this._isSystemEnvironment();
  }

  // Checks for control room role in system environment
  isControlRoomSystem(): boolean {
    return this.hasRole(this.CONTROL_ROOM) && this._isSystemEnvironment();
  }

  // Checks if user has any of the system roles
  isSystemRole(): boolean {
    return this.hasAnyRoles([this.SYSTEM_MANAGER, this.SYSTEM_SUPPORT, this.POLICY_MANAGER, this.PROJECT_MEMBER,
      this.isControlRoomSystem()? this.CONTROL_ROOM: null])
  }
  // Checks if user has permission to edit the role
  hasPermissionToAddOrEditRoles(){
    return this.isSystemRole() || this.hasAnyRoles([this.PROJECT_MANAGER, this.PROJECT_SUPPORT])
  }
  // Checks if user has any of the project roles
  isProjectRole() {
    return this.hasAnyRoles([this.PROJECT_MANAGER, this.PROJECT_SUPPORT, this.PROJECT_MANAGER_C, this.EXECUTION_TEAM_LEAD, this.EXECUTION_OWNER,
      this.MAINTENANCE_MANAGER, this.POLICY_SME, this.OPERATION_SME, this.REMOTE_OPERATOR,
      this.isControlRoomProject()? this.CONTROL_ROOM: null])
  }

  // Checks if user can edit in system environment
  hasPermissionToEditInSystem(): boolean {
    const allowedRoles = [this.SYSTEM_MANAGER, this.SYSTEM_SUPPORT];
    const otherRoles = [this.POLICY_MANAGER, this.PROJECT_MEMBER];
    const viewRoles = [this.CONTROL_ROOM];

    return this.permissionToEdit(allowedRoles, otherRoles, viewRoles)
  }

  // Checks if user can edit in project environment
  hasPermissionToEditInProject(): boolean {
    const allowedRoles = [this.PROJECT_MANAGER, this.PROJECT_SUPPORT];
    const otherRoles = [this.EXECUTION_TEAM_LEAD, this.EXECUTION_OWNER, this.POLICY_SME,
    this.MAINTENANCE_MANAGER, this.OPERATION_SME, this.REMOTE_OPERATOR];
    const viewRoles = [this.CONTROL_ROOM, this.PROJECT_MANAGER_C];

    return this.permissionToEdit(allowedRoles, otherRoles, viewRoles)
  }

    //Checks if user can edit in Maintenance Management
    hasPermissionToEditInMaintenanceManagement(): boolean {
      const allowedRoles = [this.MAINTENANCE_MANAGER, this.EXECUTION_TEAM_LEAD, this.EXECUTION_OWNER];
      const otherRoles = [this.OPERATION_SME, this.POLICY_SME, this.POLICY_MANAGER, this.REMOTE_OPERATOR]
      const viewRoles = [this.PROJECT_MANAGER, this.PROJECT_SUPPORT, this.PROJECT_MANAGER_C, this.CONTROL_ROOM];
  
      return this.permissionToEdit(allowedRoles, otherRoles, viewRoles);
    }

  /**
  * Checks whether the current user has permission to edit based on their roles.
  * @param allowedRoles Array of roles that have permission to edit.
  * @param otherRoles Array of other roles that should be able to edit their respective pages but not allowed roles pages.
  * @param viewRoles Array of roles that only have view access.
  * @returns A boolean indicating whether the user has permission to edit.
  */
  permissionToEdit(allowedRoles?: any[], otherRoles?: any[], viewRoles?: any[]): boolean {
    const roles = this.getUserRoles();
    // Convert roles to an array if it's not already one
    const rolesArray = Array.isArray(roles) ? roles : roles.split(',');

    // Check if the user has the combination of roles that grant permission to edit
    const hasAllowedRolesWithOtherAllowedOrRequired = allowedRoles.some(role => rolesArray.includes(role)) && (otherRoles.some(role => rolesArray.includes(role)) || viewRoles.some(role => rolesArray.includes(role)));
    // Check if the user has other roles along with required roles
    const hasOtherAllowedRolesWithRequired = otherRoles.some(role => rolesArray.includes(role)) && viewRoles.some(role => rolesArray.includes(role));
    // Check if the user has only required roles
    const hasOnlyRequiredRoles = viewRoles.every(role => rolesArray.includes(role));
    // Check if the user has allowed roles along with other roles
    const hasAllowedRolesWithOtherAllowed = allowedRoles.some(role => rolesArray.includes(role)) && otherRoles.some(role => rolesArray.includes(role));
    // Check if the user has allowed roles along with required roles
    const hasAllowedRolesWithRequired = allowedRoles.some(role => rolesArray.includes(role)) && viewRoles.some(role => rolesArray.includes(role));
    // Check if the user has only allowed roles
    const hasOnlyAllowedRoles = allowedRoles.some(role => rolesArray.includes(role));

    return hasAllowedRolesWithOtherAllowedOrRequired || (!hasOtherAllowedRolesWithRequired && !hasOnlyRequiredRoles && hasAllowedRolesWithOtherAllowed) || hasAllowedRolesWithRequired || hasOnlyAllowedRoles;
  }

  // Checks for permission to edit in project and system
  hasPermissionToEdit(): boolean {
    if (RouteHelper.isInProjectMode()) {
        return this.hasPermissionToEditInProject();
    } else {
        return this.hasPermissionToEditInSystem();
    }
  }

  //Checks if system env or not
  private _isSystemEnvironment() {
    const token = JSON.parse(sessionStorage.getItem('previleges'));
    return token?.isSystemRole;
  }

}
