import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationService } from '../../../../../services/application.service';
import each from 'lodash/each';

@Component({
  selector: 'app-create-load-modal',
  templateUrl: './create-load-modal.component.html',
  styleUrls: []
})
export class CreateLoadModalComponent implements OnInit {
  form: FormGroup;
  submitted: boolean;
  @Input() edit: boolean;
  @Input() masterData: any;
  @Input() marker: any;
  lat: number;
  lon: number;
  equipment: any = {};
  options: any = {};
  userRolesObject: any = {};
  typeOfLoad: any = ['Distribution', 'Transmission', 'Utility', 'Others']
  constructor(private dataService: ApplicationService,
    private formBuilder: FormBuilder, public activeModal: NgbActiveModal, private router: Router) {
  }

  ngOnInit() {
    this.userRolesObject = this.dataService.getUserPrivilages();
    const properties = this.marker ? this.marker.getProperties() : {};
    this.lat = properties.latitude;
    this.lon = properties.longitude;
    this.form = this.formBuilder.group({
      loadReference: [properties.loadReference, Validators.required],
      substationReference: [properties.substationReference],
      typeOfLoad: [properties.typeOfLoad],
      typeOfLoadEtc: [properties.substationReference],
      ratedPower: [properties.ratedPower, Validators.required],
      ratedPowerFactor: [properties.ratedPowerFactor],
    });
    this.loadProperties();
  }
  loadProperties() {
  
    this.dataService.getAssetInfoForNodes('Load')
      .subscribe({next:(fields) => {
        this.equipment = fields; 
        this.registerFields(this.equipment);       
      }, error: () => {
  }});
      
  }

  registerFields(equipment) {
    const fieldObject = {};
    
    const propertiesByCategory = this.equipment.propertiesByCategory;
    const fields = {};
    if (propertiesByCategory) {
      each(propertiesByCategory, (category) => {
        each(category.properties, (property) => {
            if (property.propDataTypeName === 'Customizable'&& property.propertyName == 'Type of load') {
              this.options = property.options;
                  }                  
        });
      });
    }
    }
  /**
   * get form controllers
   */
  get f(): any {
    return this.form.controls;
  }

  /**
   * Validate sub station
   * @param name - field name
   * @returns - valid or not
   */
  validation(name): any {
    return this.submitted && this.form.controls[name].errors && this.form.controls[name].errors.required;
  }

  close() {
    this.activeModal.close({ type: 'close' });
  }

  /**
   * Delete marker
   */
  deleteMarker() {
    this.activeModal.close({ type: 'delete', data: this.marker.getId() });
  }

  /**
   * Submit form
   */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.activeModal.close({
      nodeType: 'external load',
      type: 'save', data: {
        id: this.marker.getId(),
        ...this.form.value,
        latitude: this.lat,
        longitude: this.lon
      }
    });
  }
  only_numbers(value: string, event: any) {
    let k;
    //k = event.charCode;  //
    k = event.keyCode;
    //  return ((k > 47 && k < 58) || k === 46);
    // const input = (document.getElementById('myInput') as HTMLInputElement).value;
    if (k === 8 || k === 37 || k === 45) {
        return true;
    }
     else if (k === 46 && value.indexOf('.') !== -1) {
        return false;
    } 
    else if (k > 31 && k !== 46 && (k < 48 || k > 57)) {
        return false;
    }
    return true;
}

  goToSLD() {
    this.activeModal.close({ type: 'sld' });
  }
}
