@if (displayConfiguration) {
  <div class="icons-wrapper">
    @if(displayConfiguration?.toggleMatrix){
      <button class="risk-matrix-button" (click)="isRiskMatrixVisible=!isRiskMatrixVisible;toggleClicked('riskMatrix')"
        ngbTooltip="Risk matrix" triggers="hover" tooltipClass="icon-tooltip" 
        container="body" placement="bottom">
      <i class="icon icon-risk-matrix riskmatrix-icons"></i></button>
    }
    @if(displayConfiguration?.toggleTable){
      <button class="table-list-button" (click)="isTableVisible=!isTableVisible;toggleClicked('table')"
      [ngbTooltip]="tableName + ' table'" triggers="hover" tooltipClass="icon-tooltip" 
      container="body" placement="bottom">
      <i class="icon icon-table-list"></i></button>
    }
  </div>
}
