import { Component, ElementRef, EventEmitter, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { ApplicationService } from 'src/services/application.service';

@Component({
  selector: 'app-edit-skill',
  templateUrl: './edit-skill.component.html',
  styleUrls: ['./edit-skill.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EditSkillComponent implements OnInit {

  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  skills: any = [];
  @Input() skillInput: any;
  skill: any;
  loading = true;
  documentFile: any;
  sliderPerfOptions = {
    showSelectionBar: true,
    floor: 1,
    ceil: 5,
    step: 1,
    hidePointerLabels: true,
    hideLimitLabels: true
  };
  skillLevel = 1;
  months = [{ 'id': '1', 'item': 'Jan' }, { 'id': '2', 'item': 'Feb' }, { 'id': '3', 'item': 'Mar' },
  { 'id': '4', 'item': 'Apr' }, { 'id': '5', 'item': 'May' }, { 'id': '6', 'item': 'Jun' },
  { 'id': '7', 'item': 'Jul' }, { 'id': '8', 'item': 'Aug' }, { 'id': '9', 'item': 'Sep' },
  { 'id': '10', 'item': 'Oct' }, { 'id': '11', 'item': 'Nov' },
  { 'id': '12', 'item': 'Dec' }];
  today = new Date();
  years = [0, 1, 2, 3, 4, 5].map(n => this.today.getFullYear() + n);
  @ViewChild('fileInput', { static: false }) fileInputRef: ElementRef;
  skillNameRequired = false;
  constructor(private appSvc: ApplicationService,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.loading = true;
    this.skill = this.skillInput ? _.cloneDeep(this.skillInput) : {};
    if (this.skillInput) {
      this.skillLevel = this.skillInput.skillLevel;
      // this.skill.isSkillDocModified = true;
    }
    this.appSvc.getSkills().subscribe({next:res => {
      this.loading = false;
      const skill = res;
      for (let i = 0; i < skill.skillsDefaultFalseList.length; i++) {
        this.skills.push(skill.skillsDefaultFalseList[i]);
      }
      for (let i = 0; i < skill.skillsDefaultTrueList.length; i++) {
        this.skills.push(skill.skillsDefaultTrueList[i]);
      }
      // this.skills.push(res.skillsDefaultFalseList);
      // this.skills.push(res.skillsDefaultTrueList);
      // this.skillLevel = 3;
      this.manualRefresh.emit();
      // this.skill.skillId = this.skill.skillName;
      this.skill.skillName = this.skill.skillName;
      this.skill.skillCertificateValidityPeriodMonth = this.skill.skillCertificateValidityPeriodMonth || '1';
      // this.skill.skillCertificateValidityPeriodMonth = new Date('Jan' + '-1-01').getMonth() + 1;
      this.skill.skillCertificateValidityPeriodYear = this.skill.skillCertificateValidityPeriodYear || this.today.getFullYear() + 1;
    }, error:() => {
      this.loading = false;
    }});
  }
  selectedskill(event, name) {
    this.skill.skillId = event.value;
    this.skill.skillName = event.selectedOptions[0].innerText;
    if (this.skill.skillName) {
      this.skillNameRequired = false;
    }
  }
  onChange(monthStr) {
    return new Date(monthStr + '-1-01').getMonth() + 1;
  }
  save() {
    if (this.skill.skillName) {
      this.skill.skillLevel = this.skillLevel;
      // this.skill.skillCertificateValidityPeriodMonth = new Date(this.skill.skillCertificateValidityPeriodMonth + '-1-01').getMonth() + 1;
      this.activeModal.close(this.skill);
    } else {
      this.skillNameRequired = true;
    }
  }

  attachDocument() {
    this.fileInputRef.nativeElement.click();
  }
  removeSkill() {
    this.skill.skillCertificateFile = null;
    this.skill.skillCertificateName = null;
  }
  onInputFileSelected(event) {
    const file = event.target.files[0] as File;
    if (!file) { return; }
    this.skill.skillCertificateName = file.name;
    this.skill.skillCertificateFile = file;
    if (this.skillInput && this.skill.skillCertificateFile) {
      this.skill.isSkillDocModified = true;
    }
  }

  deleteSkill(close) {
    if (close === 'close') {
      this.activeModal.close('close');
    }
    if (close === 'delete') {
      this.activeModal.close('delete');
    }
    this.activeModal.close(null);
  }

}
