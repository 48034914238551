<div class="all-roles-list" [perfectScrollbar]="{suppressScrollY: true}">
  @for (role of allRoles; track role) {
    <div>
      <div class="card-role-container">
        <div class="card-role" [class.disabled]="role.eventSelected">
          <div class="card-role-wrap">
            <div class="card-header">
              <input id="{{role.roleName}}" type="checkbox" [class.disabled]="isPMCRoleSelected && role.roleName !== 'Project Manager (C)'" [checked]="role.selected" (change)="Changed(role,$event)"/>
              <i class="icon icon-role-black" aria-hidden="true"></i>
              <span class="role">{{role.roleName}} </span>
            </div>
            <div class="card-body" (click)="roleClick.emit()">
              @for (privilege of role.assignDescriptions[0].description; track privilege) {
                <div class="item">
                  <div class="permission">
                    <ul>
                      <li>
                        <div style="margin-top: -20px;margin-left: 20px;">{{privilege}}</div>
                      </li>
                    </ul>
                  </div>
                </div>
              }
            </div>     </div>
          </div>
        </div>
      </div>
    }
  </div>
