@if(openRightPanel) {
<div class="right-container" (click)="$event.stopPropagation()">
    <div class="content">
        <div class="content-header"> {{ this.router.url.indexOf('/offline') === -1 ? userName : offlineUserName}}
            <button type="button" class="close" aria-label="Close" (click)="openRightPanel = false; sendValueBack()">
                <span>&times;</span>
            </button>
        </div>
        <hr>
        <div class="content-body">
            <div class="content-body-header"> Privileges </div>
            @if(this.router.url.indexOf('/offline') === -1){
                @for (item of userRoles; track item) {
                    <div class="roles">
                        {{ item.name }}
                    </div>
                    }
            }
            @else{
                @for (item of userRoles; track item) {
                    <div class="roles">
                        {{ item }}
                    </div>
                    } 
            }

        </div>
        <hr>
        <div class="logout" (click)=" this.router.url.indexOf('/offline') === -1 ? logOut() : offlineLogout()">
            <i class="icon icon-logout" aria-hidden="true"></i>
            <span class="logout-title">Log out</span>
        </div>
    </div>
</div>
}
