import { Injectable } from '@angular/core';
import { Observable, firstValueFrom, switchMap, map, of } from 'rxjs';
import { db, User, Project, Task, TaskDetails, Filters } from 'src/app/db';
import { CryptoService } from './crypto.service';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneralUserConfirmationModalComponent } from 'src/app/containers/dashboard/modals/general-user-confirmation-modal/general-user-confirmation-modal.component';

type MiscellaneousDataKey = 'commonSafetySwms' | 'teams' | 'hazards' | 'severity' | 'probabilities' | 'riskmatrix';

@Injectable({
  providedIn: 'root'
})
export class IndexDBService {

  public password: string;

  constructor(private cryptoService: CryptoService, private modalService: NgbModal) { }

  login(email: string, password: string): Observable<User> {
    return new Observable((observer) => {
      db.users.where('email').equals(email).first().then(user => {
        this.cryptoService.decrypt(user.passwordChecker, password).then(() => {
          observer.next(user);
          observer.complete();
        }).catch(() => observer.error('password error'));
      }).catch(() => observer.error('username error'));
    });
  }

  getUsers(): Observable<User[]> {
    return new Observable((observer) => {
      db.users.toArray().then((users) => {
        observer.next(users);
        observer.complete();
      });
    });
  }


  getProjects(): Observable<Project[]> {
    const userId = sessionStorage.getItem('UserId');

    return new Observable((observer) => {
      db.projects.where('userId').equals(userId).toArray().then((projects) => {
        observer.next(projects);
        observer.complete();
      });
    });
  }

  getProjectMetaInformation(projectId: string): Observable<any> {
    return this.getTasks(projectId).pipe(map(tasks => ({
        technology: this.getUniqueArrayString(tasks, "technology").join(),
        totalInstallations: this.getUniqueArrayString(tasks, "installation").length,
        openTaskCount: tasks.length,
      })
    ))
    };

  getUniqueArrayString(arr:any[], key:string){
    return [...new Set(arr.map(task => task[key]))]
  }

  getFiltersMasterData(): Observable<Filters> {
    const userId = sessionStorage.getItem('UserId');
    const projectId = sessionStorage.getItem('projectId');

    return new Observable((observer) => {
      db.filters.where('userId').equals(userId).filter(filters => filters.projectId === projectId).first().then((filters) => {
        this.cryptoService.decrypt(filters.encryptedData).then(data => {
          observer.next(data);
          observer.complete();
        }).catch(err => observer.error(err));
      }).catch(err => observer.error(err));
    });
  }

  getTasks(projectId?:string): Observable<Task[]> {
    const userId = sessionStorage.getItem('UserId');
    projectId = projectId || sessionStorage.getItem('projectId');

    return new Observable((observer) => {
      db.tasks.where('userId').equals(userId).filter(task => task.projectId === projectId).toArray().then((tasks) => {
        Promise.all(tasks.map(task => this.cryptoService.decrypt(task.encryptedData))).then(data => {
          observer.next(data);
          observer.complete();
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }

  getTaskOfflineState(taskId: string): Observable<string> {
    const userId = sessionStorage.getItem('UserId');

    return new Observable((observer) => {
      db.tasks.where('id').equals(taskId).filter(task => task.userId === userId).first().then(task => {
        this.cryptoService.decrypt(task.encryptedData).then(data => {
          observer.next(data.offlineState);
          observer.complete();
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }

  getTaskDetails(taskId: string): Observable<TaskDetails> {
    const userId = sessionStorage.getItem('UserId');

    return new Observable((observer) => {
      db.taskDetails.where('id').equals(taskId).filter(taskDetails => taskDetails.userId === userId).first().then((taskDetails) => {
        this.cryptoService.decrypt(taskDetails.encryptedData).then(data => {
          observer.next(data);
          observer.complete();
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }

  getCommonSafetySwms(): Observable<any> {
    const userId = sessionStorage.getItem('UserId');
    const projectId = sessionStorage.getItem('projectId');

    return new Observable((observer) => {
      db.miscellaneous.where('userId').equals(userId).filter(commonSafetySwms => commonSafetySwms.projectId === projectId).first().then((commonSafetySwms) => {
        this.cryptoService.decrypt(commonSafetySwms.encryptedData).then(data => {
          observer.next(data.commonSafetyStepsSwms);
          observer.complete();
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }

  addOrUpdateUser(user: Partial<User>, password?: string): Observable<void> {
    return new Observable((observer) => {
      this.cryptoService.encrypt(uuid(), password).then(encryptedData => {
        // assign a random string that will be used to check if decryption with a given password is successful or not
        user.passwordChecker = encryptedData;
        db.users.put(user as User).then(() => {
          observer.next();
          observer.complete();
        }).catch((err) => observer.error(err))
      }).catch((err) => observer.error(err));
    });
  }

  addOrUpdateProject(projectData: Partial<Project>): Observable<void> {
    let project={...projectData}
    project.userId = sessionStorage.getItem('UserId');
    project.userRoles = sessionStorage.getItem('UserRoles')
    return new Observable((observer) => {
      db.projects.put(project as Project).then(() => {
        observer.next();
        observer.complete();
      });
    });
  }

  addOrUpdateFiltersMasterData(filters: any): Observable<void> {
    return new Observable((observer) => {
      this.cryptoService.encrypt(filters).then(encryptedData => {
        db.filters.put({ userId: sessionStorage.getItem('UserId'), projectId: sessionStorage.getItem('projectId'), encryptedData }).then(() => {
          observer.next();
          observer.complete();
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }

  addOrUpdateTask(task: Partial<Task>): Observable<void> {
    return new Observable((observer) => {
      this.cryptoService.encrypt(task).then(encryptedData => {
        db.tasks.put({ id: task.id, userId: sessionStorage.getItem('UserId'), projectId: sessionStorage.getItem('projectId'), encryptedData }).then(() => {
          observer.next();
          observer.complete();
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }

  changeTaskOfflineState(taskId: string, newState: string): Observable<void> {
    return new Observable((observer) => {
      db.tasks.where('id').equals(taskId).first().then(task => {
        this.cryptoService.decrypt(task.encryptedData).then(decryptedData => {
          decryptedData.offlineState = newState;
          firstValueFrom(this.addOrUpdateTask(decryptedData)).then(() => {
            observer.next();
            observer.complete();
          }).catch((err) => observer.error(err));
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }

  addOrUpdateTaskDetails(taskDetails: Partial<TaskDetails>): Observable<void> {
    return new Observable((observer) => {
      this.cryptoService.encrypt(taskDetails).then(encryptedData => {
        db.taskDetails.put({ id: taskDetails.id, userId: sessionStorage.getItem('UserId'), projectId: sessionStorage.getItem('projectId'), encryptedData }).then(() => {
          observer.next();
          observer.complete();
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }

  deleteTask(taskId: string): Observable<void> {
    return new Observable((observer) => {
      db.taskDetails.where('id').equals(taskId).delete().then(() => {
        db.tasks.where('id').equals(taskId).delete().then(() => {
          observer.next();
          observer.complete();
        }).catch((err) => observer.error(err));
      });
    });
  }

  getMiscellaneousData(key?: MiscellaneousDataKey): Observable<any> {
    const userId = sessionStorage.getItem('UserId');
    const projectId = sessionStorage.getItem('projectId');

    return new Observable((observer) => {
      db.miscellaneous.where('userId').equals(userId).filter(miscellaneous => miscellaneous.projectId === projectId).first().then((miscellaneous) => {
        this.cryptoService.decrypt(miscellaneous.encryptedData).then(data => {
          observer.next(key ? data[key] : data);
          observer.complete();
        }).catch((err) => observer.error(err));
      }).catch(() => {
        observer.next(key ? null : {});
        observer.complete();
      });
    });
  }

  addOrUpdateMiscellaneousData(key: MiscellaneousDataKey, data: any): Observable<void> {
    return new Observable((observer) => {
      firstValueFrom(this.getMiscellaneousData()).then((miscelleanous) => {
        miscelleanous[key] = data;
        this.cryptoService.encrypt(miscelleanous).then(encryptedData => {
          db.miscellaneous.put({ userId: sessionStorage.getItem('UserId'), projectId: sessionStorage.getItem('projectId'), encryptedData }).then(() => {
            observer.next();
            observer.complete();
          }).catch((err) => observer.error(err));
        }).catch((err) => observer.error(err));
      }).catch((err) => observer.error(err));
    });
  }

  private async getSavedFileDirectoryHandle(type: 'file' | 'directory'): Promise<FileSystemDirectoryHandle | FileSystemFileHandle | undefined> {
    const records = await db.fileDirectoryHandle.toArray();
    const record = records.find(record => record.type === type);
    if (!record) { return; }
    // this check is added as the serialized version of index db will not serialize the file / directory handles properly
    // so if the db has been imported from a json file, then even though we might have a record, the handle will be an empty, invalid object
    if (!record.handle.name) { return; }
    return record.handle;
  }

  private async addOrUpdateFileDirectoryHandle(handle: FileSystemDirectoryHandle | FileSystemFileHandle, type: 'file' | 'directory') {
    const existing = (await db.fileDirectoryHandle.toArray()).filter(record => record.type === type);
    if (existing.length > 0) {
      await db.fileDirectoryHandle.update(existing[0].id, { handle, type });
    } else {
      await db.fileDirectoryHandle.add({ handle, type });
    }
  }

  async getBackupObject() {
    const backup = {};
    for (const table of db.tables) {
      const records = await table.toArray();
      backup[table.name] = records;
    }
    return backup;
  }

  async downloadDBBackupAsFile() {
    const backup = await this.getBackupObject();

    const jsonData = JSON.stringify(backup, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    const currentDate = moment();
    const dateformat = moment(currentDate).format('DD-MM-YYYY')
    link.download = 'relcare-offline-execution-backup-'+dateformat+'.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  async clearAllTables() {
    for (const table of db.tables) {
      await table.clear();
    }
  }

  async importFromBackupObject(backup: any, takeSafetyBackup = true) {
    const safetyBackup = await this.getBackupObject();
    await this.clearAllTables();

    try {
      for (const tableName in backup) {
        // console.log(tableName);
        if (backup.hasOwnProperty(tableName)) {
          const table = db.table(tableName);
          await table.bulkAdd(backup[tableName]);
        }
      }
    } catch (error) {
      console.error('Failed to import', error);
      if (takeSafetyBackup) {
        this.importFromBackupObject(safetyBackup, false);
      }
      throw new Error(error);
    }
  }

  checkIfBackupFileHasCurrentUserData(file: File): Observable<boolean> {
    const currentUserId = sessionStorage.getItem('UserId');
    if (!currentUserId) { return of(false); }

    return new Observable((observer) => {
      const reader = new FileReader();

      reader.onload = async () => {
        try {
          const data = JSON.parse(reader.result as string);
          data.users.find(user => user.id === currentUserId) ? observer.next(true) : observer.next(false);
        } catch (error) {
          console.error('Failed to read data:', error);
          observer.error(error);
        }
      };

      reader.readAsText(file);
    });
  }

  importBackupFromFile(file: File): Observable<void> {
    return new Observable((observer) => {
      const reader = new FileReader();

      reader.onload = async () => {
        try {
          const data = JSON.parse(reader.result as string);
          await this.importFromBackupObject(data);
          observer.next();
          observer.complete();
        } catch (error) {
          console.error('Failed to import data:', error);
          observer.error(error);
        }
      };

      reader.readAsText(file);
    });
  }

  private async getPermissionToModifyFile(handle: FileSystemFileHandle | FileSystemDirectoryHandle): Promise<boolean> {
    // check if permission already granted
    if ((await handle.queryPermission({ mode: 'readwrite' })) === 'granted') {
      return true;
    }
    // request for permission
    if (await handle.requestPermission({ mode: 'readwrite' }) === 'granted') {
      return true;
    }
    return false;
  }

  async getBackupDirectoryHandleWithPermission(): Promise<FileSystemDirectoryHandle> {
    try {
      // feature not supported on this device. Just return nothing.
      if (!window.showDirectoryPicker) { return; }

      // if the directory handle is already stored in indexdb, we should use that
      let directoryHandle = await this.getSavedFileDirectoryHandle('directory') as FileSystemDirectoryHandle;

      // don't annoy the user if they've already said no to backup in this session
      if (sessionStorage.getItem('userSaidNoIndexDBBackup')) { return; }

      // if we don't have a directory handle stored in indexdb, inform user that we are setting up automatic backups
      // and then show the directory picker to choose a directory for backups
      if (!directoryHandle) {
        const modalRef = this.modalService.open(GeneralUserConfirmationModalComponent, { centered: true });
        modalRef.componentInstance.title = 'Set up automatic backups';
        modalRef.componentInstance.message = 'To set up automatic backups of all your offline data on this device, you will be asked to choose a directory where the backup file(s) will be stored.';
        modalRef.componentInstance.confirmationText = 'Choose directory';
        modalRef.componentInstance.cancelText = 'Don\'t setup automatic backups';

        const result = await modalRef.result;
        if (result !== true) {
          sessionStorage.setItem('userSaidNoIndexDBBackup', 'true');
          return;
        }

        // inside the directory the user selects, we will create a folder for relcare backups
        // better this way than storing the backup file(s) in the selected directory itself to avoid clutter
        const outerDirectoryHandle = await window.showDirectoryPicker({ startIn: 'documents', mode: 'readwrite' });
        if (!await this.getPermissionToModifyFile(outerDirectoryHandle)) { return; }
        directoryHandle = await outerDirectoryHandle.getDirectoryHandle('relcare-backup', { create: true });
      }

      // now that have the handle (either from indexdb or from recently closed directory picker),
      // we need to ensure the browser will allow us to read / write to that file
      if (!await this.getPermissionToModifyFile(directoryHandle)) { return; }
      // only if permissing is granted we should return the directory handle
      return directoryHandle;

    } catch (error) {
      console.error('Error in getting backup directory handle:', error);
      return;
    }
  }

  async autoWriteBackupToFileSystem(directoryHandle: FileSystemDirectoryHandle) {
    try {
      // if not directory handle is passed, do nothing
      if (!directoryHandle) { return; }
      // check for permission again just for safety
      if (!await this.getPermissionToModifyFile(directoryHandle)) { return; }
      const fileHandle = await directoryHandle.getFileHandle('relcare-backup.json', { create: true});
      const writableStream = await fileHandle.createWritable();
      await writableStream.write(JSON.stringify(await this.getBackupObject(), null, 2));
      await writableStream.close();

      // the given directory handle might not be saved in indexdb, so save it now that the backup file has been written
      this.addOrUpdateFileDirectoryHandle(directoryHandle, 'directory');

    } catch (error) {
      console.error('Error in writing backup to file system:', error);
      return;
    }

  }

}
