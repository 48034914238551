import { Component } from '@angular/core';
import { DisplayConfiguration } from 'src/app/models/display-config';

@Component({
  selector: 'app-home-page',
 // standalone: true,
 // imports: [],
  templateUrl: './home-page.component.html',
  styleUrl: './home-page.component.scss'
})
export class HomePageComponent {
  viewTab = 'Substation-L';
  displayConfiguration:DisplayConfiguration={
    toggleTable : true,
    toggleMatrix : true,
    showEdit : true
  }
}
