<div class="row center-class" style="justify-content: right;text-align: center;align-items:center;">
    <div class="col-md-6" style="text-align: right;">
    </div>
    <div class="col-md-6" style="padding-top: 50px;">
        <h4 style="font-weight: bold;">How to access this RelCare project on your mobile</h4>
        <br>
        <br>
        <ol style="text-align: left;justify-content: left">
            <li>1.Open RelCare application on your device</li>
        <br>
        <li>2.Click on Add environment button, to initiate QR code scanning</li>
        <br>
        <li>3.Point the camera to this screen to capture the displayed QR code</li>
        <br>
        <li>4.Once the QR captured, follow the steps on the mobile device</li>
        </ol>
    </div>
</div>