import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { take } from 'rxjs/operators';
import { GridSearcher } from 'src/app/components/generic-table/GridSearcher';
import { GenericTableModel } from 'src/app/components/generic-table/models/generic-table.model';
import { AlarmService } from 'src/services/alarm.service';
import { AlarmConfiruration } from './models/alarm-configuration.model';

@Component({
  selector: 'app-alarm-notification-settings',
  templateUrl: './alarm-notification-settings.component.html',
  styleUrls: ['./alarm-notification-settings.component.scss']
})
export class AlarmNotificationSettingsComponent implements OnInit {
  @ViewChild('toggleSwitch', { static: true }) toggleSwitch;
  _gridSearcher: GridSearcher;
  _allItems: any[] = [];
  _panelOpenState = false;
  configTable: GenericTableModel;
  alarmsConfig: AlarmConfiruration;
  substationDict: any[];
  eqptCatDict: any[];
  assetDict: any[];
  equipmentDict: any[];
  filterForm: FormGroup;
  notifyForm: FormGroup;

  constructor(public modal: NgbActiveModal, private alarmService: AlarmService) {
  }

  ngOnInit() {
    this.filterForm = new FormGroup({
      substation: new FormControl('All'),
      eqptCat: new FormControl('All'),
      asset: new FormControl('All'),
      equipment: new FormControl('All'),
      search: new FormControl(''),
    });

    this.notifyForm = new FormGroup({
      notifyEmail: new FormControl(false),
      notifySms: new FormControl(false),
    })

    this.filterForm.valueChanges.subscribe((x) => this.filterAlarms(x))

    this.alarmService.getAlarmUserConfiguration().pipe(take(1)).subscribe(config => {
      this.alarmsConfig = config;
      this._allItems = config.items;
      this.substationDict = this._allItems.map(x => x.substation).filter(this.distinctArray);
      this.eqptCatDict = this._allItems.map(x => x.eqptCat).filter(this.distinctArray);
      this.assetDict = this._allItems.map(x => x.asset).filter(this.distinctArray);
      this.equipmentDict = this._allItems.map(x => x.equipment).filter(this.distinctArray);
      this.notifyForm.patchValue({
        notifyEmail: config.notifyEmail,
        notifySms: config.notifySms
      })
    });

    this.configTable = {
      columns: [
        { name: "Type", valuePath: "type" },
        { name: "Substation", valuePath: "substation" },
        { text: "Equipment category", name: "EquipmentCategory", valuePath: "eqptCat" },
        { text: "Equipment name", name: "EquipmentName", valuePath: "equipment" },
        { name: "Asset", valuePath: "asset" },
        { name: "Name", valuePath: "name" },
        { name: "Severity", valuePath: "severity" },
        {
          name: "Notify",
          valuePath: "notify",
          disableSorting: false,
          disableHiding: false,
          cellTemplate: this.toggleSwitch
        },
      ],
      sortColumn: "Type",
    };

    this._gridSearcher = new GridSearcher(this.configTable);
  }

  public closeModal() {
    this.modal.close();
  }

  public submit() {
    var model = {
      ...this.notifyForm.value,
      items: this.alarmsConfig.items
    };

    this.alarmService.saveAlarmUserConfiguration(model).pipe(take(1)).subscribe(() => this.closeModal());
  }

  private distinctArray(value, index, self) {
    return self.indexOf(value) === index;
  }

  private filterAlarms(filters) {
    this.alarmsConfig.items = this._allItems;

    if (filters.asset !== "All")
      this.alarmsConfig.items = this.alarmsConfig.items.filter(x => x.asset === filters.asset)
    if (filters.eqptCat !== "All")
      this.alarmsConfig.items = this.alarmsConfig.items.filter(x => x.eqptCat === filters.eqptCat)
    if (filters.equipment !== "All")
      this.alarmsConfig.items = this.alarmsConfig.items.filter(x => x.equipment === filters.equipment)
    if (filters.substation !== "All")
      this.alarmsConfig.items = this.alarmsConfig.items.filter(x => x.substation === filters.substation)
    if (filters.search !== "") {
      const query = filters.search.toLowerCase();

      this.alarmsConfig.items = this._gridSearcher.search(query, this.alarmsConfig.items);
    }
  }
}
