import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';


@Component({
  selector: 'app-assign-execution-team',
  templateUrl: './assign-execution-team.component.html',
  styleUrls: ['./assign-execution-team.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AssignExecutionTeamComponent implements OnInit {

  allTeams = [{}];
  teams: any = [];
  loading = false;
  selected = [];
  filterShow = false;
  filterStr = '';
  tableVisible = false;
  search = false;
  a: any = [];
  assignTeam = [];
  filterColumns = [
    { prop: 'teamName', name: 'Name', selected: true },
    // { prop: 'region', name: 'Region', selected: true },
    { prop: 'type', name: 'Type', selected: true },
    // { prop: 'topSkill', name: 'Skills', selected: true },
  ];

  constructor(private appSvc: ApplicationService,
    public activeModal: NgbActiveModal) { }

  ngOnInit() {
    this.test1();
  }

  test1() {
    // this.spinnerService.show();
    this.loading = true;
    this.appSvc.getAllExecutionTeam().subscribe({next:(res) => {
      this.loading = false;
      if (res.returnObj.length > 0) {
        this.teams = res.returnObj;
        this.assignTeam = res.returnObj;
        this.loading = false;
        // this.spinnerService.hide();
      }
    }, error:() => {
      this.loading = false;
    }});
  }
  // test2() {
  //   this.appSvc.getMixedExecutionTeams().subscribe(res => {
  //     const b = res;
  //     if (res.length > 0) {
  //       b.forEach(element => {
  //         this.teams.push(element);
  //       });
  //     }
  //   });
  // }
  // test3() {
  //   this.appSvc.getExternalExecutionTeams().subscribe(res => {
  //     const c = res;
  //     if (res.length > 0) {
  //       c.forEach(element => {
  //         this.teams.push(element);
  //       });
  //     }
  //     this.loading = false;
  //   });
  // }

  updateFilter() {
    // const strFilter = this.filterStr.trim().toLowerCase();
    // let filterItems = this.filterColumns.filter(filterItem => filterItem.selected);
    // if (filterItems.length === 0) {
    //   filterItems = this.filterColumns;
    // }
    // // filter our data
    // this.teams = this.allTeams.filter(team => {
    //   if (!strFilter) {
    //     return true;
    //   }
    //   return filterItems.some(filterItem => {
    //     let searchTxt = team[filterItem.prop] || '';
    //     if (!(team[filterItem.prop] instanceof String)) {
    //       searchTxt = JSON.stringify(team[filterItem.prop]);
    //     }
    //     return searchTxt.toLowerCase().indexOf(strFilter) !== -1;
    //   });
    // });
  }

  // Search
  onKey(event) {
    const searchValue = event.target.value.trim().toLowerCase();
    let resRow = [];
    let searchArray = [];
    if (searchValue.length === 0) {
      this.search = false;
      resRow = [];
      resRow = this.assignTeam;
    } else {
    this.search = true;
    this.assignTeam = this.assignTeam.filter(function (row) {
        searchArray = [row.teamType, row.teamName];
        searchArray.forEach(element => {
          if (element.toLowerCase().indexOf(searchValue) >= 0) {
            resRow.push(row);
            return row;
          }
        });
        return resRow;
      });
    }
    this.teams = _.uniq(resRow);
    resRow = [];
  }

  assign() {
    this.activeModal.close(this.selected);
  }

  onSelect({ selected }) {
    this.selected.splice(0, this.selected.length);
    this.selected.push(...selected);
  }

  selectedItemCount() {
    return this.teams.filter(team => team['selected']).length;
  }

}
