import { Component, OnInit } from '@angular/core';
import { Project, User } from 'src/app/db';
import { IndexDBService } from 'src/services/indexdb.service';
import { Router } from '@angular/router';
import { CryptoService } from 'src/services/crypto.service';
import { fromEvent, map, merge, of, Subscription } from 'rxjs';
import { detectIncognito } from 'src/app/detect-incognito';
import { GeneralUserConfirmationModalComponent } from '../dashboard/modals/general-user-confirmation-modal/general-user-confirmation-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-offline-landing',
  templateUrl: './offline-landing.component.html',
  styleUrl: './offline-landing.component.scss'
})
export class OfflineLandingComponent implements OnInit {

  public email: string;
  public password: string;
  public errorMessage: string;

  public selectedUser: User;
  public users: User[];

  public projects: Project[];
  networkStatus: boolean
  networkStatus$: Subscription = Subscription.EMPTY;
  isOffline: boolean;
  isDBNotAvailable: boolean = false;

  constructor(private indexDBService: IndexDBService, private router: Router, private cryptoService: CryptoService,
    private _modalService: NgbModal
  ) { }

  ngOnInit(): void {
    detectIncognito().then((isIncognito) => {
      if (isIncognito && window.location.href.includes("/offline")) {
        window.location.href = 'assets/incognito.html'
      }
    });
    this.checkIfDataBaseAvailable();
    this.checkNetworkStatus()
  }

  onUserSelected() {
    this.projects = [];
    this.indexDBService.getProjects().subscribe(projects => {
      this.projects = projects;
      if (this.projects.length === 1) {
        sessionStorage.setItem("projectId", this.projects[0].projectId);
        sessionStorage.setItem("projectName", this.projects[0].projectName);
        this.router.navigate([
          `/offline/${this.projects[0].projectName}/maintenance-management`,
        ]);
      } else {
        this.router.navigate([`offline/projectdashboard`]);
      }
    });

  }

  onProjectSelected(project: Project) {
    sessionStorage.setItem('projectId', project.projectId);
    sessionStorage.setItem('projectName', project.projectName);

    this.router.navigate([`/offline/${project.projectName}/maintenance-management`]);
  }

  login() {
    this.indexDBService.login(this.email.toLowerCase(), this.password).subscribe({
      next: (user) => {
        if (user) {
          this.errorMessage = null;
          sessionStorage.setItem('UserId', user.id);
          sessionStorage.setItem('UserName', user.name);
          sessionStorage.setItem('Email', user.email);
          this.cryptoService.setPasswordInMemory(this.password);
          this.onUserSelected();
        }
      }, error: (err) => this.errorMessage = err
    });
  }
  checkNetworkStatus() {
    this.networkStatus = navigator.onLine;
    this.networkStatus$ = merge(
      of(null),
      fromEvent(window, 'online'),
      fromEvent(window, 'offline')
    )
      .pipe(map(() => navigator.onLine))
      .subscribe(status => {
        this.isOffline = status
      });
  }

  importFromBackup(files: File[]) {
    if (!files.length) { return; }
    const file = files[0];
    this.indexDBService.importBackupFromFile(file).subscribe(() =>{
      this.checkIfDataBaseAvailable();
    });
  }

  checkIfDataBaseAvailable() {
    this.indexDBService.getUsers().subscribe(users => {
      this.users = users;
      this.users.length ? this.isDBNotAvailable = false : this.isDBNotAvailable = true;
    });
  }
}
