import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AlarmService } from '../../../../services/alarm.service';
import { ApplicationService } from './../../../../services/application.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GisSldStateService } from 'src/services/gis-sld-state.service';

@Component({
  selector: 'app-alarm-item',
  templateUrl: './alarm-item.component.html',
  styleUrls: ['./alarm-item.component.scss']
})

export class AlarmItemComponent implements OnInit, OnChanges {
  @ViewChild('alarmItem', { static: false }) alarmItem: ElementRef;
  @ViewChild('circle', { static: false }) circle: ElementRef;
  @ViewChild('hover-tooltip', { static: false }) contextMenu: ElementRef;
  @Input() alarm;
  @Input() isAllAcknowledged = false;
  @Output() clickedAlarm: EventEmitter<any> = new EventEmitter();
  @Output() acknowledgedAlarm: EventEmitter<any> = new EventEmitter();

  constructor(
    private router: Router,
    private alarmService: AlarmService,
    private appSvc: ApplicationService,
    private modalService: NgbModal,
    private gisSldStateService: GisSldStateService,
  ) {
  }

  ngOnInit() {
    console.log("initialise app");
  }

  ngOnChanges(changes: SimpleChanges) {

  }

  isLow(alarm) {
    return alarm && alarm.severity && alarm.severity.toLowerCase()=='low';
  }

  isMedium(alarm) {
    return alarm && alarm.severity && alarm.severity.toLowerCase()=='medium';
  }

  isHigh(alarm) {
    return alarm && alarm.severity && alarm.severity.toLowerCase()=='high';
  }

  clickAlarm(alarm){
    this.clickedAlarm.emit(alarm);
  }

  acknowledgeAlarm(event) {
    this.alarmService.acknowledgeAlarm(this.alarm.id).subscribe(() => {
      if (!this.alarm.isAcknowledged) {
        this.circle.nativeElement.className = 'circle';
        this.alarm.isAcknowledged = true;

        this.acknowledgedAlarm.emit({
          alarm: this.alarm,
          isAcknowledged: this.alarm.isAcknowledged
        });
      }
    });
  }

}
