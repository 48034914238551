import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-three-state-slide-select',
  templateUrl: './three-state-slide-select.component.html',
  styleUrls: ['./three-state-slide-select.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ThreeStateSlideSelectComponent),
    multi: true
  }]
})
export class ThreeStateSlideSelectComponent implements OnInit {

  @Input() states = ['Hide', 'View', 'Edit'];
  @Input() sccID;
  @Input() disabled = false;
  @Input() value;
  // @Input() currentIndex;
  // @Output() sccIDValuew = new EventEmitter();

  @Output() valueChange = new EventEmitter();

  // value = '';  
  currentIndex = 0;

  /**
  * Invoked when the model has been changed
  */
  onChange: (_: any) => void = (_: any) => { };

  /**
   * Invoked when the model has been touched
   */
  onTouched: () => void = () => { };

  constructor() { }

  ngOnInit() {
    if (this.value) {
      if (this.value === 'Hide') {
        this.currentIndex = 0;
      }
      if (this.value === 'View') {
        this.currentIndex = 1;
      }
      if (this.value === 'Edit') {
        this.currentIndex = 2;
      }
      if (this.value === 'Internal') {
        this.currentIndex = 0;
      }
      if (this.value === 'Mixed') {
        this.currentIndex = 1;
      }
      if (this.value === 'External') {
        this.currentIndex = 2;
      }
      if (this.value === 'Short') {
        this.currentIndex = 0;
      }
      if (this.value === 'Medium') {
        this.currentIndex = 1;
      }
      if (this.value === 'Long') {
        this.currentIndex = 2;
      }
      // this.currentIndex = this.states.indexOf(this.value);
    }

    if (this.states) {
      this.currentIndex = this.states.indexOf(this.value);
    }
    if (this.value === undefined) {
      this.currentIndex = 0;
    }
  }

  /**
   * Registers a callback function that should be called when the control's value changes in the UI.
   */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /**
   * Registers a callback function that should be called when the control receives a blur event.
   */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
  * Writes a new value to the element.
  */
  writeValue(newValue: string): void {
    if (this.disabled) {
      return;
    }
    else if(this.states){
      this.value = newValue;
      this.updateChanges();
    }
  }

  /**
   * Method that is invoked on an update of a model.
   */

  // Counter = 0;
  // valueChanged() { // You can give any function name
  //   const num = 0;
  //   this.valueChange.emit(num);
  // }
  updateChanges() {
    // this.sccIDValuew = this.sccID;
    this.valueChange.emit(this);
    if (this.value === 'Hide') {
      this.currentIndex = 0;
    }
    if (this.value === 'View') {
      this.currentIndex = 1;
    }
    if (this.value === 'Edit') {
      this.currentIndex = 2;
    }
    if (this.value === 'Short') {
      this.currentIndex = 0;
    }
    if (this.value === 'Medium') {
      this.currentIndex = 1;
    }
    if (this.value === 'Long') {
      this.currentIndex = 2;
    }

    if (this.states) {
      this.currentIndex = this.states.indexOf(this.value);
    }
    if (this.value === undefined) {
      this.currentIndex = 0;
    }

    // this.currentIndex = this.states.indexOf(this.value);
    this.onChange(this.value);
  }

}
