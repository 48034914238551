import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Style from 'ol/style/Style';
import Text from 'ol/style/Text';
import { Interaction, NodeType } from './constant.enum';
import * as RouteHelper from 'src/app/utility/routing.helper';
import Stroke from 'ol/style/Stroke';

export class Map {
  isProjectEnv: boolean;

  static possibleConnection(originNode: NodeType, destinationNode: NodeType) {
    if ((originNode === NodeType.SubStation && destinationNode === NodeType.Source) || (originNode === NodeType.Load)
      || (destinationNode === NodeType.Source) || (destinationNode === NodeType.Load && originNode === NodeType.Source)) {
      return false;
    }
    return true;
  }

  static getMiddleCoordinate(startCoordinate, endCoordinate) {
    const x = endCoordinate[1] - startCoordinate[1];
    const y = endCoordinate[0] - startCoordinate[0];
    const degrees = 180 + Math.atan2(y, x) * (180 / Math.PI);
    if (degrees < 45) {
      return [startCoordinate[0], endCoordinate[1] + (startCoordinate[0] - endCoordinate[0])];
    } else if (degrees === 45) {
      return [];
    } else if (degrees > 45 && degrees < 90) {
      return [endCoordinate[0] + (startCoordinate[1] - endCoordinate[1]), startCoordinate[1]];
    } else if (degrees === 90) {
      return [];
    } else if (degrees > 90 && degrees < 135) {
      return [endCoordinate[0] - (startCoordinate[1] - endCoordinate[1]), startCoordinate[1]];
    } else if (degrees === 135) {
      return [];
    } else if (degrees > 135 && degrees < 180) {
      return [startCoordinate[0], endCoordinate[1] - (startCoordinate[0] - endCoordinate[0])];
    } else if (degrees === 180) {
      return [];
    } else if (degrees > 180 && degrees < 225) {
      return [startCoordinate[0], endCoordinate[1] + (startCoordinate[0] - endCoordinate[0])];
    } else if (degrees === 225) {
      return [];
    } else if (degrees > 225 && degrees < 270) {
      return [endCoordinate[0] + (startCoordinate[1] - endCoordinate[1]), startCoordinate[1]];
    } else if (degrees === 270) {
      return [];
    } else if (degrees > 270 && degrees < 315) {
      return [endCoordinate[0] - (startCoordinate[1] - endCoordinate[1]), startCoordinate[1]];
    } else if (degrees === 315) {
      return [];
    } else if (degrees > 315 && degrees < 360) {
      return [startCoordinate[0], endCoordinate[1] - (startCoordinate[0] - endCoordinate[0])];
    } else if (degrees === 260 || degrees === 0) {
      return [];
    }
    return [];
  }


  static getNodeIcon(nodeType: NodeType, interaction: Interaction, risk, Substationname = '') {
    let image = '';
    let size = [56, 56];

    switch (nodeType) {
      case NodeType.Joint:
        image = 'joint';
        size = [62, 62];
        break;
      case NodeType.SubStation:
        image = 'marker' + (risk ? `-${risk}` : '');
        size = (interaction === Interaction.Hover && Substationname) ? [72, 72] : [56, 56];
        break;
      case NodeType.Source:
        image = 'source';
        break;
      case NodeType.Load:
        image = 'load';
        break;
      default:
        break;
    }
    let baseStyle;
    if (interaction === Interaction.Hover) {
      baseStyle = new Style({
        image: new Icon({
          crossOrigin: 'anonymous',
          src: `/assets/i/${image}-black.svg`,
          size: [56, 56]
        })
      });
      return baseStyle;
    }
    if (interaction === Interaction.Active) {
      image += '-active';
      size = [60, 75];
    }

    return new Style({
      image: new Icon({
        crossOrigin: 'anonymous',
        src: `/assets/i/${image}.svg`,
        size: size
      }),
      // text: new Text({
      //   text: (text == null ? '' : text) + '',
      //   fill: new Fill({ color: '#414141' }),
      //   offsetX: -4,
      //   offsetY: -4
      // })
    });
  }

}


