<div class="modal-containe">
    <div class="modal-header">
      <span class="modal-title" id="modal-basic-title">Confirm</span>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('back')">
        <span>&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <span i18n>Are you sure you want to logout from the application?</span>
    </div>
    <div class="modal-footer">
      <a class="btn btn-secondary" (click)="activeModal.close('cancel')" i18n>Cancel</a>
      <a class="btn btn-delete" (click)="activeModal.close('Logout')" i18n>Logout</a>
    </div>
  </div>
  