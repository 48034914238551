import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs';
import { ManageMaintenanceService } from 'src/services/manage-maintenance.service';

@Component({
  selector: 'app-indexdb-password-modal',
  templateUrl: './indexdb-password-modal.component.html',
  styleUrl: './indexdb-password-modal.component.scss'
})
export class IndexdbPasswordModalComponent {

  @Input() mode: 'set' | 'retrieve' = 'retrieve';

  public password: string;
  public repeatPassword: string;
  public loading: boolean;
  public email : string= sessionStorage.getItem('Email');

  constructor(
    public modal: NgbActiveModal,
    private _manageMaintenanceService: ManageMaintenanceService,
    private router: Router
  ) {}

  setPassword() {
    this.loading = true;
    this._manageMaintenanceService.setMPin(this.password)
      .pipe(finalize(() => this.loading = false))
      .subscribe(() => {
        this.modal.close(this.password);
      });
  }

  navigateToLogin() {
    sessionStorage.clear();
    this.router.navigateByUrl("/offline");
    this.modal.dismiss();
  }
}
