import { Pipe, PipeTransform } from '@angular/core';
import { ApplicationService } from 'src/services/application.service';
import { ConstService } from '../../services/const.service';

@Pipe({
    name: 'APItoUImappingFilter'
})
export class APItoUImappingFilterPipe implements PipeTransform {

    constructor(private appSvc: ApplicationService, private constService: ConstService) {
    }

    public transform(value: string, projectId: string, filterAPICallType: string): string {
        let text: string;
        // Common/Genereral/Generic Case capturing common condition
        switch (value) {
            case 'ProjectName':
                text = "Projects";
                break;
            case 'CustomerName':
                text = "Customer";
                break;
            case 'StartDate':
                text = "Start date";
                break;
            case 'EndDate':
                text = "End date";
                break;
            case 'SubstationOwner':
                text = "Owner";
                break;
            case 'SubstationOperator':
                text = "Operator";
                break;
            case 'AssetCount':
                text = "No. of assets";
                break;
            case 'EqptType':
                text = "Equipment type";
                break;
            case 'Voltage':
                text = "Operating voltage";
                break;
            case 'Years':
                text = "Manufacturing year";
                break;
            case 'project':
                text = 'Project'
                break;
            case 'installation':
                text = 'Installation'
                break;
            case 'owner':
                text = 'Substation owner'
                break;
            case 'substationOperator':
                text = 'Substation operator'
                break;
            case 'technology':
                text = 'Technology'
                break;
            case 'country':
                text = 'Country'
                break;
            case 'assetCount':
                text = 'Asset count'
                break;
            case 'underSLA':
                text = 'UnderSLA'
            case 'MaintenancePlan':
                text = 'Maintenance plan';
                break;
            case 'OutageWindow':
                text = 'Outage window';
                break;
            case 'OutageArea':
                text = 'Outage area';
                break;
            case 'AssetType':
                text = 'Asset type';
                break;
            case 'TaskType':
                text = 'Task type';
                break;
            case 'MaintTypeName':
                text = 'Maintenance type';
                break;
            case 'PlanningStatusName':
                text = 'Planning status';
                break;
            case 'TaskStatusName':
                text = 'Task status';
                break;
            case 'SubstationBayName':
                text = 'Bay/Branch name';
                break;
            case 'Risk':
                // shift this in an if condition for filterAPICallType === 'Tasks' (plan viewer page) if you need it as "Risk" only
                text = 'Asset risk';
                break;
            case 'Priority':
                // shift this in an if condition for filterAPICallType === 'Tasks' (plan viewer page) if you need it as "Priority" only
                text = 'Task priority';
                break;
            case 'ExecutionOwner':
                text = 'Task owner';
                break;
            case 'ExecutionTeam':
                text = 'Execution team';
                break;
            case 'ExecutionTeamLead':
                text = 'Execution team lead';
                break;
            case 'MaintenanceType':
                text = 'Maintenance type';
                break;
            case 'TaskState':
                text = 'Task state';
                break;
            case 'EventState':
                text = 'Event state';
                break;
            case 'BayBranch':
                text = 'Bay / Branch';
                break;
            case 'PlanningType':
                text = 'Planning type';
                break;
            default:
                text = value;
        }
        //For System Environment there will be no Project ID
        if (projectId == this.constService.emptyGuid) {
            switch (value) {
                case 'Country':
                    text = "OU";
                    break;
            }
        }
        //For Project Environment there will be Project ID
        if (projectId != this.constService.emptyGuid && filterAPICallType == 'Installation') {
            switch (value) {
                case 'Installation':
                    text = "Location";
                    break;
            }
        }
        return text;
    }
}

