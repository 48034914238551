import { Component, EventEmitter, inject, Input, OnDestroy, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CancelAssessReliabilityModalComponent } from 'src/app/containers/sld-editor/cancel-assessReliability-modal/cancel-assessReliability-modal';
import { SldUtilService } from 'src/app/containers/sld-editor/services/util.service';
import { MainHeaderService } from '../main-header.service';
import { SldMainService } from 'src/app/containers/sld-editor/sld-main.service';

@Component({
  selector: 'app-notification-assessReliability',
  templateUrl: './notification-assessReliability.component.html',
  styleUrls: ['./notification-assessReliability.component.scss']
})
export class NotificationAssessReliabilityComponent implements OnDestroy{
  @Input() notification;
  @Output() cancelMessage = new EventEmitter();
  public loading = false;
  public showInProgressStatus: boolean = false;
  public message: string;
  private _subscriptions: Subscription;
  sldMainService = inject(SldMainService);
  constructor(private modalService: NgbModal, private utilService: SldUtilService, private mainheaderService:MainHeaderService) { }

  dismissWithAction(data) {
    const modalRef = this.modalService.open(CancelAssessReliabilityModalComponent, { centered: true });
    modalRef.componentInstance.substationName = data.substationName;
    modalRef.result.then(ret => {
      if (ret === 'yes') {
        this.loading = true;
        this._subscriptions = this.utilService.cancelAssessReliability(data.assessReliabilityRequestId).subscribe({
          next: res => {
            this.loading = false;
            this.showInProgressStatus = true;
            this.cancelMessage.emit(data.substationName);
            this.mainheaderService.onRolesUpdated();
            this.sldMainService.onButtonClick.next(data.substationId);
          }, complete: () => { },
          error: () => { this.loading = false; }
        });
      } else {
        return;
      }
    });
  }
  ngOnDestroy() {
    if (this._subscriptions) {
      this._subscriptions.unsubscribe();
    }
  }
}
