
@if (displayData) {
  <app-resistance-chart
    [data]="displayData"
    [dateRangeLabel]="dateRangeLabel"
    [availablePropertiesForXAxis]="availablePropertiesForXAxis"
    [showTitleArea]="false"
    [settings]="chartSettings"
  ></app-resistance-chart>
}
