import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-operations-center',
  templateUrl: './operations-center.component.html',
  styleUrls: ['./operations-center.component.scss']
})
export class OperationsCenterComponent implements OnInit {

  viewTab = 'Installations';

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    sessionStorage.setItem('projectId', '00000000-0000-0000-0000-000000000000');
    sessionStorage.setItem('projectIdfromOpCenter', '00000000-0000-0000-0000-000000000000');
    const viewTab = this.route.snapshot.params.tab;
    this.viewTab = viewTab ? viewTab : 'Installations';

  }

}
