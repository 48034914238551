import { Injectable } from '@angular/core';
import moment from 'moment';
import 'moment-timezone';

@Injectable({ providedIn: 'root' })
export class TimezoneConversionService {

  constructor() { }

  readLocalDateAsProjectTimeZone(date: Date): Date {
    const projectTimeZone = sessionStorage.getItem('projectTimeZone');
    const localDateTimeString = moment(date).format('YYYY-MM-DDTHH:mm:ss');
    const newDateTime = moment.tz(localDateTimeString, projectTimeZone);
    return newDateTime.toDate();
  }

  readProjectDateAsLocalTimeZone(date: Date): Date {
    const projectTimeZone = sessionStorage.getItem('projectTimeZone');
    const dateConversion = date.toLocaleString('en-US', { timeZone: projectTimeZone });
    return moment(dateConversion).toDate();
  }
}
