<div class="modal-container">
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title" i18n>All privileges</span>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('back')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    @if (loading) {
      <div class="loading-box">
        <div class="img" alt="loading"></div>
      </div>
    }
    <div class="all-privileges-table">
      <div class="frozen-column privilege-table-column">
        <div class="privilege-table-header" i18n>Roles</div>
        @for (privilege of privileges; track privilege) {
          <div class="privilege-group">
            <div class="privilege-sub-header privilege-table-row">{{ privilege.permissionName }}</div>
            @for (permission of privilege.permissionList; track permission) {
              <div class="privilege-permission-item privilege-table-row"
                >
                {{ permission.name }}
              </div>
            }
            <div class="privilege-table-row separator-row">
              <div class="separator"></div>
            </div>
          </div>
        }
      </div>
      <div class="scrollable-column-group">
        @for (role of roles; track role) {
          <div class="privilege-table-column">
            <div class="privilege-table-header" i18n>
            <div class="role-header-cell">
              <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':role.selected}"
                (click)="role.selected=!role.selected"></i>
              <i class="icon icon-role" aria-hidden="true"></i>
              <span class="role-name">{{role.roleName}}</span>
            </div>
          </div>
            @for (privilege of role.rolePrivileges; track privilege) {
              <div class="privilege-group">
                <div class="privilege-sub-header privilege-table-row"></div>
                @for (permission of privilege.permissionList; track permission) {
                  <div class="privilege-permission-item privilege-table-row"
                    >
                    <app-three-state-slide-select [(ngModel)]="permission.level"></app-three-state-slide-select>
                  </div>
                }
                <div class="privilege-table-row separator-row"></div>
              </div>
            }
          </div>
        }
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <a class="btn btn-save" [class.disabled]="selectedItemCount()<=0" (click)="save()" i18n>Save</a>
  </div>
</div>