import {GenericColumn, Paginate, PaginationConfig} from './models/generic-table.model';
import {GenericTableHelper} from './GenericTableHelper';
import {GenericTableComponent} from './generic-table.component';
import {reverse} from 'lodash';
import _ from 'lodash';

export class LocalPaginationConfig implements PaginationConfig {
  private _currentPage: number = 1;

  sort(column: GenericColumn, gt: GenericTableComponent): void {
    if (gt.configTableModel.sortColumn === column.name) {
      gt.configTableModel.sortAscending = !gt.configTableModel.sortAscending;
      this.sortItems(column, gt);
      return;
    }

    gt.configTableModel.sortAscending = false;
    gt.configTableModel.sortColumn = column.name;

    this.sortItems(column, gt);
  }

  private sortItems(column: GenericColumn, gt: GenericTableComponent) {
    let sorted;
    if(column.valuePath == 'notify' ){
       sorted = _.orderBy(gt.items, [item => GenericTableHelper.getValue(column, item)], ['desc']);
    } else {
       sorted = _.orderBy(gt.items, [item => GenericTableHelper.getValue(column, item)?.toLowerCase()], ['desc']);
    }
     //const sorted = _.orderBy(sortBy(gt.items, (item) => GenericTableHelper.getValue(column, item));
    if (gt.configTableModel.sortAscending) {
      gt.setItems(sorted);
    } else {
      gt.setItems(reverse(sorted));
    }
  }

  public sortItemsBySettings(gt: GenericTableComponent) {
    if (!gt.configTableModel.sortColumn) {
      return;
    }
    const sortColumnDef = gt.configTableModel.columns.find(
      (x) => x.name === gt.configTableModel.sortColumn
    );

    if (!sortColumnDef) {
      return;
    }
    this.sortItems(sortColumnDef, gt);
  }

  onReceivedNewItems(gt: GenericTableComponent): void {
    this.sortItemsBySettings(gt);
  }

  pageChanged(gt: GenericTableComponent, event: number): void {
    this._currentPage = event;
  }

  getPaginateConfig(gt: GenericTableComponent): Paginate {
    return {currentPage: this._currentPage, itemsPerPage: gt.itemsPerPage, totalItems: gt.items.length};
  }

  onInit(gt: GenericTableComponent): void {
    //nothing to do
  }

  triggerLoadingData(gt: GenericTableComponent): void {
    //nothing to do
  }
}
