<div class="alarm-item-container" #alarmItem>
  <div class="first-line" (click)="clickAlarm(alarm)">
    <div class="title">
      <i icon-severity [alarm]="alarm" aria-hidden="true"></i>
      <span class="type">{{alarm.type}}</span>
      @if (alarm.type == 'Alarm' || alarm.type == 'Derivative') {
        <div class="severity" [class.highOuter]="isHigh(alarm)" [class.mediumOuter]="isMedium(alarm)" [class.lowOuter]="isLow(alarm)">
          <div class="severity" [class.highInner]="isHigh(alarm)" [class.mediumInner]="isMedium(alarm)" [class.lowInner]="isLow(alarm)">
            {{alarm.severity}}
          </div>
        </div>
      }
      <span class="circle" [class.unread]="!alarm.isAcknowledged" #circle></span>
    </div>
    <div class="datetime">{{alarm.createdAt}}</div>
  </div>
  <div class="second-line" (click)="clickAlarm(alarm)">{{alarm.message}}</div>
  @if (!alarm.isAcknowledged) {
    <div class="hover-tooltip" (click)="acknowledgeAlarm($event)">
      Acknowledge
    </div>
  }
</div>
