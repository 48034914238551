export const isExternalProject = (): boolean => {
  return !!JSON.parse(sessionStorage.getItem('isExternalProject'));
}

export const getProjectRoute = (): string => {
  if (isExternalProject()) {
    return '';
  }
  const projectName = sessionStorage.getItem('projectName');
  const projectRoute = projectName ? `/${projectName}` : '';
  return projectRoute;
}

export const addProjectPrefix = (path: string): string => {
  const prefix = getProjectRoute();
  return prefix + (path.startsWith('/') ? '' : '/') + path;
}

export const isInProjectMode = (): boolean => {
  return isExternalProject() || !!sessionStorage.getItem('projectName');
}

export const getProjectIdForAPIHeader = (): string => {
  if (isExternalProject()) { return '00000000-0000-0000-0000-000000000000'; }
  return sessionStorage.getItem('projectId');
}
