import { Component,
  Input, 
  ViewEncapsulation } from '@angular/core';
@Component({
  selector: 'perfect-scrollbar',
  templateUrl: './perfect-scrollbar.component.html',
  styleUrls: [
    './perfect-scrollbar.component.scss',
    '../../../../node_modules/perfect-scrollbar/css/perfect-scrollbar.css'
  ],
  encapsulation: ViewEncapsulation.None
})
export class PerfectScrollbarComponent {

  @Input() disabled: boolean = false;
  @Input() config?: any;
  @Input() usePSClass: boolean = true;

}
