import {Component, Input} from '@angular/core';

@Component({
  selector: '[header-icon-severity]',
  templateUrl: './header-icon-severity.component.html',
  //styleUrls: ['./header-icon-severity.component.scss']
})
export class HeaderIconSeverityComponent {

  @Input() alarms: any[] = [];
  @Input() ignoreAcknowledged = true;

  public getClass(): string | null {

    const events = this.alarms.filter(x => x.type == 'Event' && (this.ignoreAcknowledged ? !x.isAcknowledged : true));

    if (events.find(x => x.severity == 'High'))
      return 'icon-exclamation-high';

    if (events.find(x => x.severity == 'Medium'))
      return 'icon-exclamation-medium';

    if (events.find(x => x.severity == 'Low'))
      return 'icon-exclamation-low';

    return null;
  }

}
