@if (loading) {
  <div class="loading-box">
    <div class="img" alt="loading"></div>
  </div>
}

<div class="page-wrapper">
  <header class="header-container">
    <h3 i18n>Audit Logs</h3>
  </header>
  <div class="d-flex justify-content-end">
    <div class="page-header">
      <span class="pr-3">Start :</span>
      <mat-form-field>
        <input matInput [ngxMatDatetimePicker]="pickerStart" placeholder="Date & Time"
          [(ngModel)]="startDateTime" [formControl]="start_dt" [class.invalid]="validation(start_dt)">
        <mat-datepicker-toggle matSuffix [for]="pickerStart"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #pickerStart [showSpinners]="showSpinners" [showSeconds]="showSeconds"
          [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
          [touchUi]="touchUi" [enableMeridian]="enableMeridian"
          [disableMinute]="disableMinute">
        </ngx-mat-datetime-picker>
      </mat-form-field>
      <div [class.show]="validation(start_dt)"
      class="error-message" i18n>Date & time is required.</div>
      <div [class.show]="rangeValidation()"
      class="error-message" i18n>Invalid input.</div>
    </div>

    <div class="page-header" style="padding-left: 0px">
      <span class="pr-3">End :</span>
      <mat-form-field>
        <input matInput [ngxMatDatetimePicker]="pickerEnd" placeholder="Date & Time"
          [(ngModel)]="endDateTime" [formControl]="end_dt" [class.invalid]="validation(end_dt)">
        <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
        <ngx-mat-datetime-picker #pickerEnd [showSpinners]="showSpinners" [showSeconds]="showSeconds"
          [stepHour]="stepHour" [stepMinute]="stepMinute" [stepSecond]="stepSecond"
          [touchUi]="touchUi" [enableMeridian]="enableMeridian"
          [disableMinute]="disableMinute">
        </ngx-mat-datetime-picker>
      </mat-form-field>
      <div [class.show]="validation(end_dt)"
      class="error-message" i18n>Date & time is required.</div>
      <div [class.show]="rangeValidation()"
      class="error-message" i18n>Invalid input.</div>
    </div>

    <div class="page-header download-logs">
      <a href="javascript:;" ngbTooltip="Download Logs" tooltipClass="custom-tooltip">
        <i class="icon icon-table-download" (click)="downloadAuditLogs(start_dt, end_dt)"
        aria-hidden="true"></i>
      </a>
    </div>

    <div class="d-flex align-items-center search-audit-log">
      <input type="search" class="input-ctrl search-ctrl" placeholder="Search"
        (keyup)="search($event.target.value)"/>
    </div>
  </div>


  <div class="p-2 table-wrapper">
    <div class="freeze-table">
      <div class="filter-section">
        <div class="filter-container" [ngClass]="{'filter-hide':!filterShow}">
          <div class="header">
            <i class="icon icon-filter" aria-hidden="true" (click)="filterShow=!filterShow" [ngClass]="{'checked':!filterShow}"></i>
            <span i18n>Column filter</span>
          </div>
          @for (item of filterColumn; track item; let i = $index) {
            <div class="filter-option" (click)="item.selected=!item.selected;">
              <i class="icon icon-checkbox"  aria-hidden="true" [ngClass]="{'checked':item.selected}"></i>
              <span>{{item.name}}</span>
            </div>
          }
        </div>
      </div>
      <div class="scroll-panel">
        <perfect-scrollbar class="scrollbar">
          <div class="wrapper"  [perfectScrollbar]="{suppressScrollX: true}">
            <ng-container [ngTemplateOutlet]="table"></ng-container>
          </div>
        </perfect-scrollbar>
        <div class="fixed-wrapper">
          <ng-container [ngTemplateOutlet]="table"></ng-container>
        </div>
      </div>
    </div>
    <ng-template #table>
      <table class="base-table" aria-hidden="true">
        <mat-accordion>
          <mat-panel-title >
            <tr>
              @if (filterColumn[0].selected) {
                <th class= "base-table-dateTime" (click)="sort('dateTime')">Date
                  <i class="default-sort-btn" [class.sort-asc]="this.sortDirection && (this.sortBy === 'dateTime')"
                  [class.sort-desc]="!this.sortDirection && (this.sortBy === 'dateTime')" aria-hidden="true"></i>
                </th>
              }
              @if (filterColumn[1].selected) {
                <th class= "base-table-dateTime" (click)="sort('dateTime')">Time
                  <i class="default-sort-btn" [class.sort-asc]="this.sortDirection && (this.sortBy === 'dateTime')"
                  [class.sort-desc]="!this.sortDirection && (this.sortBy === 'dateTime')" aria-hidden="true"></i>
                </th>
              }
              @if (filterColumn[2].selected) {
                <th class= "base-table-dt" (click)="sort('substationName')">Substation Name
                  <i class="default-sort-btn" [class.sort-asc]="this.sortDirection && (this.sortBy === 'substationName')"
                  [class.sort-desc]="!this.sortDirection && (this.sortBy === 'substationName')" aria-hidden="true"></i>
                </th>
              }
              @if (filterColumn[3].selected) {
                <th class= "base-table-dt" (click)="sort('moduleName')">Module Name
                  <i class="default-sort-btn" [class.sort-asc]="this.sortDirection && (this.sortBy === 'moduleName')"
                  [class.sort-desc]="!this.sortDirection && (this.sortBy === 'moduleName')" aria-hidden="true"></i>
                </th>
              }
              @if (filterColumn[4].selected) {
                <th class= "base-table-dt" (click)="sort('userRoleName')">User Role
                  <i class="default-sort-btn" [class.sort-asc]="this.sortDirection && (this.sortBy === 'userRoleName')"
                  [class.sort-desc]="!this.sortDirection && (this.sortBy === 'userRoleName')" aria-hidden="true"></i>
                </th>
              }
              @if (filterColumn[5].selected) {
                <th class= "base-table-dt" (click)="sort('userName')">User ID
                  <i class="default-sort-btn" [class.sort-asc]="this.sortDirection && (this.sortBy === 'userName')"
                  [class.sort-desc]="!this.sortDirection && (this.sortBy === 'userName')" aria-hidden="true"></i>
                </th>
              }
              @if (filterColumn[6].selected) {
                <th (click)="sort('userActivity')">Activity
                  <i class="default-sort-btn" [class.sort-asc]="this.sortDirection && (this.sortBy === 'userActivity')"
                  [class.sort-desc]="!this.sortDirection && (this.sortBy === 'userActivity')" aria-hidden="true"></i>
                </th>
              }
              @if (filterColumn[7].selected) {
                <th (click)="sort('detailsDescription')">Detail Description
                  <i class="default-sort-btn" [class.sort-asc]="this.sortDirection && (this.sortBy === 'detailsDescription')"
                  [class.sort-desc]="!this.sortDirection && (this.sortBy === 'detailsDescription')"  aria-hidden="true"></i>
                </th>
              }
            </tr>
          </mat-panel-title>
          @for (item of allLogs | paginate: config; track item) {
            <tr>
              <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                  @if (filterColumn[0].selected) {
                    <td title="{{item.dateTime}}" class="base-table-dateTime td_overflow">
                    {{item.dateTime}}</td>
                  }
                  @if (filterColumn[1].selected) {
                    <td title="{{item.time}}" class="base-table-dateTime td_overflow">
                    {{item.time}}</td>
                  }
                  @if (filterColumn[2].selected) {
                    <td title="{{item.substationName}}" class= "base-table-dt td_overflow">
                    {{item.substationName}}</td>
                  }
                  @if (filterColumn[3].selected) {
                    <td title="{{item.moduleName}}" class="base-table-dt td_overflow">
                    {{item.moduleName}}</td>
                  }
                  @if (filterColumn[4].selected) {
                    <td title="{{item.userRoleName}}" class="base-table-dt td_overflow">
                    {{item.userRoleName}}</td>
                  }
                  @if (filterColumn[5].selected) {
                    <td title="{{item.userName}}" class="base-table-dt td_overflow">
                    {{item.userName}}</td>
                  }
                  @if (filterColumn[6].selected) {
                    <td title="{{item.userActivity}}" class="td_overflow">
                    {{item.userActivity}}</td>
                  }
                  @if (filterColumn[7].selected) {
                    <td title="{{item.detailsDescription}}" class="td_overflow">
                    {{item.detailsDescription}}</td>
                  }
                </mat-expansion-panel-header>
                <mat-panel-description>
                  <tr class="detail-description-row">
                    @if (!item.isJson && !item.isExcelFile) {
                      <td title="{{item.detailsDescription}}" class="detail-description-col"
                        >
                        {{item.detailsDescription}}
                      </td>
                    }
                    @if (!item.isJson && item.isExcelFile) {
                      <td title="{{item.detailsDescription}}"
                        >
                        {{item.detailsDescription}} <a href="javascript:;" ngbTooltip="Download Excel" (click)="downloadAuditExcelFile(item.path)"
                        tooltipClass="custom-tooltip" style="width: auto;display: inline-flex;">
                        {{item.path}}
                      </a>
                    </td>
                  }
                  @if (item.isJson) {
                    <td title="{{item.detailsDescription}}" class="detail-description-col">
                      <ngx-json-viewer [json]="item.detailsJSON" [expanded]="false"></ngx-json-viewer>
                    </td>
                  }
                </tr>
              </mat-panel-description>
            </mat-expansion-panel>
          </tr>
        }
      </mat-accordion>
    </table>
  </ng-template>
</div>
</div>
<pagination-controls id="pagination1" (pageChange)="paginateData($event)" class="my-pagination"></pagination-controls>