@if (loading) {
  <div class="loading-box" >
    <div class="img"  alt="loading"></div>
  </div>
}
<div class="container-fluid" id="pdfTable" #pdfTable>
  <div class="row">
    <div class="col-md-3 pr-md-0">
      <button class="status-button">
        <span>Tenant Details</span></button>
      </div>

      <div class="col-md-9">
        <div class="table-wrapper">
          <ng-container [ngTemplateOutlet]="planTable"></ng-container>
          <ng-template #planTable>
          <form style="display: flex;
            margin-bottom: 12px;
            float: right;" (submit)="changeSearch(search)">
              <div class="col-sm-4">
                <input [(ngModel)]="search" name="search" type="search" (keyup)="noSearch()" class="input-ctrl"
                  style="width: 200px;" placeholder="Search" />
              <div style="margin-left: 170px;
                    margin-top: -23px;">
                  <a type="submit"><img class="image" src="../../../assets/i/search.svg" alt="Search"
                    (click)="changeSearch()" width="18" height="18">
                  </a>
                </div>
              </div>
            </form>
            <table class="base-table" aria-hidden="true">
              <perfect-scrollbar style="height: calc(100vh - 310px);">
                <tr>
                  @if (filterColumns[0].selected) {
                    <th id="id25" (click)="sort('tenantName')">Tenant name
                      <i class="default-sort-btn" aria-hidden="true"
                        [class.sort-asc]="this.sortDirection && (this.sortColumn === 'tenantName')"
                      [class.sort-desc]="!this.sortDirection && (this.sortColumn === 'tenantName')"></i>
                    </th>
                  }
                  @if (filterColumns[1].selected) {
                    <th id="id26" (click)="sort('tenantLink')">Tenant url
                      <i class="default-sort-btn" aria-hidden="true"
                        [class.sort-asc]="this.sortDirection && (this.sortColumn === 'tenantLink')"
                      [class.sort-desc]="!this.sortDirection && (this.sortColumn === 'tenantLink')"></i>
                    </th>
                  }
                  @if (filterColumns[2].selected) {
                    <th id="id29" (click)="sort('jobStatus')">Job status
                      <i class="default-sort-btn" aria-hidden="true"
                        [class.sort-asc]="this.sortDirection && (this.sortColumn === 'jobStatus')"
                      [class.sort-desc]="!this.sortDirection && (this.sortColumn === 'jobStatus')"></i>
                    </th>
                  }
                  <th id="id30">
                    <div style="width:215px;" class="filter-container" [ngClass]="{'filter-hide':!filterShow}">
                      <div class="header">
                        <i class="icon icon-filter" aria-hidden="true" (click)="filterShow=!filterShow"
                        [ngClass]="{'checked':!filterShow}"></i>
                        <span i18n>Column filter</span>
                      </div>
                      @for (item of filterColumns; track item; let i = $index) {
                        <div class="filter-option"
                          (click)="item.selected=!item.selected;">
                          <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked':item.selected}"></i>
                          <span>{{item.name}}</span>
                        </div>
                      }
                    </div>
                  </th>
                </tr>
                @for (item of tenantDetails | paginate: configPage; track item) {
                  <tr>
                    @if (filterColumns[0].selected) {
                      <td>{{item.tenantName}}</td>
                    }
                    @if (filterColumns[1].selected) {
                      <td>{{item.tenantLink}}</td>
                    }
                    @if (filterColumns[2].selected) {
                      <td>{{item.jobStatus}}</td>
                    }
                    <td>
                      <label [for]="'all-user-' + item.tenantId" class="checkbox-wrap">
                        <input [id]="'all-user-' + item.tenantId" type="checkbox" [checked]="item.isSelected"
                          (change)="onCheckboxChangeFn($event, item)">
                        <i class="icon icon-checkbox" aria-hidden="true" [ngClass]="{'checked': item.isSelected}"></i>
                      </label>
                    </td>
                  </tr>
                }
              </perfect-scrollbar>
              <tr>
                @if (tenantDetails?.length == 0) {
                  <td class="centerAlign" colspan="9">No Records found</td>
                }
              </tr>
            </table>
            <button class="btn btn-secondary" (click)="resetAll()">Reset All</button>
          </ng-template>
          <app-add-new type="light" (click)="add()">Add new</app-add-new>

          <pagination-controls id="pagination1" (pageChange)="pageChangePlan($event)" class="pagination-template-1">
          </pagination-controls>

          <div class="button-wrapper">
            <div class="row">
              <div class="col-sm-12">
                <div class="buttons">
                  @if (selected.length == 1) {
                    <button (click)="editTenant()" class="btn btn-secondary">Edit
                    </button>
                  }
                  @if (selected.length == 1) {
                    <button (click)="deleteTenant(selected)" class="btn btn-primary">Delete
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>