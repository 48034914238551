<div class="modal-container">
  <div class="modal-header">
    <span class="modal-title" id="modal-basic-title">Delete {{deleteItemName}}</span>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('back')">
      <span>&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <span i18n>Are you sure you want to delete the {{deleteItemName}}?</span>
  </div>
  <div class="modal-footer">
    <a class="btn btn-delete" (click)="activeModal.close('cancel')" i18n>Cancel</a>
    <a class="btn btn-delete" (click)="activeModal.close('delete')" i18n>Delete</a>
  </div>
</div>