import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { IndexDBService } from 'src/services/indexdb.service';

@Component({
  selector: 'app-offline-list-page',
  templateUrl: './offline-list-page.component.html',
  styleUrl: './offline-list-page.component.scss'
})
export class OfflineListPageComponent {
  projects: any;
  offlineProjectDetailsFromTaks:any
  task: any;
  constructor(private indexDBService: IndexDBService, private router: Router) { }
  onProjectSelected(project: any) {
    sessionStorage.setItem('projectId', project.projectId);
    sessionStorage.setItem('projectName', project.projectName);

    this.router.navigate([`/offline/${project.projectName}/maintenance-management`]);
  }
  ngOnInit(){
    this.indexDBService.getProjects().subscribe(async projects => {
      for(var i=0; i < projects.length ; i++){
        let metaData= await firstValueFrom(this.indexDBService.getProjectMetaInformation(projects[i].projectId));
        projects[i] = {...projects[i],...metaData }
      }
      this.projects = projects
    });
  }
}