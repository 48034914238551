import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApplicationService } from '../../../../../services/application.service';

@Component({
  selector: 'app-create-joint-modal',
  templateUrl: './create-joint-modal.component.html',
  styleUrls: []
})
export class CreateJointModalComponent implements OnInit {
  form: FormGroup;
  submitted: boolean;
  @Input() edit: boolean;
  @Input() masterData: any;
  @Input() marker: any;
  userRolesObject: any = {};

  constructor(private dataService: ApplicationService,
    private formBuilder: FormBuilder, public activeModal: NgbActiveModal, private router: Router) {
  }

  ngOnInit() {
    this.userRolesObject = this.dataService.getUserPrivilages();
    const properties = this.marker ? this.marker.getProperties() : {};
    this.form = this.formBuilder.group({
      nodeReference: [properties.nodeReference, Validators.required],
      locationReference: [properties.locationReference],
      longitude: [properties.longitude],
      latitude: [properties.latitude],
      altitude: [properties.altitude],
    });
  }

  /**
   * get form controllers
   */
  get f(): any {
    return this.form.controls;
  }

  /**
   * Validate sub station
   * @param name - field name
   * @returns - valid or not
   */
  validation(name): any {
    return this.submitted && this.form.controls[name].errors && this.form.controls[name].errors.required;
  }

  close() {
    this.activeModal.close({ type: 'close' });
  }

  /**
   * Delete marker
   */
  deleteMarker() {
    this.activeModal.close({ type: 'delete', data: this.marker.getId() });
  }

  /**
   * Submit form
   */
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.form.invalid) {
      return;
    }
    this.activeModal.close({
      nodeType: 'joint',
      type: 'save', data: {
        id: this.marker.getId(),
        ...this.form.value
      }
    });
  }

  goToSLD() {
    this.activeModal.close({ type: 'sld' });
  }

}
