import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class BackToHomePageService {
    fromHomepage: boolean;

    isFromHomePage(value?) {
        this.fromHomepage = value;
    }
 }

