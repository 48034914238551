import { Injectable } from '@angular/core';
import { HelperService } from './helper.service';
import { SettingsService } from './settings.service';
import { catchError } from 'rxjs';
const MOCK_API_DELAY = 1000;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  msurl: any;
  constructor(private helper: HelperService,
    mysettingsService: SettingsService) {
      this.msurl = mysettingsService.settings.managesystemApiURL;
     }

  /**
   * Get all notifications
   */
  getNotifications() {
    //return this.helper.getdd('/assets/data/GetNotifications.json').pipe(delay(MOCK_API_DELAY));
    return this.helper.get1(this.msurl + '/Notification/GetAllNotifications?includeDetails=true');
  }

  toggleNotificationReadStatus(id, isRead) {
    return this.helper.patch('/Notification/ToggleNotificationReadStatus', { notificationIds: id, isRead: isRead });
  }

  markAllNotificationsRead() {
    return this.helper.patch('/Notification/MarkAllNotificationsRead', null, null);
  }

  /**
   * Get email settings options
   */
  getEmailSettingOptions() {
    return this.helper.get('/Notification/GetEmailSettings');
  }

  /**
   * Get notification settings data
   */
  getNotificationSettings() {
    return this.helper.get('/Notification/GetNotificationSettings');
  }

  /**
   * Get system notifications
   */
  /// Commenting this call until system goes live.
  getSystemNotifications() {  
    let url = this.msurl + '/Notification/GetSystemNotifications';
    return this.helper.get1(url).pipe(
      catchError(error => this.helper.catchAuthError(error)));
                  
  }

  updateNotificationSettings(settings: any) {
    return this.helper.post('/Notification/UpdateNotificationSettings', settings);
  }

  //   errorHandler(error: HttpErrorResponse): Observable<Response> {
  //   return throwError(error);
  // } 
}
