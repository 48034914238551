import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class EditDashnoardWidgetsService {
    private gridResized = new Subject<void>();
    public gridResized$ = this.gridResized.asObservable();

    emitGridResized() {
        this.gridResized.next();
    }
}
