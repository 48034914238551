import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from './application.service';
import { MainHeaderService } from 'src/app/components/main-header/main-header.service';
import { IndexDBService } from './indexdb.service';

@Injectable({ providedIn: 'root' })
export class RouteBackService {
    constructor(private router: Router, private appSvc: ApplicationService, private mainHeaderService: MainHeaderService, private indexDBService: IndexDBService) {
    }
    loading = false;
    login() {
        sessionStorage.removeItem('projectName');
        sessionStorage.setItem('projectId', '00000000-0000-0000-0000-000000000000');
        this.appSvc.loginUserKeycloak().subscribe({next:res => {
            this.loading = false;
            let roles = res.roles;
            sessionStorage.setItem('UserId', res.userId);
            sessionStorage.setItem('UserRoles', res.roles);
            sessionStorage.setItem('previleges', JSON.stringify(res));
            sessionStorage.setItem('isDeputyTeamLead', res.isDeputyTeamLead);
            this.mainHeaderService.onRolesUpdated();
          }, error: () => {
            this.loading = false;
          }});
        }

}
