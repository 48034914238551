import { ActivatedRouteSnapshot, DetachedRouteHandle, RouteReuseStrategy } from '@angular/router';
import { Injectable } from "@angular/core";

@Injectable()
export class AppRouteReuseStrategy extends RouteReuseStrategy {
  constructor() {
    super();
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, detachedTree: DetachedRouteHandle): void { }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    return null;
  }

  // When data 'noReuse' is added to route then component will reload
  shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const reuseCurr = this.reuseRoute(future);

    return future.routeConfig === curr.routeConfig && reuseCurr;
  }

  private reuseRoute(curr: ActivatedRouteSnapshot) {
    if (curr.data.noReuse)
      return false;
    else if (curr.firstChild)
      return this.reuseRoute(curr.firstChild)
    else
      return true;
  }
}
