import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationStart } from '@angular/router'
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-general-user-confirmation-modal',
  templateUrl: './general-user-confirmation-modal.component.html',
  styleUrls: ['./general-user-confirmation-modal.component.scss']
})
export class GeneralUserConfirmationModalComponent implements OnInit {

  @Input() message: string;
  @Input() messageHtml: string;
  @Input() title: string;
  @Input() confirmationText: string;
  @Input() cancelText: string;

  constructor(public activeModal: NgbActiveModal, router: Router) {
    router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .pipe(take(1))
      .subscribe(() => this.activeModal.dismiss('back'));
  }

  ngOnInit() {
  }

}
