@if (loading) {
  <div class="loading-box">
    <div class="img" alt="loading"></div>
  </div>
}

<div class="modal-container">

  <div class="modal-header">
    @if (mode === 'retrieve') {
      <span class="modal-title" id="modal-basic-title">Re-enter password</span>
    } @else {
      <span class="modal-title" id="modal-basic-title">Create password</span>
    }
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('back')">
      <span>&times;</span>
    </button>
  </div>

  <div class="modal-body">
    @if (mode === 'set') {
      <span class="input-label">New password</span>
    }

    @if (mode === 'retrieve') {
      <div class="refresh-warning-text">
        <div class="refresh-warning">
          <img class="onboard-img" src="/assets/i/Info_red.svg" alt="Avatar" />
          Refreshing the page requires reauthentication.
        </div>
      </div>

      <div class="username-div">
        <span class="input-label">Email ID</span>
        <input class="input-ctr" disabled type="text" placeholder="Enter password" 
          [(ngModel)]="email">
      </div>

      <span class="input-label">Password</span>
    }

    <div class="input-padding">

      <input class="input-ctr" [minlength]="mode === 'set' ? 8 : null"  type="password" placeholder="Enter password" [(ngModel)]="password" #passwordField = "ngModel">

      @if (mode === 'set') {
        @if (password && passwordField.errors?.minlength) {
          <div>
            <img src="/assets/i/Error_circle.svg" alt="Avatar" />
            <span class="validation-error-message">Should be a minimum length of 8 characters</span>
          </div>
        }
      
        @else {
          <span class="validation-warning-message">Should be a minimum length of 8 characters</span>
        }
      }
    </div>

    @if (mode === 'set') {
      <span class="input-label">Confirm password</span>
      <input class="input-ctr" minlength="8" type="password" placeholder="Re-enter password" [(ngModel)]="repeatPassword" [class.error]="repeatPassword !== password">

      @if (password && repeatPassword && password !== repeatPassword) {
        <div class="error-message">
          <img src="/assets/i/Error_circle.svg" alt="Avatar" />
          <span class="validation-error-message">Password mismatch</span>
        </div>
      }
    }

    @if (mode === 'retrieve') {
      <div class="logout">For logging-out, <a (click)="navigateToLogin()" class="logout-link">click here</a></div>
    }
    @if (mode === 'set') {
      <div class="password-info-text">
        <div class="password-info">
          <img class="onboard-img" src="/assets/i/Info_red.svg" alt="Avatar" />
            This password is used to authorize offline access for RelCare.
        </div>
      </div>
    }

  </div>

  <div class="modal-footer">

    @if (mode === 'retrieve') {
      <a class="btn btn-primary" (click)="modal.close(password)" [class.disabled]="!password" i18n>Login</a>
    }

    @else {
      <a class="btn btn-cancel" (click)="modal.dismiss('back')" i18n>Cancel</a>
      <a class="btn btn-primary" (click)="setPassword()" [class.disabled]="!password || password !== repeatPassword || password?.length > 8 || password?.length < 8" i18n>Set</a>
    }

  </div>
</div>