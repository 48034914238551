import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class OpCenterService {
  // Observable searchItem source
  private _searchItem = new BehaviorSubject<string>('');
  // Observable searchItem stream
  searchItem$ = this._searchItem.asObservable();
  // service command
  installationSearch(substation) {
    this._searchItem.next(substation);
  }

  projectSearch(project) {
    this._searchItem.next(project);
  }
}
