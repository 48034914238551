<div class="page-wrapper">
  <header class="header-container">
    <h3 i18n>{{title}}</h3>
  </header>
  @if (loading) {
    <div class="loading-box">
      <div class="img" alt="loading"></div>
    </div>
  }
  <div class="table_border">
    <div class="table-wrapper p-2" style="background-color: white;">
      <div class="freeze-table">
        <div class="wrapper" [perfectScrollbar]="{}">
          <ng-container [ngTemplateOutlet]="table"></ng-container>
        </div>
        <div class="fixed-wrapper">
          <ng-container [ngTemplateOutlet]="table"></ng-container>
        </div>
      </div>
      <ng-template #table>
        <table class="base-table" aria-hidden="true">


          <tr>
            <th id="idUserName">User Name</th>

            <th id="idVersionName">Version Name</th>
            @if (pageType == historicaldata) {
              <th id="idHistoryType">History Type</th>
            }
            @if (pageType != planexcelreport) {
              <th id="idSubstationName" >Substation Name</th>
            }
            @if (pageType == planexcelreport) {
              <th id="idSubstationName">Plan Name</th>
            }
            <th id="idExcelRequest">Excel Request Time</th>
            <th id="idExcelComplete">Excel Complete Time</th>
            <th id="idStatus22">Status</th>
            @if (pageType != planexcelreport) {
              <th id="idSpaces22">
                &nbsp;
              </th>
            }
          </tr>
          @for (item of filterColumns; track item) {
            <tr>
              <td>{{item.userName}}</td>
              <td>{{item.versionName}}</td>
              @if (pageType == historicaldata) {
                <td>{{item.historizationType}}</td>
              }
              @if (pageType != planexcelreport) {
                <td>{{item.substationName}}</td>
              }
              @if (pageType == planexcelreport) {
                <td>{{item.planName}}</td>
              }
              <td>{{item.excelRequrestTs | date:'medium'}}</td>
              <td>{{item.excelCompleteTs | date:'medium'}}</td>
              @if (item.isError == false) {
                <td>Completed</td>
              }
              @if (item.isError === true) {
                <td>Error</td>
              }
              @if (sldColumnDisplay()) {
                <td>
                  <a  (click)="openDownloadModal(item)" href="javascript:;"><i
                  class="icon icon-table-download" aria-hidden="true"></i></a>
                </td>
              }
              @if (historyColumnDisplay(item)) {
                <td>
                  <a  (click)="openDownloadModal(item)" href="javascript:;"><i
                  class="icon icon-table-download" aria-hidden="true"></i></a>
                </td>
              }
              @if (planColumnDisplay()) {
                <td>
                  <a (click)="openDownloadExel(item)" href="javascript:;"><i
                  class="icon icon-table-download" aria-hidden="true"></i></a>
                </td>
              }
            </tr>
          }
        </table>
      </ng-template>
    </div>
  </div>
</div>