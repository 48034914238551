import { Component, EventEmitter, Input, Output, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-role-card',
  templateUrl: './role-card.component.html',
  styleUrls: ['./role-card.component.scss']
})
export class RoleCardComponent implements OnInit, AfterViewInit {

  @Input() role: any;
  @Input() allRoles: any;
  @Output() roleClick = new EventEmitter();
  assignDescriptions;
  isPMCRoleSelected = false;

  ngOnInit() {}

  ngAfterViewInit() {}

  identify(index, item) {
    return item.eventSelected;
  }

  Changed(role, event) {
    const ele1 = document.getElementById("Operation SME") as HTMLInputElement;
    const ele2 = document.getElementById("Remote Operator") as HTMLInputElement;
    const ele3 = document.getElementById("Maintenance Manager") as HTMLInputElement;
    const ele4 = document.getElementById("Execution Team Lead") as HTMLInputElement;
    const ele7 = document.getElementById("Execution Owner") as HTMLInputElement;
    const ele8 = document.getElementById("Execution Owner") as HTMLInputElement;
    const ele9 = document.getElementById("Project Manager (C)") as HTMLInputElement;
    const ele10 = document.getElementById("Policy SME") as HTMLInputElement;

    role.selected = !role.selected;

    if (role.roleName === "Project Manager (C)" && event.target.checked === true) {
      this.isPMCRoleSelected = true;
      ele1.checked = false;
      ele2.checked = false;
      ele3.checked = false;
      ele4.checked = false;
      ele7.checked = false;
      ele8.checked = false;
      ele10.checked = false;
      this.allRoles.forEach(ele => {
        var roleName = ele.roleName;
        if (roleName === "Project Manager (C)") {
          ele.eventSelected = false;
        } else {
          ele.eventSelected = true;
          ele.selected = false;
        }
      });
    } else if (role.roleName === "Project Manager (C)" && event.target.checked === false) {
      this.isPMCRoleSelected = false;
      role.selected = false;
      this.allRoles.forEach(ele => {
        ele.eventSelected = false;
      });
    }

    if (this.allRoles.length < 9) {
      for (let i = 0; i < this.allRoles.length; i++) {
        if (this.allRoles[i].roleName === "Project Manager (C)" && event.target.checked === true) {
          this.allRoles.forEach(ele => {
            var roleName = ele.roleName;
            if (roleName === "Project Manager (C)") {
              ele.eventSelected = false;
            } else {
              ele.eventSelected = true;
            }
          });
        }
      }

      let checkedTrue = false;
      for (let i = 0; i < this.allRoles.length; i++) {
        if ((document.getElementById(this.allRoles[i].roleName) as HTMLInputElement).checked === true && this.allRoles[i].roleName !== 'Project Manager (C)') {
          checkedTrue = true;
        }
      }
      if (checkedTrue === false) {
        if (event.target.checked === false) {
          this.allRoles.forEach(ele => {
            ele.eventSelected = false;
          });
        }
      } else {
        this.allRoles.forEach(ele => {
          var roleName = ele.roleName;
          if (roleName === "Project Manager (C)") {
            ele.eventSelected = true;
          } else {
            ele.eventSelected = false;
          }
        });
      }
    }

    let allRolesCount = this.allRoles.length;
    if (allRolesCount === 9 || allRolesCount === 10) {
      if (role.roleName === "Project Manager (C)" && event.target.checked === true) {
        this.allRoles.forEach(ele => {
          var roleName = ele.roleName;
          if (roleName === "Project Manager (C)") {
            ele.eventSelected = false;
          } else {
            ele.eventSelected = true;
          }
        });
      }

      if (role.roleName === "Project Manager (C)" && event.target.checked === false) {
        this.allRoles.forEach(ele => {
          ele.eventSelected = false;
        });
      }

      if (role.roleName !== "Project Manager (C)" && event.target.checked === true) {
        this.allRoles.forEach(ele => {
          var roleName = ele.roleName;
          if (roleName !== "Project Manager (C)") {
            ele.eventSelected = false;
          } else {
            ele.eventSelected = true;
          }
        });
      }

      if (ele1 === null) {
        if (ele2.checked === false && ele3.checked === false && ele4.checked === false
          && ele7.checked === false && ele8.checked == false && ele10.checked === false) {
          if (role.roleName !== "Project Manager (C)" && event.target.checked === false) {
            ele9.disabled = false;
            this.allRoles.forEach(ele => {
              ele.eventSelected = false;
            });
          }
        }
      }
      if (ele2 === null) {
        if (ele1.checked === false && ele3.checked === false && ele4.checked === false
          && ele7.checked === false && ele8.checked == false && ele10.checked === false) {
          if (role.roleName !== "Project Manager (C)" && event.target.checked === false) {
            ele9.disabled = false;
            this.allRoles.forEach(ele => {
              ele.eventSelected = false;
            });
          }
        }
      }
      if (ele3 === null) {
        if (ele1.checked === false && ele2.checked === false && ele4.checked === false
          && ele7.checked === false && ele8.checked == false && ele10.checked === false) {
          if (role.roleName !== "Project Manager (C)" && event.target.checked === false) {
            ele9.disabled = false;
            this.allRoles.forEach(ele => {
              ele.eventSelected = false;
            });
          }
        }
      }
      if (ele4 === null) {
        if (ele1.checked === false && ele3.checked === false && ele2.checked === false
          && ele7.checked === false && ele8.checked == false && ele10.checked === false) {
          if (role.roleName !== "Project Manager (C)" && event.target.checked === false) {
            ele9.disabled = false;
            this.allRoles.forEach(ele => {
              ele.eventSelected = false;
            });
          }
        }
      }
      if (ele7 === null) {
        if (ele1.checked === false && ele3.checked === false && ele4.checked === false
          && ele2.checked === false && ele8.checked == false && ele10.checked === false) {
          if (role.roleName !== "Project Manager (C)" && event.target.checked === false) {
            ele9.disabled = false;
            this.allRoles.forEach(ele => {
              ele.eventSelected = false;
            });
          }
        }
      }
      if (ele8 === null) {
        if (ele1.checked === false && ele3.checked === false && ele4.checked === false
          && ele2.checked === false && ele7.checked == false && ele10.checked === false) {
          if (role.roleName !== "Project Manager (C)" && event.target.checked === false) {
            ele9.disabled = false;
            this.allRoles.forEach(ele => {
              ele.eventSelected = false;
            });
          }
        }
      }
      if (ele10 === null) {
        if (ele1.checked === false && ele3.checked === false && ele4.checked === false
          && ele2.checked === false && ele7.checked == false && ele8.checked === false) {
          if (role.roleName !== "Project Manager (C)" && event.target.checked === false) {
            ele9.disabled = false;
            this.allRoles.forEach(ele => {
              ele.eventSelected = false;
            });
          }
        }
      }
      if (ele1 !== null && ele2 !== null && ele3 !== null && ele4 !== null
        && ele7 !== null && ele8 !== null && ele10 !== null) {
        if (ele1.checked === false && ele3.checked === false && ele4.checked === false
          && ele2.checked === false && ele7.checked == false && ele8.checked === false && ele10.checked === false) {
          if (role.roleName !== "Project Manager (C)" && event.target.checked === false) {
            ele9.disabled = false;
            this.allRoles.forEach(ele => {
              ele.eventSelected = false;
            });
          }
        }
      }
    }
  }
}
