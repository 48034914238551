import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApplicationService } from './application.service';

@Injectable({ providedIn: 'root' })
export class BackNavigationService {
    path="operationscenter";
    constructor(private router: Router, private appSvc: ApplicationService) {
    }
    backnavigation() {
        this.router.navigateByUrl('/'+ this.path);    
        }
    }

