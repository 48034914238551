import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../../../../services/notification.service';

@Component({
  selector: 'app-system-notifications',
  templateUrl: './system-notifications.component.html',
  styleUrls: ['./system-notifications.component.scss']
})
export class SystemNotificationsComponent implements OnInit {
  @Input() title = '';
  public systemNotifications = [];

  constructor(
    public activeModal: NgbActiveModal,
    private notificationSvc: NotificationService
  ) { }

  ngOnInit() { }
    //Commenting this call until the system goes live.
    // this.notificationSvc.getSystemNotifications().subscribe(data => {
    //   let temp = _.cloneDeep(data);
    //   temp = _.orderBy(temp.scadaAssetErrors, 'createdAt', 'desc');
    //   _.forEach(temp, item => {
    //     error.message = item.message.split('lost');
    //     error.createdAt = moment(item.createdAt).format('DD MMMM HH:mm');
    //     this.systemNotifications.push(_.cloneDeep(error));
    //   });
    // });
  }

